.wrapper {
    width: 100%;
    position: relative;
    .dateRangePickerLabel {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 48px;
        padding: 10px;
        border: 1px solid var(--borderColor4);
        border-radius: 8px;
        background-color: var(--shade_ffffff);
        // width: 390px;
        font-size: 14px;
        .calendarIcon {
            display: flex;
            align-items: center;
            color: var(--grey_shade);
        }
        label {
            margin-left: 10px;
            font-size: 14px;
            cursor: pointer;
            white-space: nowrap;
        }
    }
    .dateRangePickerFocus {
        box-shadow: inset 0px 0px 0px 2px var(--borderColor2);
    }
}

[data-theme="darkMode"] {
    .wrapper {
        .dateRangePickerLabel:not(.dateRangePickerFocus) {
            background: var(--darkMode_genericGrey);
            &:hover {
                background: var(--darkMode_placeholder);
            }
        }
        .dateRangePickerFocus {
            background-color: var(--darkMode_depthGrey);
            border-color: var(--brandColor);
            box-shadow: var(--inputShadow);
        }
    }
}
