:global {
  .t-row:hover {
    .linkRenderer__link {
      text-decoration: underline;
      &:hover {
        color: var(--brandColor);
      }
    }
  }
  .linkRenderer__link{
    cursor: pointer;
    transition: 250ms linear color;
    &:hover {
      text-decoration: underline;
      color: var(--brandColor);
    }
  }
}
