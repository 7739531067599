.header {
    // box-shadow: none;
}

.createCategoriesWrapper {
    .maxWidth {
        max-width: 640px;
    }
    .plusIconWrapper {
        position: relative;
        cursor: pointer;
        padding: 7px 7px 3px 7px;
        border: 1px solid var(--borderColor4);
        border-radius: var(--radius2);
        &:hover {
            background: var(--brandColor);
            svg {
                color: var(--shade_ffffff) !important;
            }
        }
    }
}

.offerGroupAlignment {
    border: 1px solid var(--shade_EAF0F3);
    border-radius: 8px;
    padding: 20px;
    color: var(--shade_CAD1D4);
    font-weight: 500;
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 10px;
    &.noOfferGroupMapped {
        padding-top: 10px;
        padding-left: 10px;
        padding-bottom: 35px;
    }
}

.logicalGroupList {
    position: relative;
    > li {
        border: 1px dashed var(--borderColor2);
        padding: 40px 20px;
        position: relative;
        &:not(:last-child) {
            margin-bottom: 40px;
            &:after {
                border-left: 1px dashed var(--borderColor2);
                content: "";
                height: 40px;
                left: 50%;
                position: absolute;
                top: 100%;
            }
            &:before {
                align-items: center;
                background: var(--panelBg);
                border: 1px dashed var(--borderColor2);
                border-radius: 50%;
                color: var(--borderColor2);
                content: "OR";
                display: flex;
                font-size: 14px;
                height: 25px;
                justify-content: center;
                left: 50%;
                position: absolute;
                text-align: center;
                top: calc(100% + 7px);
                transform: translateX(-50%);
                width: 25px;
                z-index: 1;
            }
        }
    }

    &.readOnlyView {
        > li {
            border: none;
            padding: 10px;
            background: var(--drpdownBG);
            border-radius: var(--radius2);
            &:not(:last-child) {
                &:after {
                    display: none;
                }
                &:before {
                    content: "Or";
                    position: absolute;
                    top: calc(100% + 12px);
                    left: 10px;
                    transform: translateX(-50%);
                    color: var(--dark_color);
                    display: flex;
                    font-style: italic;
                    border: none;
                    height: auto;
                }
            }
            .groupLabel {
                display: none;
            }
        }
    }

    .andText {
        color: var(--dark_color);
        font-style: italic;
    }
    .actionsContainer {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
        color: var(--textColor2);
        &:hover {
            color: var(--error-color);
        }
    }
    .groupLabel {
        position: absolute;
        top: 5px;
        left: 5px;
        color: var(--borderColor2);
        font-size: 12px;
        font-style: italic;
    }
}

.plusDropdownWrapper {
    height: 30px;
    position: absolute;
    left: 0;
    max-width: 332px;
    width: 100%;
    top: 0;
    z-index: 1;
    .select {
        min-height: 30px !important;
        height: 30px;
    }
}

.plusDropDownActive {
    background-color: var(--brandColor);
    color: var(--shade_ffffff);
}
.toastDiv {
    span {
        text-transform: capitalize;
    }
}

[data-theme="darkMode"] {
    .logicalGroupList {
        > li {
            background: var(--darkMode_body);
            &:not(:last-child) {
                &:before {
                    color: var(--grey_shade);
                }
            }
        }

        &.readOnlyView {
            > li {
                background: var(--darkMode_body);
            }
        }

        .groupLabel {
            color: var(--grey_shade);
        }
    }
}
