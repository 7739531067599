.dummySelect {
    background: var(--shade_f7f7f7);
    border: 1px solid var(--borderColor1);
    color: var(--dark_color);
    padding: 0 10px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 14px;
    height: 40px;
    .deprecateTag {
        margin-right: 7px;
    }
    .labelText {
        max-width: 90px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    svg {
        color: var(--shade_74838F);
        cursor: pointer;
        &:hover {
            color: var(--errorIcon);
        }
    }
}
