.wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    .label {
        margin-right: 10px;
        &:empty {
            display: none;
        }
    }
    .dropdownWrapper {
        display: flex;
        align-items: center;
        cursor: pointer;
        border: 1px solid transparent;
        &:hover {
            border-radius: var(--radius2);
        }

        .labelWrapper {
            display: flex;
            align-items: center;
            position: relative;
            .inputWrapper {
                border: 1px solid var(--borderColor1);
                display: inline-flex;
                align-items: center;
                cursor: pointer;
                &.inputBorder {
                    border-radius: var(--radius2) 0 0 var(--radius2);
                }
                .input {
                    max-width: 40px;
                    input {
                        background-color: var(--shade_ffffff);
                        border: none;
                        outline: none;
                        &:disabled {
                            cursor: pointer;
                        }
                    }
                }
            }
            .dropdownListWrapper {
                width: 80px;
                position: absolute;
                top: calc(100% + 5px);
                background: var(--shade_ffffff);
                border-radius: var(--radius2);
                z-index: 9;
                box-shadow: var(--shadow1);
                display: flex;
                left: 0;
                .listWrap {
                    width: 40px;
                    .listItem {
                        padding: 5px 0px;
                        text-align: center;
                        color: var(--grey_shade);
                        font-size: var(--fontSize);
                        font-weight: var(--fontMedium);
                        line-height: var(--lineHeight);
                        cursor: pointer;
                        &.selected {
                            background-color: var(--shade_EAF0F3);
                            color: var(--dark_color);
                            font-weight: var(--fontBold);
                        }
                        &:hover {
                            background-color: var(--shade_EAF0F3);
                        }
                    }
                }
            }
        }
        .toggleSwitchContainer {
            border-left: none;
            border-radius: 0 var(--radius2) var(--radius2) 0 !important;
            label {
                width: 50px;
            }
            span {
                width: 50px;
            }
        }
        &.disablePicker {
            opacity: 0.5;
            cursor: not-allowed;
            .labelWrapper {
                pointer-events: none;
                .inputWrapper {
                    background-color: var(--shade_f7f7f7);
                    .input {
                        input {
                            background-color: var(--shade_f7f7f7);
                        }
                    }
                }
            }
            .toggleSwitchContainer {
                pointer-events: none;
            }
            &:hover {
                border: 1px solid transparent;
            }
        }
    }
}

[data-theme="darkMode"] {
    .wrapper .dropdownWrapper .labelWrapper {
        background: var(--darkMode_genericGrey);
        .inputWrapper input {
            background: var(--darkMode_genericGrey);
            color: var(--darkMode_primaryGrey);
        }
    }
}
