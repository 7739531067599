.table {
    margin-top: 20px;
    max-width: 641px;
    position: relative;
}

.popUp {
    position: absolute;
    right: -140px;
    white-space: normal;
    > div {
        background: none !important;
    }
}

.popupChannel {
    position: absolute;
    right: -140px;
}

:global {
    .channelTable .t-body {
        // max-height: 265px;
        // overflow-y: auto;
    }
}
