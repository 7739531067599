.noCard {
    padding: 0px!important;
    box-shadow: none;
}

.idWrapper {
    color: var(--grey_shade);
}

.layoutPropertiesWrapper{
    :global {
        .mg-top {
            margin-top: -39px;
            .dp-parent{
                padding-right: 20px;
            }
        }

        .deleteIconVisible{
            span{
                padding-right: 3px;
                visibility: visible!important;
                cursor: pointer!important;
            }
            
        }
    }
}
