.compactFormWrapper{
    border: 1px solid var(--borderColor1);
    border-radius: 8px;
    background: var(--panelBg);
    .compactFormContainer{
        padding: 20px;
    }
    .footer{
        border-top: 1px solid var(--borderColor1);
        padding: 20px;
        display: flex;
        height: 78px;
        justify-content: flex-end;
        button{
            margin-left: 20px;
            margin-right: 0px;
            
        }
    }
}