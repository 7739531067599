.toastLayer {
    padding: 20px;
    border: 1px solid var(--borderColor4);
    border-radius: var(--radius2);
    box-shadow: var(--shadow2);
    position: relative;
    background: var(--shade_ffffff);
    color: var(--grey_shade);
    margin-top: 20px;
    font-family: var(--font);

    //default info color
    .iconWrapper svg {
        color: var(--updateText);
    }
    &.Success {
        .iconWrapper svg {
            color: var(--brandColor);
        }
    }
    &.Error {
        .iconWrapper svg {
            color: var(--error);
        }
    }
    &.Warning {
        .iconWrapper svg {
            color: var(--warningYellow);
        }
    }
    .labelWrapper {
        padding-left: 10px;
        padding-right: 20px;
        flex: 1;
        word-break: break-all;
        .title {
            color: var(--dark_color);
            font-weight: var(--fontMedium);
            line-height: 20px;
            margin-bottom: 10px;
        }
    }
    .iconClose svg {
        color: #aab8c2;
        cursor: pointer;
        &:hover {
            color: var(--grey_shade);
        }
    }

    .btnAction {
        color: var(--brandColor);
    }
    .btnCancel {
        color: var(--toggleReadOnlyColor);
    }
}

[data-theme="darkMode"] {
    .toastLayer {
        background: var(--darkMode_genericGrey);
        color: var(--darkMode_primaryGrey);

        .labelWrapper {
            word-wrap: break-word;
            .title {
                color: var(--darkMode_white);
            }
        }

        .iconClose svg {
            color: var(--darkMode_primaryGrey);
            &:hover {
                color: var(--darkMode_white);
            }
        }
    }
}

:global {
    //applied to all, can be separated later if any conflicts
    .Toastify__toast-container,
    .Toastify__toast,
    .Toastify__toast-body {
        padding: 0 !important;
        margin: 0 !important;
        background: none !important;
        width: 400px !important;
        top: 0 !important;
        box-shadow: none !important;
    }

    .Toastify__progress-bar {
        background: linear-gradient(
            90deg,
            var(--toggleDefaultColor),
            var(--tabBgColor)
        ) !important;
    }

    [data-theme="darkMode"] {
        .Toastify__progress-bar {
            background: linear-gradient(
                90deg,
                var(--darkMode_placeholder),
                var(--darkMode_text)
            ) !important;
        }
    }
}
