.featureToolbarContainer {
    margin-top: 10px;
}

.rulesContainer {
    min-height: calc(100vh - 196px);

    &.noData_rulesContainer {
        padding-top: 35px;
    }
    .ruleList {
        .ruleItem {
            padding: 20px;
            border: 1px solid var(--borderColor1);
            border-radius: 8px;
            position: relative;

            .actionContainer {
                display: flex;
                position: absolute;
                right: 0px;
                top: 15px;
            }

            .ruleRow {
                margin-bottom: 10px;
                label {
                    color: var(--textColor2);
                }
                .value {
                    font-family: var(--fontMedium);
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .divider {
        margin: 10px 0;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--textColor2);
        position: relative;
        &:before,
        &:after {
            border: 1px dotted var(--borderColor1);
            content: "";
            width: calc(50% - 20px);
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(50%);
        }
        &:after {
            position: absolute;
            left: auto;
            right: 0;
        }
    }
}

.addNewRuleBtn {
    svg {
        margin-right: 10px;
    }
}

.footer {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0;
    margin-top: 10px;
    position: sticky;
    transform: translateY(20px);
    bottom: 0px;
    right: 0;
    z-index: 1;
    background: var(--panelBg);
    border-top: 1px solid var(--borderColor1);
    button {
        margin-left: 20px;
    }
}
