.sortable-list-wrap {
    width: 100%;
}
li.sortable-list-item {
    border: 1px solid var(--borderColor4);
    border-radius: var(--radius2);
    margin-right: 15px;
    cursor: pointer;
    position: relative;
    margin-bottom: 10px;
    .tag-edit {
        text-align: left;
        width: 100%;
        & > div {
            margin-right: 20px;
            &:last-child {
                margin-right: 0px;
            }
            p {
                margin-bottom: 10px;
            }
        }
    }
    &:last-child {
        margin-right: 0;
    }
    &:hover {
        background: var(--dragHandleBgColor);
        border-color: var(--borderColor7);
        .drag-item {
            border-color: var(--borderColor7);
        }
        .remove-item {
            display: block;
        }
    }
    .editPopupToggle {
        position: absolute;
        left: 20px;
        bottom: 20px;
    }
    .item-content-wrap {
        padding: 10px;
        .item-bullet {
            border: 2px solid var(--borderColor6);
            border-radius: 24px;
            width: 14px;
            height: 14px;
            margin-right: 10px;
            &.enabled {
                background: var(--brandColor);
                border-color: var(--brandColor);
            }
            &.disabled {
                background: var(--toggleDefaultColor);
                border-color: var(--toggleDefaultColor);
            }
        }
        .item-key,
        .item-value {
            line-height: var(--lineHeight);
            font-weight: var(--fontMedium);
        }
        .item-key {
            color: var(--grey_shade);
            border-right: 1px solid var(--borderColor6);
            padding-right: 10px;
        }
        .item-value {
            padding-left: 10px;
            color: var(--dark_color);
        }
    }

    .drag-item {
        background: var(--dragHandleBgColor);
        width: 30px;
        border-right: 1px solid var(--dragHandleBgColor);
        border-top-left-radius: var(--radius2);
        border-bottom-left-radius: var(--radius2);
        color: var(--listing-search-placeholder);
    }
    .remove-item {
        position: absolute;
        right: -9px;
        top: 50%;
        margin-top: -9px;
        color: var(--iconColor);
        width: 18px;
        height: 18px;
        cursor: pointer;
        display: none;
        background: var(--panelBg);
        border-radius: 50%;
        &:hover {
            color: var(--grey_shade);
        }
    }

    div[data-id="tooltip"] {
        align-self: center;
    }
}
