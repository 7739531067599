@keyframes applyWidth {
    to {
        width: 40px;
    }
}

.dropDownPopUpWrapper {
    box-shadow: 0px 32px 32px -24px rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, 0.9);
    border-radius: 8px;
    width: 202px;
    .headerPopUp {
        font-weight: 500;
        font-size: 14px;
        color: var(--dark_color);
        padding: 20px 0px 20px 20px;
        border-bottom: 1px solid var(--shade_EAF0F3);
    }
    .subMenuWrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-top: 10px;
        padding-bottom: 10px;
        a {
            font-weight: 400;
            font-size: 14px;
            color: var(--grey_shade);
            padding: 10px 0px 10px 10px;
            margin-left: 10px;
            margin-right: 10px;
            width: 100%;
            &:hover {
                color: var(--dark_color);
                background: var(--drpdownBG);
                border-radius: 8px;
            }

            &.active {
                color: var(--brandColor);
            }
        }
    }
}

.liWrapper {
    list-style: none;
    * {
        transition: background linear 200ms;
    }
    .menuLink {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: none;
        cursor: pointer;
        padding: 7px 10px;
        margin: 7px 10px;

        max-width: 227px;
        width: 100%;

        &:hover {
            background: var(--rowHoverBg);
            border-radius: 8px;
            .textWrapper {
                :global(.icon-normal) {
                    display: none !important;
                }
                :global(.icon-hover) {
                    display: block !important;
                }
                .text {
                    font-weight: 500;
                }
            }
        }

        .borderLine {
            position: absolute;
            width: 3px;
            height: 20px;
            left: 0px;
            background-color: var(--brandColor);
            border-radius: 18px;
            left: -10px;
            top: Calc(50% - 11px);
        }

        .textWrapper {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            svg {
                color: var(--grey_shade);
                position: relative;
                top: 2px;
            }

            &.iconActive {
                svg {
                    color: var(--brandColor);
                }
            }

            .text {
                display: flex;
                flex: none;
                font-weight: 400;
                font-size: 16px;
                color: var(--dark_color);
                margin-left: 10px;
            }
        }

        &.menuLinkActive {
            .textWrapper {
                .text {
                    font-weight: 500;
                    color: var(--dark_color);
                }
            }
        }

        .arrowIconWrapper {
            transition: 0.3s linear;
            transform: rotate(0deg);
            svg {
                color: var(--shade_74838F);
            }
            &.arrowIconActive {
                transition: 0.3s linear;
                transform: rotate(180deg);
                margin-top: -5px;
                svg {
                    color: var(--dark_color);
                }
            }
        }

        &.leftNavCollapseMenuLink {
            display: flex;
            flex: none;
            margin: 7px 15px;
            border-radius: 8px;
            animation: applyWidth 2s;
            animation-fill-mode: forwards;
            .borderLine {
                left: -15px;
            }
            .textWrapper {
                .text {
                    margin-left: 32px;
                }
            }
        }
    }

    .LiLink {
        max-height: 0;
        overflow: hidden;
        margin: 0px 20px;
        border-bottom: 1px solid transparent;
        transition: max-height 200ms ease;

        &.LiLinkActive {
            border-bottom: 1px solid var(--borderColor4);
            transition: max-height 0.7s linear;
            max-height: 1000px;
        }

        .subMenuLink {
            font-weight: 400;
            font-size: 14px;
            color: var(--grey_shade);
            margin-left: 19px;

            a {
                padding: 10px 0px 10px 10px;
                width: 100%;
                height: 100%;
                display: block;
                cursor: pointer;
            }
            &:hover {
                background: var(--shade-F0F7F7);
                border-radius: 8px;
                color: var(--dark_color);
            }
            .active {
                color: var(--brandColor);
            }

            &:last-child {
                margin-bottom: 10px;
            }
        }

        .subMenuLinkChild {
            margin-left: 29px;
        }
    }

    &:last-child {
        .LiLink {
            &.LiLinkActive {
                border-bottom: none;
            }
        }
    }

    &.leftNavIsCollapsed {
        .menuLink {
            flex: none;
        }
    }
}

[data-theme="darkMode"] {
    .dropDownPopUpWrapper {
        background: var(--darkMode_PanelBg);
    }

    .liWrapper {
        .menuLink {
            &:hover {
                background: var(--darkMode_genericGrey);
            }
        }

        .LiLink {
            .subMenuLink {
                &:hover {
                    background: var(--darkMode_genericGrey);
                }
            }
        }
    }
}
