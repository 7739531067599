.infoText {
    min-height: 38px;
    // display: inline-flex;
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 8px;
    font-size: 14px;
    position: relative;
    color: var(--grey_shade);
    align-items: center;
    font-weight: 500;

    &.success {
        background-color: var(--rowHoverBg);
        border: 1px solid #41c3a9;
        .infoIcon {
            color: #41c3a9;
        }
    }
    &.danger {
        background-color: rgba($color: #e76e6e, $alpha: 0.15);
        border: 1px solid #e76e6e;
        .infoIcon {
            color: #e76e6e;
        }
    }
    &.warning,
    &.infoWarning {
        background-color: #fffaf3;
        border: 1px solid #ffead2;
        .infoIcon {
            color: #ff971b;
        }
    }
    &.info {
        background-color: #e3f0f6;
        color: #419cc3;
    }
    .infoIcon {
        margin-right: 10px;
        display: inline-flex;
    }
    .crossIcon {
        position: absolute;
        top: 12px;
        right: 10px;
        margin-left: 20px;
        display: flex;
        cursor: pointer;
        z-index: 1;
        color: var(--grey_shade);
        &:hover {
            color: var(--errorHover);
        }
    }
}

[data-theme="darkMode"] {
    .infoText {
        &.info {
            background-color: rgba(150, 180, 215, 0.2);
            color: #81919e;
            border: 1px solid #e0eeff;
        }
        &.warning {
            background-color: rgba(221, 197, 169, 0.2);
            border: 1px solid #ffead2;
            color: #81919e;
            .infoIcon {
                color: #ff971b;
            }
        }
        &.danger {
            background-color: rgba(209, 151, 151, 0.12);
            border: 1px solid #ffdcdc;
        }
    }
}
