:global {
    .t-row {
        &:hover,
        &.rowActive {
            .actionBtns .btnWrap {
                visibility: visible;
            }
        }
        .containsNestedTable__cell {
            &.actionBtns {
                position: sticky !important;
                right: 0;

                .btnWrap {
                    background: inherit;
                    display: flex;
                    align-items: center;
                    height: inherit;
                    width: 58px !important;
                    min-width: 58px !important;
                    position: relative;
                    visibility: visible;
                }

                .editIconWrapper {
                    position: absolute;
                    right: 10px;
                    padding: 5px 10px;
                    background: var(--shade_ffffff);
                    box-shadow: -2px 1px 8px rgba(0, 0, 0, 0.15%);
                    .editIcon {
                        &:not(.accordionArw, .revertIcon) {
                            display: none;
                        }
                        &.accordionArw,
                        &.revertIcon {
                            display: block;
                        }
                        &.accordionArw {
                            transform: rotate(180deg);
                            color: var(--dark_color);
                        }
                    }
                    .moreActionsBtn {
                        display: flex;
                        span {
                            background: var(--grey_shade);
                        }
                    }

                    &:hover {
                        .editIcon:not(.accordionArw, .revertIcon) {
                            display: block;
                        }
                        .moreActionsBtn {
                            display: none; // Commenting below styles as discussed we are not showing more icons on hover
                            // span {
                            //     display: none;
                            // }
                        }
                        // .moreActionsBtn span {
                        //     background: var(--dark_color);
                        // }
                    }
                }
            }
        }
    }

    .actionBtns {
        justify-content: flex-end;
        width: 106px;
        padding: 10px 0 !important;
        position: sticky !important;
        right: 0;
        text-align: right;

        &:not(.actionBtns--compact) .btnWrap {
            visibility: hidden;
        }

        &.actionBtns--compact {
            .btnWrap {
                background: inherit;
                display: flex;
                align-items: center;
                height: inherit;
                width: 58px !important;
                min-width: 58px !important;
                position: relative;
                height: 28px;
            }

            .editIconWrapper {
                position: absolute;
                right: 10px;
                padding: 5px 10px;

                .editIcon {
                    display: none;
                }
                .moreActionsBtn {
                    display: flex;
                }

                &:hover {
                    background: var(--shade_ffffff);
                    box-shadow: -2px 1px 8px rgba(0, 0, 0, 0.15%);
                    .editIcon {
                        display: block;
                    }
                    .moreActionsBtn {
                        display: none;
                        // span {
                        //     // background: var(--dark_color);
                        //     display: none;
                        // }
                    }
                }
            }
        }
    }

    .editIconWrapper {
        // position: absolute;
        // right: 10px;
        display: flex;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        padding: 5px 20px;

        &.compact {
            position: absolute;
            right: 10px;

            .editIcon {
                display: none;
            }
            .moreActionsBtn {
                display: flex;
            }

            &:hover {
                background: #fff;
                box-shadow: -2px 1px 8px rgba(0, 0, 0, 0.15%);
                .editIcon {
                    display: block;
                }
                .downloadIcon {
                    display: block;
                }
                .moreActionsBtn {
                    display: none;
                    // span {
                    //     background: var(--dark_color);
                    //     display: none;
                    // }
                }
            }
        }

        &.accordionOpen {
            background: #fff;
            box-shadow: -2px 1px 8px rgba(0, 0, 0, 0.15%);

            .accordionArw {
                display: block;
                transform: rotate(180deg);
            }
        }

        .editIcon {
            cursor: pointer;
            display: inline-block;
            width: 18px;
            height: 18px;
            transition: 250ms linear color;
            // display: none;
            &:last-child {
                margin-right: 0px;
            }
            &.disabled {
                cursor: auto;
                &:hover,
                &.active {
                    color: var(--iconColor);
                }
            }
            &.addIcon {
                transform: rotate(45deg);
            }
        }

        .moreActionsBtn {
            display: none;
            gap: 4px;
            span {
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background: #74838f;
            }
        }

        &:hover {
            .moreActionsBtn {
                display: none;
            }
        }
    }
}
