.table-footer-wrap {
    padding: 10px;
    user-select: none;
    position: sticky;
    bottom: 0px;
    left: 0px;
    width: 100%;
    background: var(--panelBg);
    border-top: 2px solid var(--borderColor5);
    z-index: 10;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;

    &.showBottomSpace {
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.04);
        bottom: 20px;
    }

    .pages-description {
        font-size: 14px;
        color: var(--grey_shade);
        margin-left: 30px;
    }
    ul {
        li {
            width: auto;
            min-width: 30px;
            padding: 0 5px; // Added as 3 digit numbers were overlapping
            line-height: 28px;
            border-radius: var(--radius2);
            color: var(--pagination-color);
            cursor: pointer;
            text-align: center;
            &:hover {
                background: var(--shade_EAF0F3);
                color: var(--pagination-colorHover);
            }
            &.active {
                color: var(--btnColor);
                background: var(--brandColor);
            }

            &.prev {
                transform: rotate(90deg);
            }
            &.next {
                transform: rotate(-90deg);
            }
            &.disabled {
                pointer-events: none;
                opacity: 0.6;
            }
        }
        .start {
            svg {
                transform: rotate(180deg);
            }
        }
    }
    .show-count-wrap {
        p {
            margin-right: 10px;
        }
    }
}

.bottomSpace {
    position: sticky;
    bottom: 0px;
    left: 0;
    right: 0;
    height: 21px;
    background: var(--bodyBg);
    z-index: 1;
    margin-left: auto;
}
