.accordianAddTableWrapper {
    padding: 0;
    border: 1px solid var(--borderColor1);
    max-width: 100%;
    width: 100%;
    .header {
        display: flex;
        padding: 10px 20px;
        background-color: var(--headerBg);
        border-radius: var(--radius2) var(--radius2) 0 0;
        border-bottom: 1px solid var(--borderColor1);
        .subHeader {
            color: var(--grey_shade);
            font-size: var(--fontSize);
            text-transform: capitalize;

            &:nth-child(1),
            &:nth-child(2) {
                max-width: 140px;
                width: 100%;
            }
            &:nth-child(1) {
                margin-right: 20px;
            }
            &:nth-child(3),
            &:nth-child(4) {
                max-width: 250px;
                width: 100%;
            }
            margin-right: 30px;
        }
    }
    .body {
        display: flex;
        padding: 20px;
        position: relative;
        background: var(--shade_ffffff);
        .input {
            max-width: 140px;
            width: 100%;
            height: 38px;
            margin-right: 30px;
            &:first-child {
                margin-right: 20px;
            }
            input[type="text"] {
                height: 100%;
                padding: 10px;
                height: 38px;
            }
        }
        button {
            margin-left: auto;
        }
    }
    .customSelectContainerLayer,
    .secondCustomSelectContainerLayer {
        max-width: 250px;
        width: 100%;
        margin-bottom: 0px;
        margin-right: 30px;
    }
    .secondCustomSelectContainerLayer {
        [class*="dropdown"]:not([class*="dropdownList"]) {
            top: -240px !important;
        }
    }
}

.innerHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    .noDataContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        width: 100%;
        .noDataGraphicsContainer {
            display: block;
            img {
                height: 150px;
                width: 150px;
            }
            div {
                margin: 20px 0;
                color: var(--textColor2);
                font-weight: 500;
            }
        }
    }
    .noDataGraphicsContainer {
        display: none;
    }
}

.popUpFooter {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0 20px 0;
    position: absolute;
    width: 100%;
    bottom: 20px;
    transform: translateY(20px);
    right: 20px;
    z-index: 1;
    border-top: 1px solid var(--borderColor1);
    background-color: var(--panelBg);
    grid-gap: 20px;
}

.saveBtnWrapper {
    display: flex;
    position: absolute;
    right: 20px;
    bottom: 20px;
    button:nth-child(1) {
        margin-right: 20px;
    }
}
