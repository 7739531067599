.panelGridBox {
    border-radius: var(--radius2);
    border: 1px solid var(--borderColor1);
    padding: 20px;
    max-width: var(--maxChildContainer);
    position: relative;
    margin-bottom: 20px;
}

.panelGridBox:last-child,
.fieldRow:last-child {
    margin-bottom: 0px;
}
