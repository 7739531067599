.TargettingParamsWrapperComp {
    position: relative;
    border: 1px solid var(--borderColor1);
    border-radius: var(--radius2);
    max-width: 640px;
    margin-bottom: 20px;

    .header {
        display: flex;
        align-items: center;
        gap: 20px;
        border-bottom: 1px solid var(--borderColor4);
        font-weight: var(--fontMedium);
        font-size: var(--fontSize);
        color: var(--grey_shade);

        .headerText {
            display: flex;
            align-items: center;
            grid-gap: 10px;
            padding: 20px;
            &.rightBorder {
                border-right: 1px solid var(--borderColor4);
            }
        }

        .headerSelect {
            max-width: 120px;
        }

        .editIconWrapper {
            top: 18px;
            position: absolute;
            display: none;
            right: 20px;
            .TooltipWrapper {
                cursor: pointer;
                &:nth-child(2) {
                    margin-left: 20px;
                }
            }

            .ToggleSwitchWrapper {
                margin-left: 20px;
            }
        }
    }
    .body {
        padding: 20px;
    }

    .popupWrapper {
        position: absolute;
        right: 0px;
        z-index: 899;
        // z-index: 999;
    }
    &:hover {
        .editIconWrapper {
            display: flex;
        }
    }
}

[data-theme="darkMode"] {
    .TargettingParamsWrapperComp {
        background: var(--darkMode_depthGrey);
    }
}
