.timeOfDayContainer {
  .timeWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    flex-grow: 1;
    .startTimeWrapper {
      margin-right: 10px;
    }
  }

  .addBtnWrapper {
    // margin-top:20px;
    // border-bottom: 1px solid var(--grey_shade);
    text-align: right;
    // padding-bottom: 10px;
  }

  ul {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid var(--shade_EAF0F3);
    margin-top:20px;
    margin-left:-20px;
    margin-right:-20px;
    padding-left:20px;
    padding-right:20px;
    li {
      display: flex;
      position: relative;
      // cursor: pointer;
      background: transparent;
      border: 1px solid var(--borderColor3);
      margin-right: 12px;
      margin-top: 20px;
      padding: 5px 14px 5px 10px;
      border-radius: var(--radius3);
      font-weight: var(--fontMedium);
      font-size: var(--fontSize);
      span:nth-child(1) {
        color: var(--grey_shade);
      }
      span:nth-child(2) {
        color: var(--dark_color);
      }
      .crossIcon {
        visibility: hidden;
        right: -5px;
        top: 7px;
        position: absolute;
        svg:hover {
          color: var(--dark_color) !important;
          cursor: pointer;
        }
      }
      .elementSecond {
        margin-left: 20px;
      }
      &:hover{
        .crossIcon {
          visibility: visible;
        }
       }
      //   .secondInput{
      //       margin-left:20px;
      //   }
    }
  }
}
