.graphContainer {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    .graphItem {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        border-right: 2px dashed var(--borderColor4);
        flex: 1;
        &:first-child {
            border-left: 2px dashed var(--borderColor4);
        }
        &:last-child {
            border-right: none;
        }
        &.noRightBorder {
            border-right: none;
        }
        &.halfBorder {
            position: relative;
            &::before {
                content: "";
                border-right: 2px dashed var(--borderColor4);
                position: absolute;
                bottom: 0;
                left: 0;
                height: 34px;
                width: 1px;
            }
        }
        .label {
            color: var(--grey_shade);
            position: relative;
            margin-left: 5px;
            top: 10px;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.022em;
            max-width: 90px;
            white-space: normal;
            &.dropped {
                color: var(--error);
                padding-left: 5px;
                display: flex;
                align-items: center;
                position: initial;
            }
        }
        .droppedLabel {
            &::before {
                content: "";
                border: 2px solid var(--error);
                position: absolute;
                height: 20px;
            }
        }
    }
    .graphLine {
        display: flex;
        flex: 1;
        min-height: 20px;
        position: relative;
    }
}
