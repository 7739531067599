#csvForm {
    margin-bottom: 0px;
    .uploadSuccessWrapper {
        display: flex;
        align-items: center;
        .uploadSuccessText {
            display: flex;
            align-items: center;
            font-weight: var(--fontMedium);
            font-size: var(--fontSize);
            color: var(--brandColor);
            svg {
                margin-right: 5px;
                color: var(--brandColor);
            }
        }
    }
}

.disableComponent {
    pointer-events: none;
    opacity: 0.5;
}

.wrapperDiv {
    width: 100%;
}

.csvButtons {
    display: flex;
    align-items: flex-end;
    .browseButton {
        margin-right: 20px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border: 1px solid var(--borderColor4);
        color: var(--shade_74838F);
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        &:hover {
            color: var(--brandColor);
            background: #fff;
            border: 1px solid var(--brandColor);
        }
    }
    .uploadButton {
        margin-left: 20px;
        height: 48px;
        width: 162px;
        &:disabled {
            background-color: #cad1d5;
        }
    }
    .browseCsvWrapper {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        background: var(--shade_FAFCFC);
        border: 1px solid var(--borderColor1);
        border-radius: var(--radius2);
        color: var(--shade_74838F);
        width: 360px;
        height: 48px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 0;

        .uploadIconWrapper {
            display: flex;
            align-items: center;
            svg {
                margin-right: 5px;
                fill: var(--shade_74838F) !important;
            }
        }

        .fileName {
            position: relative;
            z-index: 1;
            font-size: var(--fontSize);
            font-weight: var(--fontRegular);
            color: var(--dark_color);
            display: flex;
            .name {
                overflow: hidden;
                max-width: 65%;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            .removeFileBtn {
                font-weight: var(--fontRegular);
                font-size: var(--fontSize);
                line-height: 18px;
                color: red;
                margin-left: 5px;
                cursor: pointer;
            }
        }
    }

    .csv {
        position: relative;
        align-items: center;
        width: 100%;
    }
}

@media screen and (max-width: 1362px) {
    .csvButtons {
        .browseCsvWrapper {
            width: 168px;
            .fileName {
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                .name {
                    max-width: 50%;
                }
            }
        }
        .browseButton {
            margin-right: 10px;
            padding: 9px;
        }
        .uploadButton {
            padding: 9px;
        }
    }
}

@media screen and (max-width: 1362px) {
    .csvButtons {
        .browseCsvWrapper {
            width: 280px;
            .fileName {
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                .name {
                    max-width: 50%;
                }
            }
        }
        .browseButton {
            margin-right: 10px;
            padding: 9px;
        }
        .uploadButton {
            padding: 9px;
        }
    }
}

@media screen and (max-width: 1200px) and (min-width: 1131px) {
    .csvButtons {
        .browseCsvWrapper {
            width: 250px;
        }
    }
}

@media screen and (max-width: 1130px) {
    .csvButtons {
        .browseCsvWrapper {
            width: 160px;
        }
    }
}

.formField {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;

    &:focus {
        background-color: white;
    }
}
