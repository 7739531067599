.leftPanel {
    width: 16%;
    border-right: 1px solid var(--borderColor4);

    .option {
        padding: 0 20px;
        margin-bottom: 20px;
        color: var(--grey_shade);
        // color: var(--brandColor);
        cursor: pointer;
        &:first-child {
            margin-top: 20px;
        }
        // svg {
        //     color: var(--grey_shade);
        // }

        // &:active {
        //     color: var(--brandColor);
        //     // svg {
        //     //     color: var(--brandColor);
        //     // }
        // }

        &.selectedOption {
            color: var(--brandColor);
            &:active {
                color: var(--brandColor);
                // svg {
                //     color: var(--brandColor);
                // }
            }
        }
    }
}

.rightPanel {
    width: 84%;
    display: flex;
    &.rightPanelglobalSettings {
        flex-direction: column;
        height: 100%;
        overflow-y: scroll;
    }
    .topActionBar {
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        background: var(--panelBg);
        border-bottom: 1px solid var(--borderColor1);
        z-index: 1;
        height: 48px;
        display: flex;
        align-items: center;
    }
    .drawer {
        border-left: 1px solid var(--borderColor1);
        border-top: 1px solid var(--borderColor1);
        min-width: 450px;
        max-width: 450px;
        z-index: 100;
        background: var(--panelBg);
        height: calc(100% + 30px);
    }

    .ruleSection {
        width: 22%;
        border-right: 1px solid var(--borderColor4);

        .header {
            padding: 10px;
            border-bottom: 1px solid var(--borderColor4);

            .addBtn {
                color: var(--brandColor);
                cursor: pointer;
            }
        }

        .ruleOption {
            height: 38px;
            padding: 0 10px;
            background: var(--shade_FAFCFC);
            position: relative;

            .ruleTxt {
                width: 100%;
                color: var(--dark_color);
            }

            .deleteIcon {
                position: absolute;
                right: 10px;
                cursor: pointer;
            }
        }
    }

    .templateWrapper {
        width: 640px;
        margin-top: 20px;
        margin-left: 20px;

        .editWrapper {
            width: 61px;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            font-size: 12px;
            color: var(--grey_shade);
            cursor: pointer;
            svg {
                margin-right: 6px;
            }

            &:hover {
                background: var(--addBg);
                border-radius: 8px;
                color: var(--brandColor);
                svg {
                    color: var(--brandColor);
                }
            }
        }
    }

    .noSelectionWrapper {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .selectonText {
            font-weight: 500;
            font-size: 16px;
            margin-top: 20px;
            margin-bottom: 10px;
            color: var(--dark_color);
        }

        .content {
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: var(--grey_shade);
            text-align: center;
        }
    }

    .dataWrapper {
        padding: 0px 20px 20px 20px;
        .boxWrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            padding-left: 20px;
            height: 86px;
            border: 1px solid var(--shade_EAF0F3);
            border-radius: 8px;
            margin-bottom: 20px;
            .title {
                margin-bottom: 10px;
                font-weight: 500;
                font-size: 14px;
                color: var(--grey_shade);
            }
            .value {
                font-weight: 400;
                font-size: 14px;
                color: var(--dark_color);
            }

            &:last-child {
                margin-bottom: 20px;
            }

            &.noConfig {
                border: 0px;
                padding-left: 0px;
                height: 50px;
            }
        }
    }
    .fallbackWrapper {
        position: relative;
        margin-top: 20px;
        margin-left: 20px;
        width: 100%;
        overflow-y: scroll;
        .containerList {
            width: 269px;
            position: absolute;
            top: 43px;
            left: 1px;
        }

        .cardWrapper {
            max-width: 680px;
            padding: 20px;
            margin-bottom: 20px;
            background: var(--shade_ffffff);
            border: 1px solid var(--borderColor1);
            box-sizing: border-box;
            border-radius: 8px;
            position: relative;
            word-break: break-all;

            &:hover {
                .deleteIconWrapper {
                    visibility: visible;
                }
            }

            .deleteIconWrapper {
                position: absolute;
                top: 20px;
                right: 20px;
                visibility: hidden;
                cursor: pointer;
                &:hover {
                    color: var(--errorHover);
                }
            }
            .containerTypeWrapper {
                display: flex;
                margin-bottom: 20px;
            }

            .label {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                color: var(--grey_shade);
                margin-right: 10px;
            }

            .fallbackContainerDropdownWrapper {
                .label {
                    margin-bottom: 10px;
                }
            }
        }
    }
}
