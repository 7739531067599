@font-face {
    font-family: "icomoon";
    src: url(/src/assets/fonts/icomoons/icomoon.eot?v3.05);
    src: url(/src/assets/fonts/icomoons/icomoon.eot?v3.05) format("embedded-opentype"),
        url(/src/assets/fonts/icomoons/icomoon.ttf?v3.05) format("truetype"),
        url(/src/assets/fonts/icomoons/icomoon.woff?v3.05) format("woff"),
        url(/src/assets/fonts/icomoons/icomoon.svg?v3.05) format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "icomoon" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-New-Features:before {
    content: "\e158";
}
.icon-Profile:before {
    content: "\e157";
}
.icon-Calendar:before {
    content: "\e156";
}
.icon-ArrowData:before {
    content: "\e155";
}
.icon-GraphToggle:before {
    content: "\e154";
}
.icon-Undo:before {
    content: "\e153";
}
.icon-AddAction:before {
    content: "\e152";
}
.icon-Details:before {
    content: "\e151";
}
.icon-Disable:before {
    content: "\e150";
}
.icon-TemplateDescription:before {
    content: "\e149";
}
.icon-URL:before {
    content: "\e148";
}
.icon-Weight:before {
    content: "\e147";
}
.icon-Featuremap:before {
    content: "\e146";
}
.icon-Featureset:before {
    content: "\e145";
}
.icon-Maximize:before {
    content: "\e144";
}
.icon-Minimize:before {
    content: "\e143";
}
.icon-ViewTemplate:before {
    content: "\e142";
}
.icon-Categories:before {
    content: "\e141";
}
.icon-Configurations:before {
    content: "\e140";
}
.icon-LeadGenerations:before {
    content: "\e139";
}
.icon-OfferGroups:before {
    content: "\e138";
}
.icon-Questions:before {
    content: "\e137";
}
.icon-Reports:before {
    content: "\e136";
}
.icon-Export:before {
    content: "\e135";
}
.icon-Refresh:before {
    content: "\e134";
}
.icon-DeleteBin:before {
    content: "\e133";
}
.icon-ArrowLast:before {
    content: "\e132";
}
.icon-Hide:before {
    content: "\e131";
}
.icon-Clone:before {
    content: "\e130";
}
.icon-HistoryUpdate:before {
    content: "\e129";
}
.icon-Error:before {
    content: "\e128";
}
.icon-ColumnSort:before {
    content: "\e127";
}
.icon-Filter:before {
    content: "\e126";
}
.icon-Check:before {
    content: "\e125";
}
.icon-Download:before {
    content: "\e124";
}
.icon-Info:before {
    content: "\e123";
}
.icon-Logout:before {
    content: "\e122";
}
.icon-View:before {
    content: "\e121";
}
.icon-Reset:before {
    content: "\e120";
}
.icon-Upload:before {
    content: "\e119";
}
.icon-CreateAdUnits:before {
    content: "\e118";
}
.icon-CreateSurvey:before {
    content: "\e117";
}
.icon-CreateSurveyPaths:before {
    content: "\e116";
}
.icon-CrrateOfferPaths:before {
    content: "\e115";
}
.icon-Delete:before {
    content: "\e114";
}
.icon-Time:before {
    content: "\e113";
}
.icon-Edit:before {
    content: "\e112";
}
.icon-Dropdown:before {
    content: "\e111";
}
.icon-DropDownArrow:before {
    content: "\e110";
}
.icon-Plus:before {
    content: "\e109";
}
.icon-Minus:before {
    content: "\e108";
}
.icon-DeleteMinus:before {
    content: "\e107";
}
.icon-Drag:before {
    content: "\e106";
}
.icon-Close:before {
    content: "\e105";
}
.icon-Tick:before {
    content: "\e104";
}
.icon-CloseFilled:before {
    content: "\e103";
}
.icon-Search:before {
    content: "\e102";
}
.icon-Hamburger:before {
    content: "\e101";
}
/* .icon-Collapse:before {
    content: "\e167";
}
.icon-Expand:before {
    content: "\e166";
} */
