.container {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        height: 100px;
        width: 100px;
        padding-right: 20px;
        overflow: unset;
        path {
            fill: var(--brandColor);
        }
    }

    .ball {
        transform-origin: center;
        transform: translate(0px, 0px);
        animation: slide 1.5s infinite linear;
    }
}

@keyframes slide {
    0% {
        transform: translate(0px, -20px);
        opacity: 0.2;
    }

    20% {
        transform: translate(0px, 30px);
        opacity: 1;
    }

    25% {
        transform: translate(7px, 28px);
    }

    50% {
        transform: translate(9px, 10px);
    }

    75% {
        transform: translate(16px, 10px);
        fill: var(--brandColor3);
    }

    85% {
        transform: translate(18px, 30px);
        opacity: 1;
    }

    100% {
        transform: translate(30px, 30px);
        opacity: 0;
    }
}
