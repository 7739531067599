.add-new-question {
    width: 100%;
    // width: var(--maxChildContainer);
    background: var(--shade_ffffff);
    border: 1px solid var(--borderColor1);
    box-sizing: border-box;
    border-radius: 8px;
    .tag-list-dragable-wrap {
        margin-bottom: 10px;
    }

    .question-container {
        padding: 20px;
        .has-offer {
            p {
                margin-bottom: 0px;
                margin-right: 10px;
            }
        }
    }
    .save-container {
        border-top: 2px solid var(--borderColor5);
        padding: 20px;
        justify-content: flex-end;
        Button {
            margin-right: 20px;
            &:last-child {
                margin-right: 0px;
            }
        }
    }
    .tableWrapper {
        max-width: var(--maxChildContainer);
    }
}
