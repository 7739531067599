.selectClass {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.questionTitleRichText {
    position: relative;
    z-index: 3;
}

.imgPreviewWrapper{
    img{
        width:280px;
        height: 280px;
        object-fit: contain;
    }
}

.imageHover{
    &:hover{
        color: var(--brandColor);
    }
}
