.heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 638px;
}

.editLink {
    color: var(--grey_shade);
    text-decoration: underline;
    text-decoration-color: var(--grey_shade);
    display: flex;
    align-items: center;
    margin-right: 0px;
    &.enableLink {
        cursor: pointer;
        &:hover {
            color: var(--brandColor);
            text-decoration-color: var(--brandColor);
        }
    }
    &.disabledLink {
        cursor: not-allowed;
    }
}

.offerWrapper {
    color: var(--dark_color);
}
