.accordionWrap {
    border-radius: 8px;
    border: 1px solid var(--borderColor1);
    margin: 0 0 10px;
    transition: all 0.3s;
    &:last-child {
        margin: 0 0 20px;
    }
    .accordion {
        padding: 15px 20px 10px 10px;
        border-radius: 8px;
        position: relative;
        cursor: pointer;
        transition: all 0.3s;
        &::after {
            content: "\e181";
            font-family: "icomoon";
            position: absolute;
            right: 20px;
            top: calc(50% - 8px);
            width: 14px;
            height: 8px;
            // Styling For Icon
            font-family: "icomoon" !important;
            speak: never;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            color: var(--grey_shade);
            /* Better Font Rendering =========== */
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            transition: var(--transition3);
            color: var(--grey_shade);
            pointer-events: none;
        }
        &.open {
            &::after {
                transform: rotate(180deg);
            }
        }
    }

    &.small {
        border: none;
        .accordion {
            display: inline-block;
            padding: 10px 34px 10px 10px;
            background: var(--shade_FAFCFC);
            border: none;
            margin: 0 0 20px;
            position: relative;
            z-index: 2;
            &:hover {
                background: var(--shade_EAF0F3);
            }
            &::after {
                right: 12px;
                top: calc(50% - 5px);
                color: var(--dark_color);
            }
            &.open {
                background: var(--shade_EAF0F3);
                &::after {
                    top: calc(50% - 2px);
                }
            }
        }
    }
    &.large {
        .accordion {
            padding: 15px 20px 10px 10px;
            margin: 0;
            border-radius: 8px;
            position: relative;
            cursor: pointer;
            display: block;
            background: var(--shade_ffffff);
            &:hover {
                background: inherit;
            }
            &::after {
                right: 20px;
                top: 50%;
                margin-top: -5px;
                height: 10px;
            }
            &.open {
                background: var(--shade_ffffff);
                &::after {
                    color: var(--dark_color);
                }
            }
        }
    }
}

[data-theme="darkMode"] {
    .accordionWrap .accordion {
        &:after {
            color: var(--shade_74838F);
        }
        &.open:after {
            color: var(--darkMode_white);
        }
    }
}
