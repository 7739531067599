.wrapper {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1;
}

.iconWrap {
    cursor: pointer;
    color: var(--brandColor);

    &.disabled {
        color: var(--shade_CAD1D4);
        pointer-events: none;
        svg {
            fill: var(--shade_CAD1D4);
        }
    }

    .iconLabel {
        margin-left: 5px;
    }
}
