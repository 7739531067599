.sidePanelheader {
    height: 70px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--borderColor1);
    padding: 0 20px;
    font-size: var(--fontSizeRegular);
    background: var(--shade_ffffff);
    gap: 10px;
    position: relative;
    .totalCount {
        color: var(--grey_shade);
        &__error {
            color: var(--error);
        }
    }

    & > div {
        width: 40%;
    }

    .closeIcon {
        position: absolute;
        right: 20px;
        cursor: pointer;
        &.disabled {
            pointer-events: none;
            svg {
                color: var(--disabled);
            }
        }
    }

    .backToListing {
        width: auto;
        left: -20px;
        transform: translateX(-100%);
        cursor: pointer;
        position: absolute;
        color: var(--grey_shade);
        border-radius: var(--radius2);
        background: hsl(0deg 0% 100% / 70%);
    }
}

.nodeTypeList {
    li {
        cursor: pointer;
        display: flex;
        padding: 20px;
        border: 1px solid var(--borderColor4);
        border-radius: 8px;
        margin-bottom: 20px;
        color: var(--textColor2);
        transition: all 200ms;
        img {
            width: 77px;
            height: 77px;
            margin-right: 24px;
        }
        .header {
            margin-bottom: 10px;
            font-family: var(--fontMedium);
        }
        p {
            font-size: 12px;
        }
        &:hover {
            border: 1px solid #41c3a9;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
            .header {
                color: var(--shade_000000);
            }
        }
    }
}

.formWrap {
    height: calc(100% - 21px);
}
.content {
    height: calc(100% - 78px);
    padding: 20px;
    overflow: auto;
}
.containerEditLink {
    color: var(--grey_shade);
    cursor: pointer;
    &:hover {
        color: var(--brandColor);
    }
}
.footer {
    height: 78px;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding: 0 20px;
    border-top: 1px solid var(--borderColor1);
    gap: 20px;
    background: var(--shade_ffffff);

    //to overlap sidepanel on bottom toolbar
    border-left: 1px solid var(--borderColor4);
    margin-left: -1px;

    &.noTopBorder {
        border-top: none;
    }

    .confirmCancelPopup {
        position: absolute;
        bottom: 30px;
        right: 40px;
        z-index: 1;
    }

    .nodeFooterBtnLoader {
        overflow: hidden;
        border-radius: 10px;
    }
}

.editWrapper {
    display: flex;
    align-items: flex-start;
    svg {
        margin-right: 10px;
    }
    &:hover {
        color: var(--brandColor);
        cursor: pointer;
        svg {
            color: var(--brandColor);
        }
    }
}

[data-theme="darkMode"] {
    .sidePanelheader,
    .content,
    .footer {
        background: var(--panelBg);

        .closeIcon {
            &.disabled {
                svg {
                    color: var(--darkMode_disabled);
                }
            }
        }

        .backToListing {
            background: hsl(0deg 0.01% 10.4% / 70%);
        }
    }

    // .footer {
    //     //because drawer border is transparent in dark mode
    //     border-color: transparent;
    // }
}
