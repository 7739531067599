.container {
    position: relative;
    .overlay {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        &.zoomAvailable{
            cursor: zoom-in;
        }
    }
    iframe {
        position: absolute;
        left: 50%;
        top: 0;
        transform-origin: top center;
        border-radius: 8px;
        transform: translateX(-50%);
    }
    .loaderWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
}

.popupClass {
    overflow: hidden;
    pointer-events: none;
    * {
        overflow: hidden !important;
        pointer-events: none;
    }
}
