.toggleSwitchLayer {
    cursor: pointer;
    .ToggleSwitch {
        background: var(--toggleDefaultColor);
        height: 18px;
        width: 32px;
        border-radius: 37px;
        border: 1px solid var(--toggleDefaultColor);
        position: relative;
        &.active {
            background: var(--brandColor);
            border-color: var(--brandColor);
        }

        .knob {
            position: absolute;
            width: 12px;
            height: 12px;
            background: var(--shade_ffffff);
            box-shadow: 0px 1px 4px rgba(66, 71, 85, 0.25);
            border-radius: 50%;
            left: 4px;
            top: 50%;
            transform: translateY(-50%);
            transition: left 0.3s ease-out;
            &.active {
                left: 14px;
            }
        }
    }
    .toggleText {
        color: var(--shade_CAD1D4);
        margin-left: 10px;
        &.active {
            color: var(--dark_color);
        }
    }
    &.disabled {
        cursor: not-allowed;
        opacity: 0.5;

        .ToggleSwitch:not(.active) {
            background: var(--toggleDisabledColor);
            border-color: var(--toggleDisabledColor);
        }
    }
}

[data-theme="darkMode"] {
    .toggleSwitchLayer .ToggleSwitch:not(.active) {
        background: var(--darkMode_depthGrey);
        border-color: var(--darkMode_genericGrey);
        .knob {
            background: var(--darkMode_primaryGrey);
        }
    }
}
