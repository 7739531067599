.listWrap {
    max-height: 200px;
    overflow: auto;
    .listItem {
        padding: 0 20px;

        height: 40px;
        display: flex;
        align-items: center;
        gap: 10px;
        position: relative;
        .iconWrap {
            position: absolute;
            right: 30px;
            top: 50%;
            transform: translateY(-50%);
            display: none;
            cursor: pointer;
        }
        &:hover {
            background: var(--drpdownBG);
            .iconWrap {
                display: flex;
            }
        }
        &.softDeleted {
            color: var(--error);
        }
    }
    .labelGroup {
        font-size: var(--fontSizeSmall);
        color: var(--infoText);
        padding: 0 20px;
    }
    .keyValueWrapper {
        display: flex;
        gap: 3px;
        .keyValueItem:last-child {
            color: var(--grey_shade);
        }
    }
}
.softDeleted {
    .keyValueItem:last-child {
        color: var(--error) !important;
    }
}
.clearBtn {
    font-size: var(--fontSizeSmall);
    font-weight: 500;
    color: var(--grey_shade);
    cursor: pointer;
    &:hover {
        color: var(--errorHover);
    }
}
