.copyWrap {
    display: inline-flex;
    align-items: center;
    transition: 400ms all ease-in-out;

    > div {
        position: relative;
        > div:not(.iconWrap),
        > p,
        > span,
        > a {
            padding: 4px 5px;
            border-radius: var(--radius2) 0 0 var(--radius2);
            position: static;
            display: inline-flex;
            &::after {
                content: "";
                width: 10px;
                position: absolute;
                right: -10px;
                top: 0;
                bottom: 0;
                background: transparent;
            }
        }
    }

    &:hover {
        .iconWrap {
            display: inline-flex;
        }
    }

    .iconWrap {
        display: none;
        cursor: pointer;
        background: var(--shade_ffffff);
        border: 1px solid var(--borderColor4);
        box-shadow: var(--shadow1);
        backdrop-filter: blur(8px);
        padding: 4px;
        border-radius: var(--radius2);
        order: 1;
        z-index: 1;
        position: absolute;
        right: -25px;
        top: 50%;
        transform: translateY(-50%);

        &:not(.noHover):hover {
            background: var(--shade_74838F);
            svg {
                fill: var(--shade_ffffff) !important;
            }
        }

        &:not(.noHover):hover + div,
        &:not(.noHover):hover + p,
        &:not(.noHover):hover + a,
        &:not(.noHover):hover + span {
            background: var(--tabBgColor);
            display: inline-block;
        }

        // Will have to recheck this new changes as the current changes behave differently in both the tables
        &:hover + span > div {
            display: inline-block;
        }

        &.iconWrap_active {
            &:hover + div,
            &:hover + p,
            &:hover + a,
            &:hover + span {
                background: var(--copyIconBg);
                display: inline-block;
            }
            &:hover + span > div {
                display: inline-block;
            }
        }
    }
}

[data-theme="darkMode"] {
    .copyWrap {
        .iconWrap:hover {
            background: var(--darkMode_depthGrey);
            svg {
                fill: var(--darkMode_white) !important;
            }
        }
    }
}

:global {
    .table__deletedRow .copyWrap__icon {
        display: none !important;
    }
}
