.historyHeader {
    height: 70px;
    background: var(--brandColorOpacity1);
    font-weight: var(--fontMedium);
    color: var(--grey_shade);
    border-bottom: 1px var(--borderColor4) solid;
    width: calc(100% - 570px);
    position: absolute;
    top: -70px;
    .flowName {
        color: var(--dark_color);
    }
}

.wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--shade_ffffff);
    z-index: 99;
}
