.inputListWrapper {
    form {
        display: flex;
        align-items: flex-start;
        margin-bottom: 10px;
        // justify-content: center;

        button {
            margin-left: 20px;
        }
        .inputContainer {
            max-width: 640px;
            display: flex;
        }
        .inputOne {
            width: 100%;
            max-width: 245px;
            display: flex;
        }
        .inputSecond {
            position: relative;
            width: 100%;
            max-width: 245px;
            margin-left: 20px;
            display: flex;
        }
        .inputMulti {
            max-width: 315px;
        }
        .borderRed {
            border: 1px solid var(--error);
        }

        .inputWidth{
            width:365px;
        }
        .toggleWidth {
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            border-right: 0px;
            label {
                width: 70px;
            }
            span {
                width: 70px;
            }
        }
    }
    ul {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-wrap: wrap;
        max-height: 120px;
        overflow: auto;
        overflow-x: hidden;
        padding-right: 5px;
        gap: 10px;
        // margin-top: 10px;
        li {
            display: flex;
            gap: 5px;
            position: relative;
            background: transparent;
            border: 1px solid var(--borderColor3);
            // margin-right: 12px;
            // margin-top: 10px;
            padding: 5px 14px;
            border-radius: var(--radius3);
            font-weight: var(--fontMedium);
            font-size: var(--fontSize);
            color: var(--grey_shade);
            max-width: 598px;
            word-break: break-all;
            background: var(--shade_ffffff);

            span:nth-child(1) {
                color: var(--grey_shade);
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: 582px;
            }
            .crossIcon {
                visibility: hidden;
                right: -7px;
                top: calc(50% - 7px);
                position: absolute;
                height: 14px;
                width: 14px;
                background: var(--shade_ffffff);
                cursor: pointer;
                border-radius: 50%;
                overflow: hidden;
                color: var(--grey_shade);
                &:hover {
                    color: var(--error);
                }
            }
            .elementSecond {
                margin-left: 20px;
                word-break: break-word;
            }
            &:hover {
                background: var(--shade_f7f7f7);
                .crossIcon {
                    visibility: visible;
                }
            }
        }
    }

    &.tagWrappable {
        ul li span:nth-child(1) {
            white-space: unset;
        }
    }

    .divideLineWrapper {
        position: relative;
        .divideLine {
            width: 100%;
            height: 1px;
            background: var(--shade_EAF0F3);
            margin-top: 29px;
            margin-bottom: 29px;
        }
        .divideLineContent {
            position: absolute;
            top: -11px;
            left: 50%;
            transform: translateX(-50%);
            width: 40px;
            height: 23px;
            background: var(--shade_ffffff);
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .browseCsvWrapper {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        background: var(--shade_FAFCFC);
        border: 1px dashed var(--shade_E5EFEF);
        border-radius: var(--radius2);
        color: var(--shade_CAD1D4);
        margin-bottom: 10px;

        &.browseCsvWrapper_error {
            border-color: var(--error);
        }

        .uploadIconWrapper {
            display: flex;
            align-items: center;
            svg {
                margin-right: 5px;
            }
        }

        .fileName {
            position: relative;
            z-index: 1;
            font-size: var(--fontSize);
            font-weight: var(--fontRegular);
            color: var(--shade_CAD1D4);
            display: flex;
            width: calc(100% - 270px);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            .removeFileBtn {
                font-weight: var(--fontRegular);
                font-size: var(--fontSize);
                line-height: 18px;
                color: var(--error);
                margin-left: 5px;
                cursor: pointer;
            }
        }
    }

    .zipcodeUlTag {
        margin-left: -20px;
        margin-right: -20px;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 0;
        max-height: 120px;
        overflow: auto;
    }

    .sampleWrapper {
        margin: 20px 0 10px;
        padding-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid var(--borderColor4);
        svg {
            margin-right: 10px;
        }

        &.mainStateDataHide {
            margin-bottom: 0px;
            border-bottom: 0px;
            padding-bottom: 0px;
        }
    }
    .text {
        display: flex;
        align-items: center;
        font-weight: var(--fontRegular);
        font-size: var(--fontSize);
        line-height: 18px;
        color: var(--shade_A8AEB1);
    }
    button {
        display: flex;
        align-items: center;
        position: relative;
        z-index: 1;
    }

    .zipCodeErrorState {
        display: flex;
        align-items: center;
        color: var(--error);
        svg {
            color: var(--error);
            margin-right: 5px;
        }
    }
    .tableCount {
        color: var(--brandColor2);
        font-size: var(--fontSize);
        margin-top: 20px;
        &:hover {
            color: var(--brandHoverColor);
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .tagsUL {
        margin-top: 10px;
    }

    .singleDropdownCreateTagsWrapper {
        .utmSourcesWrapper {
            position: relative;
            .dropDownWrapper {
                position: absolute;
                left: 0px;
                top: -50px;
                width: 230px;
                .customOption {
                    width: 100%;
                    p:last-child {
                        color: var(--grey_shade);
                        margin-top: 5px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }
            }
        }
        .plusIconWrapper {
            cursor: pointer;
            border: 1px solid var(--borderColor4);
            border-radius: var(--radius2);
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
                background: var(--brandColor);
                svg {
                    color: var(--shade_ffffff) !important;
                }
            }
            &.disabled {
                color: var(--shade_CAD1D4);
                pointer-events: none;
                cursor: not-allowed;
                svg {
                    fill: var(--shade_CAD1D4);
                }
            }
        }
        ul li {
            margin: 0;
        }
    }

    .colorInput {
        border: none;
        position: absolute;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        width: 24px;
        height: 20px;
        top: 10px;
        right: 9px;
        cursor: pointer;
    }
}

.draggableItem {
    border: 1px solid var(--borderColor4);
    border-radius: var(--radius2);
    background: var(--panelBg);
    width: calc(50% - 15px);
    position: relative;
    .dragIcon {
        cursor: not-allowed;
        margin-right: 10px;
        background: var(--dragHandleBgColor);
        border-radius: var(--radius2) 0 0 var(--radius2);
        svg {
            fill: var(--grey_shade) !important;
        }
        // &:hover svg {
        //     fill: var(--dark_color) !important;
        // }
    }
    .circle {
        height: 14px;
        width: 14px;
        border-radius: 50%;
        border: 2px solid var(--grey_shade);
        margin-right: 5px;
        flex-shrink: 0;
    }
    .text {
        padding: 5px;
        color: var(--grey_shade);
        word-break: break-all;
    }
    .closeIcon {
        position: absolute;
        right: -7px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        visibility: hidden;
    }
    &:hover .closeIcon {
        visibility: visible;
        &:hover svg {
            fill: var(--error) !important;
        }
    }
}

.draggableItemCard {
    width: 640px;
    position: relative;
    word-break: break-all;
    &:hover {
        .edit-options {
            display: flex;
        }
    }
    .edit-options {
        display: none;
        position: absolute;
        top: 20px;
        right: 10px;
        .option {
            cursor: pointer;
            height: 16px;
            &:last-child {
                margin-right: 0px;
            }
            &.drag-handle {
                border-radius: 50%;
                background: var(--grey_shade);
                color: var(--shade_ffffff);
                &:hover {
                    background: var(--grey_shade);
                }
                span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }

    .newAnswer {
        width: 640px;
        background: var(--shade_ffffff);
        border: 1px solid var(--borderColor1);
        box-sizing: border-box;
        border-radius: 8px;
        position: relative;
        padding: 20px;
        .tag-list-dragable-wrap {
            margin-bottom: 10px;
        }

        .answerContainer {
            max-width: 511px;
            display: flex;
            align-items: flex-start;
            margin-bottom: 20px;
        }

        .key {
            color: var(--grey_shade);
            margin-right: 5px;
            max-width: 105px;
            font-weight: 500px;
        }
        .value {
            color: var(--dark_color);
            font-weight: 400;
        }
        .answerValue {
            color: var(--dark_color);
            font-weight: 400;
            max-width: 440px;
            max-height: 39px;
            overflow-y: auto;
        }

        .edit-options {
            visibility: hidden;
            gap: 20px;
            .option {
                cursor: pointer;
                &__drag {
                    cursor: drag;
                }
            }
            .dragWrapper {
                cursor: not-allowed;
                .dragDisabled {
                    pointer-events: none;
                    &__drag {
                        cursor: drag;
                    }
                }
            }
        }
        &:hover .edit-options {
            visibility: visible;
            margin-right: 8px;
        }
    }

    .conversionDetail {
        display: flex;
        .conversionWrapper {
            display: flex;
            gap: 5px;
        }
    }
    .additionalDetails {
        color: var(--dark_color);
    }
    .customEvent {
        margin-right: 114px;
        min-width: 165px;
    }
    .conversionHead {
        font-weight: 500;
        font-size: 14px;
        color: var(--grey_shade);
    }
    .conversionHeadValue {
        font-weight: 400;
        font-size: 14px;
        color: var(--grey_shade);
    }
}

// body > .draggableItem {
//     cursor: grab;
//     .dragIcon svg {
//         fill: var(--dark_color) !important;
//     }
// }

:global {
    .channelMapDropdown .channelMapDropdown {
        max-width: 265px !important;
        width: 100%;
    }
}

.inputWithoutToggle {
    display: flex;
    width: 100%;
}

.inputContainer {
    width: 640px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 20px;
    border: 1px solid var(--borderColor1);
    border-radius: var(--radius2);
    max-width: 640px;
    margin-bottom: 20px;

    .inputMultiContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 0px 20px 0px;
        width: 100%;
        .startTimeWrapper {
            margin-right: 10px;
        }
    }

    .btnWrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
}

.deletePopup {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
}

.keyValueHandler {
    margin: 20px auto 20px;
    width: calc(100% - 30px);
    .keyValueToggle {
        label {
            width: 70px;
        }
        span {
            width: 70px;
        }
    }
    form {
        flex-wrap: wrap;
        margin-bottom: 0px;
        gap: 20px;
        justify-content: space-between;

        form,
        .inputOne,
        .inputSecond {
            flex: 0 0 180px;
        }

        form,
        button {
            order: 1;
        }
    }
    .inputOne,
    .inputSecond {
        width: auto !important;
        min-width: 150px;
        margin: 0px !important;
    }
    button {
        margin-left: 0px !important;
        align-self: end;
    }
}
.singleInputToggle {
    margin: 20px auto 20px;
    form{margin-bottom: 0px;justify-content: center;}
    input{
        margin-left: -1px;
    }
    .inputWidth {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        input {
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
        }
    }
}
.boxWrapper {
    border-top: 1px solid var(--borderColor1);
    min-height: 200px;
    .boxItem:first-child {
        border-right: 1px solid var(--borderColor1);
    }
}

[data-theme="darkMode"] {
    .inputListWrapper ul li {
        div {
            span:nth-child(1) {
                color: var(--darkMode_white);
            }
        }
        span:nth-child(1) {
            color: var(--darkMode_white);
        }
        border: 1px solid var(--darkMode_genericGrey);
        background: var(--darkMode_PanelBg);

        .crossIcon {
            div {
                span:nth-child(1) {
                    color: var(--grey_shade);
                }
            }
        }

        &:hover {
            background: var(--darkMode_genericGrey);
        }
    }
}
