.input {
    display: inline-block;
}

.labelWrap {
    border: 1px solid grey;
    height: 30px;
    padding: 5px 10px;
    border-radius: 5px;
}

.confirmBox {
    background: var(--shade_ffffff);
    box-shadow: var(--shadow4);
    border: 1px var(--borderColor1) solid;
    border-radius: 0 0 var(--radius1) var(--radius1);
}

:global {
    .select__control {
        background: var(--headerBg) !important;
        border-color: var(--borderColor1) !important;
        border-radius: var(--radius1) var(--radius1) 0 0 !important;
        box-shadow: none !important;

        &:not(.select__control--menu-is-open) {
            cursor: pointer !important;
        }

        .select__placeholder {
            color: var(--listing-search-placeholder);
            padding: 0 10px;
        }
        .select__input-container {
            padding: 0 10px;
            color: var(--inputColor);
            cursor: text;
        }
        .select__indicators {
            display: none;
        }

        .select__value-container {
            position: unset;
        }

        .select__loader {
            position: absolute;
            top: auto;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }

    .select__containerWithLabel {
        .select__control {
            border: 1px solid var(--borderColor1) !important;
            border-radius: var(--radius2) !important;
        }
        .select__control--menu-is-open {
            border-radius: var(--radius1) var(--radius1) 0 0 !important;
        }
    }

    .select__menu {
        border-left: 1px var(--borderColor1) solid;
        border-right: 1px var(--borderColor1) solid;
        background-color: var(--shade_ffffff) !important;
        margin-top: -1px !important;
        border-radius: 0 0 10px 10px !important;

        .select__menu-notice--no-options {
            padding: 8px 20px;
            text-align: left;
        }

        .select__menu-list {
            padding: 0;
        }

        .select__option {
            padding: 0 20px;
            color: var(--dark_color);

            > div {
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                padding: 8px 0;
            }

            &.select__option--is-focused {
                background: var(--drpdownBG) !important;
                color: var(--dark_color);
            }

            &.select__option--is-selected {
                background-color: transparent;
                color: var(--dark_color);
            }

            .selectAll {
                color: var(--grey_shade);
                border-bottom: 1px solid var(--borderColor4);
            }

            .select__optionTooltipWrap {
                width: calc(100% - 25px);
            }

            span {
                max-width: calc(100% - 5px);
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }

            .option__checkbox {
                pointer-events: none;

                &--deselect .createPathLayer {
                    background: transparent;
                    border-color: var(--grey_shade);
                    > div {
                        color: var(--grey_shade);
                        svg {
                            width: 6px !important;
                        }
                    }
                }
            }
        }
    }

    [data-theme="darkMode"] {
        .select__menu {
            .select__option {
                background: var(--darkMode_PanelBg);

                &.select__option--is-focused {
                    background: var(--darkMode_genericGrey) !important;
                }

                span {
                    color: var(--darkMode_white);
                }
            }
        }
    }
}
