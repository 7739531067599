.inputBox {
    // min-width: 250px;
    position: relative;
    // max-width: var(--maxChildContainer);
    width: 100%;
    overflow: hidden;
    border-radius: var(--radius2);

    &.percentage,
    &.currency {
        &:before {
            display: inline-flex;
            content: "%";
            background: var(--shade_f7f7f7);
            position: absolute;
            right: 2px;
            top: 2px;
            height: calc(100% - 4px);
            border-radius: 0 var(--radius2) var(--radius2) 0;
            padding: 0 10px;
            z-index: 1;
            font-size: var(--fontSizeRegular);
            align-items: center;
        }
        .inputField {
            padding-right: 40px;
        }
    }

    &.currency {
        &:before {
            content: "$";
            left: 2px;
            right: auto;
            border-radius: var(--radius2Child) 0 0 var(--radius2Child);
            background: var(--shade_EAF0F3);
        }
        .inputField {
            padding-left: 40px;
            padding-right: 20px;
            font-weight: 500;
        }
    }

    .uploadFile {
        display: flex;
        position: absolute;
        top: 2px;
        right: 0;
        height: 40px;
        align-items: center;
        width: 30px;
        justify-content: center;
        cursor: pointer;
        svg {
            color: var(--shade_CAD1D4);
        }
        &:hover {
            svg {
                color: var(--dark_color);
            }
        }
    }

    .sortingArrowWrapper {
        opacity: 0;
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 2px;
        right: 7px;
        .sortingArrow {
            display: inline-block;
            cursor: pointer;
            svg {
                color: var(--shade_CAD1D4);
            }
            &.sortingArrowUp {
                transform: rotate(180deg);
            }
            &.sortingArrowDown {
                top: 50%;
            }
            &:hover {
                svg {
                    color: var(--dark_color);
                }
                // .sortingArrow{
                //   opacity: 1 !important;
                // }
            }
        }
    }

    .checkTextWrapper {
        position: absolute;
        right: 20px;
        z-index: 999;
        top: 14px;
        color: var(--grey_shade);
        font-weight: var(--fontRegular);
        font-size: var(--fontSizeSmall);
        span {
            margin-left: 5px;
            svg {
                animation: rotation 2s infinite linear;
            }
        }
    }

    .checkFilledImg {
        position: absolute;
        right: 10px;
        z-index: 999;
        top: 14px;
        svg {
            color: var(--brandColor);
        }
    }

    // input:hover ~ .sortingArrow{
    //     opacity: 1 !important;
    //   }
    .bigInputSize {
        top: 6px;
        right: 10px;
    }
}

@keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(359deg);
    }
}

.inputField {
    background-color: var(--inputBg);
    color: var(--inputColor);
    padding: 0 10px;
    border: 1px solid var(--borderColor1);
    position: relative;
    border-radius: var(--radius2);
    width: calc(100% - 2px);
    // min-height: 40px;
    line-height: 38px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 1px;
    &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: var(--inputPlaceholderColor);
        transform: translateY(1px);
    }
    &.numberPadding {
        padding-right: 25px !important;
    }
    &.numberPaddingValueType {
        padding-right: 40px !important;
    }
    &.loaderPadding {
        padding-right: 157px !important;
    }
    &:focus:not(.invalid) {
        border-color: var(--borderColor2);
        box-shadow: var(--inputShadow);
        background-color: var(--shade_ffffff);
    }
    &.invalid:not(:disabled) {
        border-color: var(--error-color);
        box-shadow: var(--inputShadowError);
        &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: var(--error);
        }
    }
    &:disabled {
        background-color: var(--shade_f7f7f7);
        cursor: not-allowed;
        color: var(--grey_shade);
    }
}

.inputBox:hover .sortingArrowWrapper {
    opacity: 1;
}

.textError {
    color: var(--error);
    margin-top: 5px;
    // font-weight: var(--fontRegular);
    font-weight: var(--fontMedium);
    font-size: 12px;
    line-height: 16px;
}

.textErrorStyling{
    display: flex;
    align-items: center;
    font-weight: 500;
    svg{
        color: var(--error);
        margin-right:5px;
    }
    margin-top:10px;
}

.textErrorLayer:focus,
.textErrorLayer {
    border: 1px solid var(--error) !important;

    // border: 0px !important;
    box-shadow: none;
    // box-shadow: 0px 0px 0px 1px var(--error) !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    padding-right: 28px;
    -moz-appearance: textfield;
}

.icon {
    left: 21px;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    width: 15px;
    height: 15px;
    display: flex;
    z-index: 1;
    color: var(--shade_CAD1D4);
    pointer-events: none;
}
.hasCustomIcon {
    padding-left: 48px;
}

.inputCount{
    position: absolute;
    top:-28px;
    right:0px;
    color:var(--grey_shade);
}

.inputBoxResetOverflow{
    overflow: inherit;
}

.visualSuffix {
    position: relative;
    max-width: 640px;

    .suffixText {
        position: absolute;
        right: 2px;
        top: 50%;
        transform: translateY(-50%);
        padding: 10px;
        background: var(--shade_EAF0F3);
        font-size: var(--fontSize);
        border-radius: 0 var(--radius2) var(--radius2) 0;
        min-width: 80px;
        text-align: center;
        color: var(--dark_color);
        font-style: italic;
        font-weight: 500;
    }

    .inputField {
        padding-right: 90px;
    }
}
.noSelect{
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

[data-theme="darkMode"] {
    .inputBox {
        &.percentage,
        &.currency {
            .inputField {
                border: 1px solid var(--darkMode_placeholder);
            }
        }
    }
    .inputField {
        &:not(.invalid):not(:disabled) {
            &:hover {
                border-color: var(--darkMode_placeholder);
            }
            &:focus {
                border-color: var(--brandColor);
            }
        }
    }

    .inputBox .sortingArrowWrapper .sortingArrow {
        svg {
            color: var(--darkMode_primaryGrey);
        }
        &:hover svg {
            color: var(--darkMode_white);
        }
    }
}
