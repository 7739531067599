.historyOverlay {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    z-index: 999;
    &:not(.isFlow) {
        background: var(--popupbg1);
    }
    &.isFlow {
        max-width: 570px;
        position: absolute;
        top: 0;
        min-height: calc(100vh - 50px);
    }
    .wrapper {
        position: absolute;
        right: 0;
        height: 100%;
        width: 100%;
        max-width: 640px;
        background: var(--panelBg);
        border-radius: 8px 0 0 8px;
        box-shadow: -4px 4px 10px rgba(0, 0, 0, 0.1);
        animation: history-in 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
        &.exit {
            animation: history-out 0.2s cubic-bezier(0.165, 0.84, 0.44, 1)
                forwards;
        }

        .heading {
            height: 64px;
            position: relative;
            border-bottom: 1px solid var(--borderColor1);
            .closeIcon {
                height: 16px;
                width: 16px;
                position: absolute;
                right: 20px;
                cursor: pointer;
                color: var(--grey_shade);
                top: 50%;
                transform: translateY(-50%);
                &:hover {
                    color: var(--shade_000000);
                }
            }
            .headText {
                color: var(--dark_color);
                font-weight: 500;
                font-size: 20px;
                padding-left: 20px;
            }
        }
        .innerWrapper {
            padding: 20px;
            height: calc(100% - 64px);
            overflow-y: auto;
            .topSection {
                margin-bottom: 20px;
                .datepickerWrap {
                    position: relative;
                    z-index: 3;
                    flex-shrink: 0;
                }
            }

            .dataWrapper {
                .update_wrap {
                    display: flex;
                    align-items: center;
                    ul {
                        display: flex;
                        margin-left: 15px;
                        flex-wrap: wrap;
                        gap: 5px;
                        li {
                            margin-right: 5px;
                            background-color: var(--drpdownBG);
                            padding: 5px;
                            border-radius: 8px;
                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
                .content_wrap {
                    display: flex;
                    margin: 15px 0 0;
                    .content_txt {
                        margin: 0 5px;
                        color: var(--shade_000000);
                        font-size: 14px;
                    }
                }
            }
        }
    }
    .restoreConfirmPopup {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.strike {
    text-decoration: line-through;
}

.oustideCard {
    color: var(--grey_shade);
    font-weight: var(--fontMedium);
    .showLess {
        border: 1px solid var(--borderColor4);
        border-radius: 4px;
        padding: 5px;
        cursor: pointer;
    }
}

.dateGroupWrap {
    position: relative;

    .dateWrap {
        border: 1px solid var(--borderColor4) !important;
        background: var(--shade_ffffff) !important;
        text-transform: capitalize;
        color: var(--grey_shade);
        font-weight: 500;
        &:after {
            font-size: 12px;
            color: var(--grey_shade) !important;
        }

        &.dateWrap__open {
            background: var(--drpdownBG) !important;
            color: var(--dark_color);
            &:after {
                color: var(--dark_color) !important;
            }
        }
    }

    .dateGroupInfo {
        position: absolute;
        right: 0;
        top: 0;
        margin: 10px 0;
        font-weight: var(--fontMedium);
        color: var(--grey_shade);
        span {
            color: var(--shade_000000);
        }
    }
}

.dateContent {
    padding: 20px;
    color: var(--grey_shade);
    border-radius: var(--radius2);
    background: var(--shade_ffffff);
    border: 1px solid var(--borderColor4);
    margin-bottom: 20px;
    position: relative;

    &:hover {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
        .viewDetails {
            text-decoration: underline;
        }
    }

    &.showDetails {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
        .header {
            padding: 20px;
            margin: -20px -20px 10px -20px;
            background: var(--shade_f7f7f7);
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
            border-radius: var(--radius2) var(--radius2) 0 0;
        }
    }

    .header {
        text-transform: capitalize;
    }

    .nameInitials {
        text-transform: uppercase;
        height: 24px;
        width: 24px;
        border-radius: 4px;
        background: #311c9d;
        color: #fff;
        font-size: 12px;
        &.nameInitials__bg1 {
            background: var(--bg1);
        }
        &.nameInitials__bg2 {
            background: var(--bg2);
        }
        &.nameInitials__bg3 {
            background: var(--bg3);
        }
        &.nameInitials__bg4 {
            background: var(--bg4);
        }
        &.nameInitials__bg5 {
            background: var(--bg5);
        }
        &.nameInitials__bg6 {
            background: var(--bg6);
        }
    }

    .changedKeyWrapper {
        span {
            padding: 5px;
        }
        .changedKeys {
            color: var(--dark_color);
            background: var(--drpdownBG);
            border-radius: var(--radius2);
        }
        .more {
            color: var(--brandColor);
        }
    }

    .viewDetails {
        cursor: pointer;
        color: var(--dark_color);
        width: max-content;
        &:hover {
            color: var(--brandColor);
        }
    }

    &.isFlow {
        cursor: pointer;
        &.activeFlow {
            background: var(--drpdownBG);
            &.showDetails {
                background: var(--shade_ffffff);
            }
        }
        .header {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 10px;

            div:last-child {
                margin-left: 35px;
            }
        }

        .flowActionWrap {
            position: absolute;
            right: 20px;
            top: 35px;
            width: 150px;
            height: 20px;
            .icon {
                position: absolute;
                right: 0;
                top: 50%;
                transform: rotate(90deg) translateX(-50%);
            }
            .currentTag {
                color: var(--brandColor);
                background: var(--addBg);
                border: 1px solid var(--borderColor1);
                border-radius: var(--radius2);
                font-style: italic;
                font-size: var(--fontSizeSmall);
            }
        }
    }
}

h1 {
    font-size: 16px !important;
    color: var(--dark_color);
    font-weight: var(--fontMedium);
}

.publishChange {
    margin-top: 0px !important; //added this as h1 tag is used
}

.difftextWrap {
    border-radius: var(--radius2);
    color: var(--grey_shade);
    padding: 10px;
    background: var(--updateBg);
    margin-left: 10px;
    word-break: break-all;
    &:not(:last-child) {
        margin-bottom: 10px;
    }
    i {
        align-self: flex-start;
        margin-bottom: 2px;
    }
    .entity {
        color: var(--dark_color);
    }
    &.Added {
        background: var(--addBg);
        .txt {
            color: var(--brandColor);
        }
    }
    &.Removed {
        background: var(--removeBg);
        .txt {
            color: var(--error);
        }
    }
    span {
        margin: 0 3px;
    }
    .txt {
        color: var(--updateText);
    }
}

.accordionWrap {
    border: none;
    background: var(--updateBg);
    margin-left: 10px !important;
    &.nestedAccordion {
        margin: 10px 0 0 0 !important;
        &:first-child {
            margin-top: 0 !important;
        }
    }
    &.Added {
        background: var(--addBg);
        .txt {
            color: var(--brandColor);
        }
    }
    &.Removed {
        background: var(--removeBg);
        .txt {
            color: var(--error);
        }
        .childComponentWrapper {
            padding: 0 10px 10px 10px;
            text-decoration: line-through;
        }
    }

    &:last-child {
        margin-bottom: 0;
    }
    .accordionItem {
        padding: 0 !important;
        &:hover {
            background: var(--shade_ffffff) !important;
        }
        &:after {
            color: var(--grey_shade) !important;
        }

        &.accordionItem__open {
            &:after {
                color: var(--dark_color) !important;
            }
        }
        .difftextWrap {
            margin-left: 0;
        }
    }
    .childComponentWrapper {
        padding: 0 10px 10px 10px;

        .heightToggleBtn {
            transition: var(--transition3);
            cursor: pointer;
            width: 20px;
            height: 20px;
            margin: -10px auto 0;
            border: 1px var(--borderColor4) solid;
            border-radius: 50%;
            background: var(--shade_ffffff);
            z-index: 5;
            position: relative;

            &.rotate {
                transform: rotate(180deg);
            }
        }
    }
}

.diffWrap {
    border-radius: var(--radius2);
    border: 1px solid var(--borderColor4);
    background: var(--panelBg);
    padding: 10px;
    word-break: break-all;
    position: relative;
    transition: var(--transition3);

    &:not(:first-child) {
        margin-top: 10px;
    }

    &__compact {
        max-height: 500px;
        overflow: hidden;

        &::after {
            content: "";
            height: 50px;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            background: linear-gradient(
                to bottom,
                rgba(255, 255, 255, 0),
                #fff
            );
        }
    }

    &__update {
        padding: 0;
        .oldData {
            border-right: 1px solid var(--borderColor4);
        }
    }

    .item {
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .key {
        flex-shrink: 0;
    }
    .value {
        color: var(--dark_color);
        word-break: break-all;
    }

    .separator:not(:last-child) {
        padding-bottom: 10px;
        border-bottom: 1px var(--borderColor1) solid;
        margin-bottom: 10px;
    }
}

.updatedContent {
    &:not(:first-child) {
        margin-top: 10px;
    }
    .oldData {
        text-decoration: line-through;
        * {
            color: var(--grey_shade);
        }
    }
}

.histMb10:not(:last-child) {
    margin-bottom: 10px;
}

@keyframes history-in {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes history-out {
    from {
        transform: none;
    }
    to {
        transform: translateX(100%);
    }
}

:global {
    .datePickerInHistory {
        .dateRangePickerLabel {
            height: 38px;
        }
        .rdrCalendarWrapper {
            padding: 0;
        }
    }
}

[data-theme="darkMode"] {
    .history_overlay .history_wrap {
        .accordio_wrap .left .accord p {
            color: var(--darkMode_white);
        }
    }
    .dateContent {
        &:hover,
        &.showDetails {
            box-shadow: var(--shadow3);
        }
    }

    .diffWrap {
        &__compact {
            &::after {
                background: linear-gradient(
                    to bottom,
                    rgba(255, 255, 255, 0),
                    #101010
                );
            }
        }
    }
}
