.select {
    position: relative;
    width: 100%;
    max-width: var(--maxChildContainer);
    border: 1px solid var(--borderColor1);
    border-radius: var(--radius2);

    &:not(.hideOutline):not(.disabled):focus-within {
        border: 1px solid var(--borderColor2);
        box-shadow: var(--inputShadow);
        border-radius: var(--radius2);
    }

    &.disabled {
        cursor: not-allowed;
        .input {
            pointer-events: none;
            // background-color: var(--tabBgColor);
            background-color: var(--shade_f7f7f7);
            label,
            .isSelectedText,
            .dropdownArrow {
                color: var(--shade_CAD1D4);
            }
        }
    }

    &.loading {
        overflow: hidden;
        pointer-events: none;
        .loader {
            position: absolute;
            top: auto;
            bottom: 0;
        }
    }

    &.hideOutline {
        border: 1px solid transparent;
    }

    &.hideLabel {
        border: 1px solid transparent;
        .input {
            visibility: hidden;
            min-height: 0px;
            max-height: 0px;
            padding: 0;
        }
    }

    .input {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: var(--inputBg);
        padding: 10px;
        border-radius: var(--radius2Child);
        width: 100%;
        min-height: 40px;
        .isSelectedText {
            color: var(--dark_color);
            margin-right: 5px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .isLabelText {
            color: var(--shade_CAD1D4);
        }
        cursor: pointer;
        .dropdownArrow {
            transition: var(--transition3);
            color: var(--grey_shade);
        }
    }

    .input.open {
        .dropdownArrow {
            transform: rotate(180deg);
            color: var(--dark_color);
        }
    }

    &.touched input:invalid + .input,
    &:not(:focus-within):not(.disabled) .input.error {
        border: 1px solid var(--error);
        box-shadow: var(--inputShadowError);
        label {
            color: var(--error);
        }
    }
}

.dropdown {
    cursor: pointer;
    width: 100%;
    position: absolute;
    background-color: var(--inputBg);
    border: 1px solid var(--borderColor1);
    box-sizing: border-box;
    box-shadow: var(--shadow2);
    border-radius: var(--radius2);
    z-index: 999;
    overflow: hidden;
    color: var(--dark_color);
    pointer-events: all;

    &.fixed {
        position: fixed;
    }

    .inputSearch {
        border: none;
        background-color: var(--shade_FAFCFC);
        &:focus {
            box-shadow: none !important;
            background-color: var(--shade_FAFCFC) !important;
        }
    }
    .singleOptionList {
        padding: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &.active:not(.disabledOption) {
            background-color: var(--drpdownBG);
        }
        &.disabledOption {
            cursor: not-allowed;
            div {
                color: var(--shade_CAD1D4);
                pointer-events: none;
            }
        }
    }
    .multiOptionWrapper {
        padding: 0 10px;
        &.active {
            background-color: var(--drpdownBG);
        }
        &:hover {
            background-color: var(--drpdownBG);
        }
        &.hasCustomRenderer {
            display: flex;
            .list {
                flex: 1;
            }
        }
        .nestedLink {
            margin-left: 8px;
            width: 20px;
            border-left: 1px solid var(--borderColor3);
            border-bottom: 1px solid var(--borderColor3);
            top: -18px;
            height: 37px;
            position: relative;
            &.firstChildLink {
                top: -10px;
                height: 30px;
            }
        }
        .multiOptionList {
            padding: 10px 0;
            color: var(--dark_color);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .createNewItem {
        word-break: break-word;
        padding: 10px;
        color: var(--brandColor);
        &:hover {
            background: var(--drpdownBG);
        }
    }
}

.openWithSearch {
    border: 1px solid var(--borderColor2);
    box-shadow: var(--inputShadow);
    border-radius: var(--radius2);
}

.choice {
    display: flex;
    align-items: center;
    &:last-child {
        margin-bottom: 0;
    }
    .choice-key,
    .choice-value {
        line-height: var(--lineHeight);
        font-weight: var(--fontMedium);
    }
    .choice-key {
        color: var(--grey_shade);
        border-right: 1px solid var(--borderColor6);
        padding-right: 10px;
        white-space: nowrap;
    }
    .choice-value {
        padding-left: 10px;
        color: var(--dark_color);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

[data-theme="darkMode"] {
    .select {
        .input {
            border: 1px solid var(--darkMode_placeholder);
            background-color: var(--darkMode_genericGrey);
        }

        &:not(.hideOutline):not(.disabled):focus-within {
            background-color: var(--darkMode_depthGrey);
            border-color: var(--brandColor);
            box-shadow: var(--inputShadow);
            .input {
                background-color: var(--darkMode_depthGrey);
            }
        }
        &.disabled .input {
            label,
            .isSelectedText,
            .dropdownArrow {
                color: var(--darkMode_placeholder);
            }
            background-color: var(--darkMode_genericGrey);
            border-color: var(--darkMode_genericGrey);
        }
    }

    .dropdown {
        background: var(--darkMode_PanelBg);
        box-shadow: var(--shadow3);
        .multiOptionWrapper:hover,
        .singleOptionList {
            &:not(.disabledOption):hover {
                background-color: var(--darkMode_genericGrey);
                color: var(--darkMode_white);
            }
            &.disabledOption div {
                color: var(--darkMode_disabled);
            }
        }
    }

    .openWithSearch {
        border: 1px solid var(--brandColor);
    }
}

.closeIcon{
    svg{
        color: var(--infoText);
        &:hover {
            color: var(--textColor2);
        }
    }
}