.cardCustom {
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 998; //to override panel tab
    background: var(--popupbg2) !important;
    border: 1px solid var(--borderColor7) !important;
    box-shadow: 0px 32px 32px -24px rgb(0 0 0 / 10%) !important;
    backdrop-filter: blur(8px);
    padding: 20px;
    width: 400px;
    word-break: break-all;
    word-wrap: break-word;
    transition: var(--transitionSpeed1) ease-out;

    .header {
        color: var(--dark_color);
        p {
            font-weight: var(--fontMedium);
            margin-bottom: 10px;
        }
        span {
            color: var(--infoText);
        }
    }

    .section {
        padding: 20px 0;
        border-bottom: 1px solid var(--borderColor3);
        color: var(--grey_shade);
        align-items: baseline;
        &:last-child {
            border: none;
        }
        &.shake {
            animation: shake 1.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
            transform: translate3d(0, 0, 0);
        }
        &.blink {
            animation: blink 1s ease;
        }
        .name {
            color: var(--brandColor);
            word-break: break-all;
            span:first-child {
                color: var(--dark_color);
                font-weight: var(--fontMedium);
            }
        }
        .path {
            background: var(--shade_F3F9F9);
            border-radius: var(--radius2);
            padding: 5px 0;

            &:empty {
                display: none;
            }

            span {
                position: relative;
                padding: 0 8px 0 5px;
                &::after {
                    content: "";
                    width: 3px;
                    height: 3px;
                    border-radius: 50%;
                    background: var(--shade_74838F);
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
                &:first-child {
                    flex-shrink: 0;
                }
                &:last-child {
                    padding-right: 5px;
                    &:after {
                        display: none;
                    }
                }
            }
        }
        .mappedData {
            &:empty {
                display: none;
            }
            span {
                text-transform: capitalize;
            }
        }
    }

    .footer {
        .collapseBtn {
            padding: 10px;
            height: 38px;
            &:hover {
                background: var(--shade_F3F9F9);
            }
        }
    }

    .miniWidgetOverlay {
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        cursor: pointer;

        .count {
            width: 30px;
            height: 30px;
            background: var(--brandColor);
            border-radius: 50%;
            position: absolute;
            top: 0;
            right: 0;
            transform: translate(50%, -50%);
            color: var(--shade_ffffff);
        }
    }

    &.miniWidget {
        width: auto;
        border-radius: 20px;
        padding: 15px;
        img {
            width: 30px;
            height: 30px;
        }
        .miniWidgetOverlay {
            display: block;
            [data-id="tooltip"],
            [data-id="tooltip"] span {
                height: 100%;
                width: 100%;
            }
        }
        .header,
        .clipboard,
        .footer {
            display: none;
        }
    }

    &.inactive {
        img {
            filter: grayscale(100%);
        }
        .count {
            background: var(--iconColor);
        }
    }

    &.closeWidget {
        transform: scale(0);
    }
}

@keyframes shake {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}

@keyframes blink {
    50% {
        filter: brightness(2);
        transform: scale(1.1);
    }
}

[data-theme="darkMode"] {
    .cardCustom {
        box-shadow: 0px 32px 32px -24px #000 !important;
        .header span {
            color: var(--darkMode_primaryGrey);
        }
        .section .path {
            background: var(--darkMode_genericGrey);
        }
    }
}
