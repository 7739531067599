.header {
    border-bottom: 1px solid var(--borderColor4);
    display: flex;
    justify-content: space-between;
    padding: 10px;
    font-weight: var(--fontMedium);

    &.headerInactive {
        color: var(--shade_CAD1D4);
    }

    span {
        color: var(--brandColor);
        // cursor: pointer;
    }
    li {
        flex: auto;
        &:nth-child(2),
        &:nth-child(3) {
            width: 100px;
            flex: none;
            display: inline-flex;
            justify-content: center;
        }
    }
}

.maxWidth {
    max-width: inherit;
    padding-left: 10px;
    // border: 1px solid var(--borderColor1);
    // border-radius: var(--radius2);
    .contentWrap {
        padding: 10px;
        .MuiTreeItem-content {
            padding: 0px;
            .MuiTreeItem-label {
                padding: 0px;
            }
        }
    }
}

.moduleRow {
    display: flex;
    cursor: pointer;
    font-weight: 400;

    .moduleCol {
        flex: auto;
        color: var(--dark_color);
        font-weight: 400;

        &:nth-child(2),
        &:nth-child(3) {
            width: 100px;
            flex: none;
            display: inline-flex;
            justify-content: center;
        }

        &.moduleColSelected {
            color: var(--dark_color);
            font-weight: var(--fontMedium);
        }
        &:hover {
            // background: var(--drpdownBG);
            color: var(--brandColor);
        }
    }
    .onlyHeader {
        color: var(--dark_color);
        font-weight: 500;
        &:hover {
            color: var(--dark_color);
            cursor: default;
        }
    }
}

.contentWrap {
    padding: 10px 20px;
    height: 305px;
    overflow: auto;

    .errorMsg {
        color: var(--grey_shade);
    }
}

.row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .toggleSwitchWrap {
        display: flex;
        min-width: 100px;
        > div {
            margin-right: 10px;
        }
    }
}

.popupWrap {
    border: 1px solid var(--borderColor4);
    border-radius: var(--radius1);
    background: var(--shade_ffffff);
    color: var(--grey_shade);
    line-height: 18px;
    max-width: 450px;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .topSection {
        padding: 20px;
        p {
            margin-bottom: 20px;
        }

        .form {
            display: flex;
            gap: 10px;

            button {
                flex-shrink: 0;
            }
        }
    }
    .divider {
        display: flex;
        justify-content: center;
        position: relative;
        margin: 0 20px;
        &::before {
            content: "";
            width: 100%;
            height: 1px;
            border-bottom: 1px dashed var(--borderColor7);
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
        span {
            font-size: 12px;
            padding: 0 14px;
            color: var(--infoText);
            background: var(--shade_ffffff);
            z-index: 1;
        }
    }
    .bottomSection {
        padding: 20px;
        p {
            margin-bottom: 10px;
        }
    }

    .closeSection {
        padding: 10px 20px;
        display: flex;
        justify-content: flex-end;
        border-top: 1px solid var(--borderColor4);
    }
}

:global {
    //for over riding library styling
    .contentWrap {
        padding: 10px;
        * {
            font-size: var(--fontSize);
        }
        .MuiTreeItem-content {
            padding: 0px;
            .MuiTreeItem-label {
                padding: 0px;
                z-index: 1;
                background: var(--shade_ffffff);
            }
        }

        .MuiTreeView-root {
            max-width: 100%;
            height: auto;
            margin-bottom: 10px;
            &:last-child {
                margin: 0;
            }
        }

        .MuiCollapse-root .MuiTreeItem-root {
            border-left: 1px dashed var(--borderColor7);
            position: relative;
            margin: 10px 0 10px 10px;
            &:before {
                content: "";
                height: 1px;
                width: 15px;
                border-bottom: 1px dashed var(--borderColor7);
                position: absolute;
                left: -20px;
                top: 18px;
            }
            &:last-child {
                border: none;

                &::before {
                    height: 40px;
                    top: -30px;
                    border-left: 1px dashed var(--borderColor7);
                }

                &:first-child:not(:last-child) {
                    &::before {
                        display: none;
                    }
                }
            }
        }

        .MuiTreeItem-content {
            background: none !important;
            cursor: default;
            &:not(.Mui-expanded) {
                padding-left: 20px;
            }
            .MuiTreeItem-iconContainer {
                display: none;
            }
        }
    }
}

[data-theme="darkMode"] {
    .popupWrap {
        background: var(--darkMode_PanelBg);
    }
    .divider {
        span {
            background: var(--darkMode_PanelBg);
        }
    }
    :global {
        .contentWrap {
            .MuiTreeItem-content {
                .MuiTreeItem-label {
                    background-color: var(--darkMode_PanelBg);
                }
            }
        }
    }
}
