textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--borderColor4);
    box-sizing: border-box;
    border-radius: var(--radius2);
    color: var(--dark_color);
    resize: none;
    background: var(--inputBg);
    &:focus {
        border: 1px solid var(--borderColor2);
        box-shadow: var(--inputShadow);
    }
    &:disabled {
        cursor: not-allowed;
    }
    &::-webkit-input-placeholder {
        color: var(--inputPlaceholderColor);
    }
}
textarea.fixedHeight {
    height: 260px;
}
textarea.error {
    border: 1px solid var(--error);
    box-shadow: var(--inputShadowError);
    &::-webkit-input-placeholder {
        color: var(--error);
    }
}

.errorMsg{
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    color: var(--error);
    margin-top:10px;
    svg{
        color: var(--error);
        margin-right:5px;
    }
}

.inputCount{
    position: absolute;
    top:-28px;
    right:0px;
    color:var(--grey_shade);
}

.textAreaWrapper{
    position: relative;
}

[data-theme="darkMode"] {
    textarea {
        &:not(.error):not(:disabled) {
            &:hover {
                border-color: var(--darkMode_placeholder);
            }
            &:focus {
                border-color: var(--brandColor);
            }
        }
    }
}
