.accord_child {
  animation: accord_anim 0.2s ease-in-out;
}

@keyframes accord_anim {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
