.mainParent {
    width: 100%;
    height: 100%;
    .tableLoaderWrapper {
        display: grid;
        grid-template-columns: 100px 300px 500px;
        grid-gap: 20px;
        padding: 20px;
        > div {
            display: grid;
            grid-template-columns: auto;
            grid-gap: 30px;
        }
    }
    .skeletonCard {
        padding: 20px;

        &.skeletonTitleCard {
            display: flex;
            flex-direction: column;
            div {
                width: 600px;
                &:nth-child(1) {
                    width: 200px;
                    margin-bottom: 5px;
                }
            }
        }

        &.skeletonCardTwo {
            display: flex;
            flex-direction: column;
            div {
                width: 600px;
                margin-bottom: 8px;
                &:nth-child(1) {
                    width: 200px;
                    margin-bottom: 5px;
                }
                &:nth-child(2) {
                    width: 200px;
                    margin-bottom: 5px;
                }
                &:nth-child(3) {
                    width: 200px;
                    margin-bottom: 5px;
                }
                &:nth-child(6) {
                    width: 200px;
                    margin-bottom: 5px;
                }
                &:nth-child(8) {
                    width: 200px;
                    margin-bottom: 5px;
                }
            }
        }

        &.skeletonCardThree {
            display: flex;
            flex-direction: column;
            div {
                margin-bottom: 8px;
                &:nth-child(1) {
                    width: 200px;
                    margin-bottom: 5px;
                }
            }
        }

        &.normalSkeletonCard {
            box-shadow: none;
            margin-bottom: 0;
            div {
                max-width: 600px;
                width: 100%;
                margin-bottom: 10px;
            }
        }

        &.gridListViewSkeletonCard {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(319px, 1fr));
            grid-gap: 20px;
            align-items: stretch;
            flex-wrap: wrap;
            div {
                max-width: 350px;
                width: 100%;
            }
        }
    }
    .rightPanelPaddingLeftZero {
        padding-left: 0px !important;
    }

    .gridListWrapper {
        width: 100%;
    }
}

[data-theme="darkMode"] {
    :global {
        span span.react-loading-skeleton {
            background-color: var(--darkMode_genericGrey);
            background-image: linear-gradient(
                90deg,
                var(--darkMode_genericGrey),
                var(--darkMode_placeholder),
                var(--darkMode_genericGrey)
            );
        }
    }
}

.graph__animate {
    animation: flowBackground 25s linear infinite;
}

.graphLoaderWrapper {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 100%;
    margin: auto;

    .loaderImg {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 60%;
        height: 60%;
        background-image: url("../../assets/img/graphLoader.svg");
        background-size: cover;
        background-position: 0 0;
        border: none;
        border-radius: initial;
        overflow: visible;
        transform: translate(-50%, -50%);
        animation: flowBackground 25s linear infinite;
    }

    .circle {
        border-radius: 50%;
        height: 100%;
        width: 100%;
        animation: rotate 2s linear infinite;
        border-top: 2px solid #0fc8b7;
        border-bottom: 2px solid #0fc8b7;
        position: relative;
    }
}

@keyframes flowBackground {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: -1017px 0px;
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(-360deg);
    }
}
