.wrapper {
    position: fixed;
    background: var(--popupbg2);
    border: 1px solid var(--borderColor8);
    box-shadow: 0px 32px 32px -24px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    font-weight: normal;
    z-index: var(--tooltipZindex);
    transform: translate(-100%, -50%); //default position is left
    overflow: hidden;
    &.top {
        transform: translate(-25%, -100%);
    }
    &.topLeft {
        transform: translate(-100%, -100%);
    }
    &.right {
        transform: translate(0, -50%);
    }
    &.bottom {
        transform: translate(-30%, 100%);
    }
}

.showComma {
    &::after {
        content: ",";
    }
}

.details {
    font-size: 12px;
    margin-left: 5px;
    color: var(--brandColor);
}

.externalHeader {
    color: var(--shade_A8AEB1);
    padding: 10px 30px;
    border-bottom: 1px solid var(--borderColor4);
    display: flex;
    gap: 20px;
    background: var(--popupHeaderBg);
    text-transform: capitalize;
    > div {
        padding: 0 5px;
    }
}

.externalBody {
    padding: 10px 30px;
    .row {
        display: flex;
        gap: 20px;
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
        .name {
            color: var(--grey_shade);
            white-space: nowrap;
        }
        .value {
            color: var(--dark_color);
        }
    }
}

.externalNumber {
    max-width: 90px;
    text-align: left;
    display: flex;
    justify-content: flex-start;
}

.externalNumber2 {
    width: 90px;
    text-align: right;
    display: flex;
    justify-content: flex-end;
}

.centreAlign {
    text-align: center !important;
}

@mixin flexGrid {
    display: flex;
    gap: 70px;
}

.externalHeader1 {
    color: var(--shade_CAD1D4);
    padding: 15px 25px;
    border-bottom: 1px solid var(--borderColor8);
    background: var(--popupHeaderBg);
    @include flexGrid;
    .externalChannel {
        max-width: 90px;
        text-align: left;
        text-transform: capitalize;
    }
}

.campaignIdCol {
    width: 120px;
    text-align: left;
    display: flex;
    justify-content: flex-start;
    padding: 0px !important;
}

.campaignNameCol {
    width: 250px;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    padding: 0px !important;
}

.externalBody1 {
    padding: 10px 20px;
    max-height: 276px;
    overflow-y: auto;
    .row {
        @include flexGrid;
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
        .name {
            color: var(--dark_color);
        }
        .value {
            color: var(--dark_color);
            max-width: 90px;
        }
    }
}

.externalHeader2 {
    color: var(--grey_shade);
    padding: 10px 25px;
    border-bottom: 1px solid var(--borderColor4);
    background: var(--popupHeaderBg);
}
.externalBody2 {
    padding: 10px 20px 20px;
    max-height: 200px;
    overflow-y: auto;
    color: var(--dark_color);
    .row:not(:last-child) {
        margin-bottom: 20px;
    }
}
