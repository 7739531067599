/* this gets exported as style.css and can be used for the default theming */
/* these are the necessary styles for React Flow, they get used by base.css and style.css */
.legendListWrap {
    border-width: 1px 0 0 0;
    border-color: var(--borderColor4);
    border-style: solid;
    padding: 15px 20px;
    display: flex;
    background: var(--panelBg);
    border-top: 1px solid var(--borderColor4);
    border-bottom-left-radius: 11px;
    border-bottom-right-radius: 11px;
    justify-content: space-between;
    .legendList {
        // background: #f7f7f7;
        border-radius: 4px;
        // padding: 10px 20px;
        font-size: var(--fontSizeSmall);
        display: inline-flex;
        li {
            font-size: 12px;
            font-weight: 500;
            margin-right: 20px;
            display: inline-flex;
            align-items: center;
            color: var(--grey_shade);
            &.container {
                &::before {
                    border: 1px solid var(--containerBgHover);
                    background-color: var(--containerBg);
                }
            }
            &.values {
                &::before {
                    background: #ffffff;
                    border: 1px solid var(--borderColor4);
                }
            }
            &.targeting {
                &::before {
                    background: var(--routerBg);
                    border: 1px solid var(--routerBorder);
                }
            }
            &.split {
                &::before {
                    background: var(--abBg);
                    border: 1px solid var(--abBorder);
                }
            }
            &.flow:before {
                background: var(--flowBg);
                border: 1px solid var(--flowBorder);
            }
            &.error {
                &::before {
                    position: relative;
                    top: 9px;
                    border-top: 1px dashed #f17d7d;
                    border-radius: 0px;
                }
            }
            &:before {
                content: "";
                width: 18px;
                height: 18px;
                border-radius: 4px;
                margin-right: 5px;
            }
            &.added:before {
                background: #a9efc5;
                border: 1px solid #a9efc5;
            }
            &.removed:before {
                background: #ffc2c2;
                border: 1px solid #ffc2c2;
            }
            &.edited:before {
                background: #ffe99c;
                border: 1px solid #ffe99c;
            }
        }
        .legendsLoaderWrapper {
            display: flex;
            gap: 20px;
            .legendsLoader {
                display: flex;
                align-items: center;
                gap: 7px;
            }
        }
    }
}

.cardWrapper {
    border: 1px solid var(--borderColor4);
    position: relative;
}

.historyHeader {
    height: 70px;
    background: var(--brandColorOpacity1);
    font-weight: var(--fontMedium);
    color: var(--grey_shade);
    border-bottom: 1px var(--borderColor4) solid;
    width: calc(100% - 640px);
    .flowName {
        color: var(--dark_color);
    }
}

.flowWrapper {
    // border-top: 1px solid var(--borderColor1);
    height: calc(100vh - 170px);
    width: 100%;
    display: flex;

    &.treeView {
        height: calc(100vh - 120px);
    }
}

.drawer {
    border-left: 1px solid var(--borderColor4);
    border-top: 1px solid var(--borderColor4);
    min-width: 500px;
    max-width: 500px;
    z-index: 100;
    background: #fff;
    margin-top: -70px;

    &.isTreeView {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 49px;
    }
}

.confirmExpandAllPopup {
    position: absolute;
    left: 50%;
    top: 0;
    width: 400px;
    transform: translateX(-80%);
}

@mixin beforeDash {
    //used for tp and ab split
    content: "";
    width: 10px;
    height: 1px;
    background: var(--borderColor4);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -10px;
}

@mixin afterLine {
    //used for tp and ab split
    content: "";
    width: 1px;
    height: calc(100% + 20px);
    background: var(--borderColor4);
    position: absolute;
    top: 50%;
    left: -10px;
}

.targetingFormWrapper {
    input[type="file"] {
        display: none;
    }
    &.inline {
        .branchList {
            &:before {
                display: none;
            }
            .branch {
                :before {
                    display: none;
                }
            }
        }
    }
    .branchList {
        position: relative;
        &::before {
            @include afterLine;
            top: 22px;
            height: calc(100% + 7px);
        }
        .branch {
            border: 1px solid var(--borderColor1);
            border-radius: var(--radius2);
            margin-bottom: 20px;
            position: relative;
            max-width: var(--maxChildContainer);
            &::before {
                @include beforeDash;
                transform: none;
                top: 22px;
            }
            .branchHeader {
                padding: 10px;
                border-bottom: 1px solid var(--borderColor1);
                justify-content: space-between;
                display: flex;
                align-items: center;
                color: var(--grey_shade);
                min-height: 42px;

                span:nth-child(2) {
                    visibility: hidden;
                    cursor: pointer;
                }

                + div {
                    padding: 10px;
                }
            }
            &:first-child .branchHeader {
                border: none; //default branch
            }
            &:hover .branchHeader span:nth-child(2) {
                visibility: visible;
            }
        }
        &__noAddBtn {
            &::before {
                display: none;
            }
        }

        &.readOnlyView {
            .branch {
                border: none;
                margin-bottom: 10px;
            }
        }
    }
    .addBranchBtn {
        position: relative;
        &::before {
            @include beforeDash;
        }

        &.addBranchBtnDisale {
            cursor: not-allowed;
            opacity: 0.5;
            pointer-events: none;
        }
    }
}

.searchWrapper {
    width: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: var(--shade_ffffff);
    z-index: 99;
    :global {
        .offlineInputSearch {
            max-width: 100% !important;
        }
    }
    &__compact {
        width: calc(100% - 500px);
        transform: none;
        left: 0;
    }
    &__withDate {
        display: flex;
        > div:first-child {
            width: 100%;
            transition: var(--transitionSpeed1) all ease;
        }
        .statsDateOnFlow {
            > div > div > div:first-child {
                border-radius: 0;
                animation: blinkBg 2s;
            }
        }
    }
}

@keyframes blinkBg {
    50% {
        background: var(--dateHighlight);
    }
}

.readonlyId {
    color: var(--inputColor);
}

.splitDetails {
    position: relative;
    div {
        color: var(--infoText);
    }
}

.addSplitBtn {
    color: var(--brandColor);
    position: relative;
    cursor: pointer;
    &::before {
        @include beforeDash;
    }

    .confirmPopup {
        position: absolute;
        bottom: 0px;
        left: 0;
    }
}

.splitWrap {
    border: 1px solid var(--borderColor4);
    border-radius: var(--radius2);
    position: relative;
    margin-bottom: 20px;

    &::before {
        @include beforeDash;
    }

    &::after {
        @include afterLine;
    }

    &:last-child:after {
        height: calc(50% + 30px);
    }

    .title {
        background: var(--shade_f7f7f7);
        border-radius: var(--radius2) var(--radius2) 0 0;
        border-bottom: 1px solid var(--borderColor4);
        padding: 10px;
        span {
            flex-shrink: 0;
        }
        .splitNameWrap {
            width: calc(100% - 20px);
        }
    }

    .title,
    p {
        color: var(--grey_shade);
        font-weight: var(--fontMedium);
    }

    .deletePopup {
        position: absolute;
        right: 0;
        top: -50px;
    }
}

.disableLink {
    cursor: not-allowed !important;
    color: var(--shade_CAD1D4) !important;
    &:hover {
        color: var(--shade_CAD1D4) !important;
    }
}

.statsDatePopup {
    top: 90px !important;
    overflow: visible !important;
    transform: translate(-50%, 0) !important;
}

.statsDateRange {
    position: relative !important;
    top: 0 !important;
}

.pullChangesBtn {
    position: absolute;
}

.headerButton {
    width: 160px !important;
    height: 40px !important;
}

.buttonDropdownWrapper {
    margin-left: 10px;
    width: 100%;
    .multiValue {
        margin-top: 0px;
        font-size: 14px;
        color: var(--textColor2);
        display: flex;
        align-items: center;
    }
    .userStatus {
        font-weight: 500;
        font-size: 14px;
        color: var(--dark_color);
    }
}

.downArrow {
    margin-left: 8px;
    margin-top: 2px;
}

.buttonSelect {
    position: absolute;
    top: 10px;
    right: 20px;
    width: 220px;
    > div {
        border: none !important;
        background: none !important;
        box-shadow: none !important;
        input + div {
            visibility: hidden;
            pointer-events: none;
        }
    }
}

@media (max-height: 720px) {
    .statsDatePopup {
        max-height: 480px;
        overflow: auto !important;
    }
}

[data-theme="darkMode"] {
    .legendListWrap {
        .legendList li {
            &.values {
                &::before {
                    background: var(--darkMode_genericGrey);
                }
            }

            &.added:before {
                background: #347d39;
                border: 1px solid #347d39;
            }
            &.removed:before {
                background: #922323;
                border: 1px solid #922323;
            }
            &.edited:before {
                background: #966600;
                border: 1px solid #966600;
            }
        }
    }

    .drawer {
        background: none;
    }

    .cardWrapper {
        box-shadow: var(--shadow1);
        border: 1px solid transparent;
    }
}
