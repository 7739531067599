.input_wrap {
    position: relative;
    width: 100%;
    .customSearchInput {
        height: 48px;
        padding-right: 35px;
        background: var(--shade_FAFCFC);
        &::placeholder,
        &:-moz-placeholder {
            color: var(--listing-search-placeholder);
        }
    }
    .inputIcon {
        left: 20px;
    }
    .clearIcon {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }
}
