.adUnitData {
    padding: 20px;
    border: 1px solid var(--borderColor4);
    background: var(--shade_ffffff);
    border-radius: var(--radius2);
    color: var(--grey_shade);
    position: relative;
    &:not(:last-child) {
        margin-bottom: 20px;
    }
    p span {
        color: var(--dark_color);
        font-weight: 500;
    }
    .iconWrap {
        position: absolute;
        right: 20px;
        top: 20px;
        visibility: hidden;
    }
    &:hover .iconWrap {
        visibility: visible;
    }
    .inputWrapper {
        display: flex;
        flex-direction: column;
        .inputLabel {
            font-size: var(--fontSize);
            line-height: var(--lineHeight);
            letter-spacing: -0.022em;
            color: var(--grey_shade);
            margin-bottom: 10px;
        }
    }
    .maxWidth {
        max-width: 640px;
    }

    .plusDropdownWrapper {
        height: 30px;
        position: absolute;
        left: 0;
        max-width: 332px;
        width: 100%;
        top: 0;
        z-index: 1;
        .select {
            min-height: 30px !important;
            height: 30px;
        }
    }

    .targetingList {
        list-style: none;
    }
}

:global {
    .multiSelectWithAny {
        max-width: 546px;
    }
}
