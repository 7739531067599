.commonStyle {
    font-size: var(--fontSize);
    border-radius: var(--radius2);
    border: 1px solid var(--borderColor1);
    transition: var(--transition);
    color: var(--grey_shade);
    background: var(--btnBg);
    padding: 0 20px;
    box-sizing: border-box;
    font-weight: var(--fontMedium);
    // line-height: var(--lineHeight);
    line-height: normal;
    display: inline-flex;
    min-height: 40px;
    align-items: center;
    white-space: nowrap;
    &:disabled {
        opacity: 0.6;
        pointer-events: none;
    }
    &:hover {
        // border-color: var(--brandHoverColor);
        background: var(--grey_shade);
        color: var(--shade_ffffff);
    }
}

button.basic {
    padding: 10px 20px;
}
button.largeBtn {
    padding: 14px 20px;
}
button.extraLargeBtn {
    height: 60px;
    width: 300px;
}

button.compact {
    padding: 0 10px;
}

button.btnIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--radius2);
    padding: 0px;
    &.basic {
        width: 28px;
        height: 28px;
        min-height: inherit;
    }

    &.largeBtn {
        width: 48px;
        height: 48px;
    }
}

.themBtn {
    color: var(--grey_shade);
    background: var(--btnBg);
    svg {
        color: var(--grey_shade);
    }
    &:hover svg {
        color: var(--shade_ffffff);
    }
}

.themBtnBg,
.themeDefaultBtn:hover {
    background: var(--brandColor);
    color: var(--btnColor);
    border-color: transparent;
}
.themBtnBg:hover {
    background: var(--brandHoverColor);
}
.themeDefaultBtn {
    color: var(--brandColor);
    border-color: var(--brandColor);
}

.themeSkeletonBtn {
    border-color: transparent;
    background: transparent;
    color: var(--grey_shade);
    position: relative;
    overflow: hidden;
    &:hover {
        background: transparent;
        color: var(--brandColor);
        &:before {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background-color: var(--brandColor);
            opacity: 0.1;
        }
    }
}

.themeDeleteBtn {
    color: var(--error);
    border-color: var(--error);
    &:hover {
        background-color: var(--error);
    }
}

.skeletonBtn button:disabled {
    background: var(--badgeBg);
    color: #cad1d5;
    border-color: var(--borderColor1);
}

[data-theme="darkMode"] {
    .themeDefaultBtn {
        color: var(--brandColor);
        background: var(--darkMode_genericGrey);
        &:hover {
            box-shadow: 0px 0px 20px rgba(65, 195, 169, 0.5);
            border-color: var(--brandColor);
        }
    }
    .themBtnBg {
        border-color: var(--darkMode_primaryGrey);
        &:hover {
            box-shadow: 0px 0px 20px rgba(65, 195, 169, 0.5);
        }
        &:disabled {
            color: var(--darkMode_disabled);
        }
    }

    .themBtn {
        color: var(--darkMode_primaryGrey);
        &:hover {
            color: var(--darkMode_white);
            background: var(--darkMode_placeholder);
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
            svg {
                color: var(--darkMode_white);
            }
        }
        &:disabled {
            background: var(--darkMode_PanelBg);
        }
    }
}
