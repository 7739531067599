.nodeWrapper {
    display: flex;
    align-items: center;
    position: relative;
    border: 1px solid var(--borderColor1);
    padding: 10px;
    margin: 0 auto;
    border-radius: var(--radius2);
    background: var(--shade_ffffff);
    color: var(--dark_color);
    box-sizing: border-box;
    transform-style: preserve-3d;

    &.isAdded::before,
    &.isDeleted::before,
    &.isUpdated::before {
        content: "";
        width: calc(100% + 20px);
        position: absolute;
        left: -10px;
        background: #a9efc5;
        height: calc(100% + 20px);
        border-radius: var(--radius1);
        transform: translateZ(-10px);
    }
    &.isDeleted::before {
        background: #ffc2c2;
    }
    &.isUpdated::before {
        background: #ffe99c;
    }

    &.container {
        width: 250px;
    }

    .targetHandle {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        transform: none;
        z-index: 2; //to overlap over node contents
    }
    .expandCollapse {
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%, -50%);
        border-radius: 4px;
        width: 16px;
        height: 16px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: 1;
        border: 1px solid var(--toggleDefaultColor) !important;
        color: var(--toggleDefaultColor) !important;
        background: #fff !important;
        &:hover {
            background: var(--toggleDefaultColor) !important;
            color: #fff !important;
        }
        .rotate {
            transform: rotate(180deg);
        }
    }
    &.collapsed {
        border: 1px dashed var(--containerBorder);
        display: inline-flex;
        background: var(--addBg);
    }
    &.highlight {
        border: 1px solid var(--containerBorder);
        background: var(--containerBg);
    }

    &.blur {
        opacity: 0.2;
    }
    &.default {
        background: #ffffff;
    }
    &.preview {
        padding: 0;
        &:hover {
            background: var(--shade_f7f7f7);
        }
        .previewContent {
            display: flex;
            text-align: left;
            width: 100%;
            .rank {
                background: var(--borderColor1);
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width: 36px;
                color: var(--textColor2);
                border-radius: 7px 0 0 7px;
                flex-shrink: 0;
                font-size: 14px;
                color: #000;
            }
            ul {
                padding: 10px 20px;
                width: calc(100% - 36px);
                word-wrap: break-word;
                li {
                    min-height: 20px;
                }
            }
        }
        .nodeContainer {
            padding: 10px;
            display: flex;
            width: 100%;
        }
    }

    &.destination:not(.isDeleted) {
        border: 1px solid #98ddc8;
        border-left: none;
        border-right: none;
        border-radius: 0;
        background: #f3fdff;
        position: relative;
        &:hover {
            box-shadow: none;
            background: var(--containerBgHover);
            &:before,
            &:after {
                background: var(--containerBgHover);
            }
        }
        &.activeNode {
            box-shadow: none;
            background: var(--containerBorder);
            &:before,
            &:after {
                background: var(--containerBorder);
            }
        }
        &.singleLine {
            &:before {
                left: -10px;
            }
            &::after {
                right: -10px;
            }
        }
        &:before {
            content: "";
            position: absolute;
            top: 2px;
            left: -15px;
            height: 72%;
            aspect-ratio: 1;
            border: 1px solid #98ddc8;
            transform-origin: bottom;
            transform: translateX(-50%) rotate(45deg);
            background: #f3fdff;
            z-index: -1;
            border-right: none;
            border-top: none;
            border-radius: 0;
        }
        &:after {
            content: "";
            position: absolute;
            top: 2px;
            right: -15px;
            height: 72%;
            aspect-ratio: 1;
            border: 1px solid #98ddc8;
            transform-origin: bottom;
            transform: translateX(50%) rotate(-45deg);
            background: #f3fdff;
            z-index: -1;
            border-left: none;
            border-top: none;
        }
    }

    &:hover {
        background: var(--containerBgHover);
        box-shadow: var(--nodeShadow);
        .nodeActions {
            display: flex;
        }
        .expandCollapse {
            display: flex;
        }
    }
    &.activeNode {
        box-shadow: var(--nodeShadow);
        background: var(--containerBorder);

        .header,
        .nodeActions svg,
        .tpContent,
        .tpContent svg,
        &:not(.targeting) .value,
        .diversifyLabel,
        .diversifyLabel svg {
            color: var(--shade_ffffff) !important;
        }
        .diversifyLabel {
            border-color: var(--shade_ffffff) !important;
        }
        .content {
            .stats {
                .impression,
                .forward,
                .drop {
                    background: var(--statsActiveBg) !important;
                    color: var(--tabBgColor) !important;
                    svg {
                        color: inherit;
                    }
                }
            }

            .header {
                .rulesCountWrapper {
                    background-color: #fff;
                    color: var(--updateText);
                }
            }
        }
    }
    &.abRouter,
    &.router {
        padding: 0 20px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 36px;
        background: var(--routerBg);
        border: 1px solid var(--routerBorder);
        transform: skew(-20deg);
        border-radius: 3px;
        &:hover {
            background: var(--routerBgHover);
        }
        &.activeNode {
            background: var(--routerBorder);
        }
        .iconWrapper {
            width: 24px;
            height: 24px;
            background: #eaf0f3;
            color: #fff;
            display: inline-flex;
            justify-content: center;
            align-items: center;
        }
        &.abRouter {
            height: 40px;
            width: 40px;
            background: #ffffff;
            border: 1px solid var(--borderColor4);
            border-radius: 4px;
            transform: rotate(45deg);
            margin-top: 7px;
            color: var(--textColor2);
            .icon {
                transform: rotate(-45deg);
            }
            .expandCollapse {
                transform: rotate(-45deg);
                top: -7px;
                left: -5px;
            }
            .nodeActions {
                top: -9px;
                right: -9px;
                transform: rotate(-45deg);
                width: 17px;
                height: 17px;
                border-radius: 50%;
                border: 1px solid var(--textColor2);
                justify-content: center;
                background: #fff;
                align-items: center;

                svg {
                    width: 7px !important;
                    height: 7px !important;
                }
            }

            &:hover {
                color: #fff;
                background: var(--textColor2);
            }
            &.activeNode {
                color: #fff;
                background: var(--abBorder);
                .nodeActions {
                    svg {
                        color: var(--grey_shade) !important;
                    }
                }
            }
        }
        &.router {
            .iconWrapper {
                border-radius: 8px;
                transform: rotate(45deg);
            }
        }

        &.cr {
            background: #ecf8de;
            border-color: #b2d18b;
        }
        .expandCollapse {
            transform: skew(20deg) translateX(-14px);
            top: -9px;
        }
        .tpContent {
            transform: skew(20deg);
            display: inline-flex;
            align-items: center;
            white-space: nowrap;

            &__previewContainer {
                display: flex;
                flex-direction: column;
                svg {
                    width: 14px !important;
                    height: 14px !important;
                }
                .text {
                    font-size: 10px;
                }
            }
        }
        .nodeActions {
            top: 0px;
            right: -15px;
        }
        &.preview:hover {
            background: var(--shade_f7f7f7);
        }
    }
    &.routerIncreasedHeight {
        height: 50px;
    }
    &.start {
        padding: 0 20px;
        flex-direction: column;
        height: 40px;
        background: var(--brandColor);
        border: 1px solid var(--borderColor4);
        justify-content: center;
        font-family: var(--fontMedium);
        border-radius: 20px;
        display: inline-flex;
        color: var(--shade_ffffff);

        &.end {
            margin-left: 10px;
        }

        .nodeActions {
            position: relative;
            top: auto;
            left: auto;
            right: auto;
            bottom: auto;
            transform: none;
        }
        .psuedoContainer {
            border: 1px dashed #98ddc8;
            border-right: none;
            border-left: none;
            padding: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--textColor2);
            position: absolute;
            width: 120px;
            background: #e5fff9;
            font-size: 12px;
            &.pullLeft {
                left: -200px;
            }
            .line {
                border-top: 1px dashed #98ddc8;
                position: absolute;
                top: 50%;
                left: 100%;
                width: 100px;
                z-index: -2;
            }
            &:before {
                content: "";
                position: absolute;
                top: 2px;
                left: -10px;
                height: 72%;
                aspect-ratio: 1;
                border: 1px dashed #98ddc8;
                transform-origin: bottom;
                transform: translateX(-50%) rotate(45deg);
                background: #e5fff9;
                z-index: -1;
            }
            &:after {
                content: "";
                position: absolute;
                top: 2px;
                right: -10px;
                height: 72%;
                aspect-ratio: 1;
                border: 1px dashed #98ddc8;
                transform-origin: bottom;
                transform: translateX(50%) rotate(-45deg);
                background: #e5fff9;
                z-index: -1;
            }
            .nodeActions {
                position: absolute;
                right: 0;
                top: 12px;
            }
        }
    }

    .nodeActions {
        position: absolute;
        color: var(--textColor2);
        right: 10px;
        top: 10px;
        display: none;
        z-index: 2;
        &:hover {
            color: var(--textColor1);
        }
        .icon {
            cursor: pointer;
        }
        .dropdown {
            position: absolute;
            width: 100px;
            .select {
                min-width: 100%;
                pointer-events: all;
            }
        }

        .deleteIconWrapper {
            &:hover {
                svg {
                    color: var(--errorHover);
                }
            }
        }
    }

    .nodeAddAction {
        position: absolute;
        color: var(--textColor2);
        bottom: -50px;
        left: 50%;
        transform: translateX(-50%);
        pointer-events: all;
        cursor: pointer;
        background: var(--shade_ffffff);
        border: 1px solid var(--borderColor4);
        border-radius: var(--radius5);
        display: flex;
        align-items: center;
        &:before {
            content: "";
            height: 27px;
            position: absolute;
            top: -28px;
            left: 50%;
            border-left: 1px solid #cad1d5;
            transform: translateX(-50%);
        }
        .icon {
            cursor: pointer;
            &.icon__disabled {
                pointer-events: none;
            }
        }

        span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 20px;
            border-right: 1px solid var(--borderColor4);
            &:last-child {
                border-right: 0px;
            }
            .icon {
                cursor: pointer;
                &.icon__disabled {
                    pointer-events: none;
                }
            }

            &:empty {
                width: 0px;
                height: 0px;
            }
            &:hover {
                background-color: var(--grey_shade);

                svg {
                    color: var(--shade_ffffff);
                }
                &:first-child {
                    border-radius: var(--radius5) 0px 0px var(--radius5);
                }
                &:last-child {
                    border-radius: 0px var(--radius5) var(--radius5) 0px;
                }
                &:only-child {
                    border-radius: var(--radius5);
                }
            }
        }
    }
    .nodeAddDisable {
        .icon {
            cursor: not-allowed;
        }
    }
    .cursorDisable {
        cursor: not-allowed;
    }
    &.ab {
        width: 250px;
        background: var(--abBg);
        border: 1px solid var(--abBorder);
        border-radius: 3px;
        transform: skew(-15deg);
        padding: 10px 20px;
        &:hover {
            background: var(--abBgHover);
        }
        &.activeNode {
            background: var(--abBorder);
        }
        .content {
            transform: skew(15deg);
            .value {
                min-width: unset;
                margin-right: 5px;
            }
        }
        .nodeAddAction {
            transform: skew(15deg) translateX(50%);
        }
        .nodeActions {
            transform: skew(15deg) translateX(-50%);
            right: 5px;
        }
        .expandCollapse {
            transform: skew(15deg) translateX(-50%);
        }
    }
    &.targeting {
        height: 26px;
        padding: 5px 10px;
        border: 1px solid var(--borderColor4);
        display: inline-flex;
        &:hover {
            background: var(--shade_f7f7f7);
        }
        &.activeNode {
            border-color: var(--routerBorder);
            background: var(--shade_ffffff);
            color: var(--textColor2);
            .nodeActions svg {
                color: var(--textColor2) !important;
            }
        }
        .nodeActions {
            top: 5px;
            right: 5px;
        }
        .value {
            text-align: center;
            &.noEllipsis {
                text-overflow: unset !important;
                margin-right: 7px;
                > span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    > span {
                        &:first-child {
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                        &:last-child {
                            flex-shrink: 0;
                        }
                    }
                }
            }
        }
    }
    .content {
        text-align: left;
        max-width: calc(100% - 12px);
        white-space: nowrap;
        .header {
            color: var(--textColor2);
            display: inline-flex;
            align-items: center;
            width: 100%;
            margin-bottom: 3px;
            p {
                text-overflow: ellipsis;
                overflow: hidden;
                text-transform: capitalize;
            }

            .rulesCountWrapper {
                width: 33px;
                height: 23px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 13px;
                background-color: var(--lightBlueShade);
                border-radius: 8px;
                color: var(--textColor2);
                &:hover {
                    background-color: var(--lightBlueShade);
                    color: var(--dark_color);
                }
            }
        }
        .value {
            cursor: pointer;
            max-width: 250px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            min-width: 100px;
            margin-right: 10px;
            min-height: 10px;

            .loader {
                position: absolute;
                top: auto;
                bottom: 0;
            }
        }
        .diversifyLabel {
            border-left: 1px var(--borderColor7) solid;
            padding: 0px 5px;
            color: var(--grey_shade);
        }
        .stats {
            margin-top: 10px;
            div * {
                display: flex;
                text-align: left;
                justify-content: flex-start;
            }
            .impression {
                background: var(--impressionBg);
                border-radius: var(--radius2);
                color: var(--updateText);
                svg {
                    color: inherit;
                }
                &.noImpression {
                    flex-direction: row;
                    background: var(--tabBgColor);
                    color: var(--grey_shade);
                }
            }
            .forward {
                background: var(--forwardBg);
                border-radius: var(--radius2);
                color: var(--brandColor);
            }
            .drop {
                background: var(--dropRateBg);
                border-radius: var(--radius2);
                color: var(--error);
            }
        }
        .statsVisual {
            width: 30px;
            height: 100%;
            position: absolute;
            right: -20px;
            top: 0;
            z-index: -1;
            display: flex;
            justify-content: center;

            &__impression {
                width: 100%;
                background: linear-gradient(
                    90deg,
                    #41c3a9 2.9%,
                    rgba(65, 195, 169, 0) 100%
                );
            }

            &__drop {
                width: 18px;
                position: absolute;
                right: 4px;
                bottom: 0;
                z-index: -1;
                background: var(--error);
                background: linear-gradient(
                    180deg,
                    #e76e6e 50%,
                    rgb(255 173 173 / 50%) 100%
                );
                border-radius: 0 8px 0 0;
                &:before {
                    content: "";
                    height: 20px;
                    width: 18px;
                    bottom: -20px;
                    right: 0;
                    position: absolute;
                    background: linear-gradient(
                        180deg,
                        rgb(255 173 173 / 50%) 10%,
                        rgb(247 233 233 / 0%) 100%
                    );
                }
                &:after {
                    content: "";
                    bottom: -4px;
                    right: 8px;
                    position: absolute;
                    height: 11px;
                    width: 16px;
                    border-radius: 40px 40px 0 0;
                    background-color: #f8f8f8;
                    transform: rotate(71deg);
                    z-index: -1;
                    visibility: hidden;
                }
            }
        }
    }
    &.flow_start {
        width: 150px;
        height: 50px;
        background: var(--flowBg);
        border-color: var(--flowBorder);
        border-radius: var(--radius1) var(--radius1) 0 0;
        &:hover {
            background: var(--flowBgHover);
        }
        &.activeNode {
            background: var(--flowBgActive);
            .content {
                .header {
                    color: var(--grey_shade) !important;
                }
                .value {
                    color: var(--dark_color) !important;
                }
            }
            .nodeActions svg {
                color: var(--grey_shade) !important;
            }
        }
    }

    &.nodeStyleSecondary {
        width: 162px;
        height: 66px;
        display: flex;
        justify-content: center;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        background-color: transparent !important;
        padding: 0px;
        border: 0px;

        &::after {
            content: "";
            height: 0;
            left: 0;
            position: absolute;
            top: 0px;
            width: 100%;
            height: 40px;
            background-color: var(--greyShadeTwo);
            border-radius: 4px;
        }

        &::before {
            content: "";
            position: absolute;
            bottom: -1px;
            width: 100%;
            height: 28px;
            background-color: var(--greyShadeTwo);
            clip-path: polygon(0% 0%, 100% 0%, 50% 100%, 50% 100%);
            z-index: 1;
        }

        &:hover {
            box-shadow: none !important;
            background-color: transparent !important;
        }
        span {
            font-size: 14px;
            font-weight: 500;
            color: var(--shade_ffffff);
            position: relative;
            z-index: 1;
            top: -7px;
        }
    }
}

.dropDownPopUpWrapper {
    box-shadow: 0px 32px 32px -24px rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, 0.9);
    border-radius: 8px;
    width: 187px;

    font-weight: 400;
    font-size: 14px;
    color: var(--dark_color);
    border-bottom: 1px solid var(--shade_EAF0F3);

    .singleOptionList {
        padding: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--dark_color);
        &:hover {
            background-color: var(--drpdownBG);
            cursor: pointer;
            font-weight: 500;
        }
    }
}

.statsTableHeader {
    padding: 10px 20px 0;
    font-weight: 500;
    color: var(--dark_color);
}

.statsTableRowText {
    cursor: pointer;
    &:hover {
        color: var(--brandColor);
    }
}

[data-theme="darkMode"] {
    .nodeWrapper {
        &.isAdded::before {
            background: #347d39;
        }
        &.isDeleted::before {
            background: #922323;
        }
        &.isUpdated::before {
            background: #966600;
        }
        &.start {
            color: var(--darkMode_white);
            background: #0e8b72;
            border-color: #0e8b72;
        }

        &:not(.activeNode) {
            &.destination:not(.isDeleted) {
                background: #1d2e29;
                border-color: #98ddc8;
                &:hover {
                    background: #26483e;
                    border-color: #98ddc8;
                    &::before,
                    &::after {
                        background: #26483e;
                        border-color: #98ddc8;
                    }
                }
                &::before,
                &::after {
                    background: #1d2e29;
                    border-color: #98ddc8;
                }
            }

            .nodeActions {
                svg:hover {
                    color: var(--textColor1);
                }
            }
            .content {
                color: var(--darkMode_primaryGrey);
                .header,
                .value {
                    color: var(--darkMode_white);
                }
                .diversifyLabel,
                .diversifyLabel svg {
                    border-color: var(--darkMode_white);
                    color: var(--darkMode_white);
                }
            }
            &:hover {
                .header,
                .nodeActions svg {
                    color: var(--darkMode_white);
                }
            }
            &.abRouter {
                &.abRouter {
                    background: var(--darkMode_genericGrey);
                    color: var(--darkMode_primaryGrey); //icon color
                    &:hover {
                        background: var(--darkMode_placeholder);
                        color: var(--darkMode_white);
                    }
                    .nodeActions {
                        background: var(--darkMode_placeholder);
                        border-color: var(--darkMode_genericGrey);
                    }
                }
            }
            &.router {
                color: var(--darkMode_white);
                svg {
                    color: var(--darkMode_white);
                }
                &:hover {
                    background: #654c3a;
                }
            }

            &.ab:hover .diversifyLabel svg {
                color: var(--darkMode_white);
            }
            &.cr {
                background: #1c2116;
                &:hover {
                    background: #3c4a29;
                }
            }

            &.preview:hover {
                background: var(--darkMode_placeholder);
            }
        }

        &.targeting {
            background: var(--darkMode_genericGrey);
            .value {
                color: var(--darkMode_primaryGrey) !important;
            }
            &:hover {
                background: var(--darkMode_placeholder);
                border-color: var(--darkMode_placeholder);
                .value {
                    color: var(--darkMode_white) !important;
                }
            }
        }

        &.activeNode {
            &.targeting {
                color: var(--darkMode_primaryGrey);
            }
            .header,
            .nodeActions svg,
            .tpContent,
            .tpContent svg,
            &:not(.targeting) .value,
            .diversifyLabel,
            .diversifyLabel svg,
            &.flow_start .header,
            &.flow_start .nodeActions svg {
                color: var(--darkMode_white) !important;
            }
            .diversifyLabel {
                border-color: var(--darkMode_white) !important;
            }
            &.destination {
                background: #44987f;
                border-color: #44987f;
                &:hover {
                    background: #44987f;
                    border-color: #44987f;
                }
                &::before,
                &::after {
                    background: #44987f;
                    border-color: #44987f;
                }
            }
            .content {
                .stats {
                    --tabBgColor: var(--darkMode_white);
                }

                .header {
                    .rulesCountWrapper {
                        color: #eaf0f3;
                        background-color: var(--statsActiveBg);
                    }
                }
            }
        }

        .nodeAddAction {
            &:before {
                border-color: var(--darkMode_placeholder);
            }
            &.disconnectedNode:before {
                border-color: var(--error);
            }
            span {
                &:hover {
                    background-color: var(--darkMode_placeholder);
                    svg {
                        color: #fff;
                    }
                }
            }
        }

        .expandCollapse {
            background: var(--darkMode_PanelBg) !important;
            border-color: var(--darkMode_headerPatch) !important;
        }

        .content {
            .header {
                .rulesCountWrapper {
                    color: var(--darkMode_primaryGrey);
                    background-color: var(--darkMode_genericGrey);
                    &:hover {
                        color: #fff;
                    }
                }
            }
            .statsVisual {
                &__impression {
                    background: linear-gradient(
                        90deg,
                        #41c3a9 -9.09%,
                        rgba(65, 195, 169, 0) 100%
                    );
                }

                &__drop {
                    background: linear-gradient(
                        180deg,
                        #b73333 51.04%,
                        rgba(183, 51, 51, 0.3) 100%
                    );
                    &:before {
                        background: linear-gradient(
                            180deg,
                            rgba(183, 51, 51, 0.3) 10%,
                            rgba(183, 51, 51, 0) 100%
                        );
                    }
                }

                --borderColor1: var(--impressionBg);
            }
        }

        &.preview {
            .previewContent {
                .rank {
                    color: #fff;
                }
            }
        }

        &.nodeStyleSecondary {
            span {
                color: #ffffff;
            }
        }
    }
    .dropDownPopUpWrapper {
        background: var(--darkMode_PanelBg);
    }
}

.flowWrapper {
    width: 100%;
    height: 100%;
    position: relative;
    .emptyGraphics {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        z-index: 1;
        img {
            margin-bottom: 10px;
            cursor: pointer;
        }
        p {
            font-weight: var(--fontMedium);
            color: var(--grey_shade);
        }

        .circleSkeleton {
            border-radius: 50%;
            overflow: hidden;
            width: 200px;
            height: 200px;
        }

        .lineSkeleton {
            width: 200px;
        }

        .editPhase {
            .skeletonSquare {
                border-radius: 40px;
                overflow: hidden;
            }
            .skeletonParallelogram {
                width: 200px;
                transform: skew(20deg);
                overflow: hidden;
                border-radius: 3px;
            }
            .skeletonRect {
                border-radius: 8px;
                overflow: hidden;
            }
        }
    }
}

.itemDetailsWrapper {
    ul {
        li {
            display: flex;
            margin-bottom: 20px;
            word-break: break-word;
            label {
                color: var(--textColor2);
                white-space: nowrap;
                margin-right: 10px;
            }
        }
    }
    .previewContainer {
        background: var(--shade_ffffff);
        padding: 20px 60px;
        display: flex;
        border-radius: 8px;

        .iframeHolder {
            // height: 200px;
            width: 340px;
            // overflow: hidden;
            .iframe {
                transform-origin: top left;
                transform: scale(0.25);
                pointer-events: none;
            }
        }
    }
}

//temporarily added
.copyWrap {
    width: 20px;
    margin: 0 auto;
    background: #fff;
}

:global {
    .react-flow__container {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
    .react-flow__pane {
        z-index: 1;
        cursor: -webkit-grab;
        cursor: grab;
    }
    .react-flow__pane.dragging {
        cursor: -webkit-grabbing;
        cursor: grabbing;
    }
    .react-flow__viewport {
        transform-origin: 0 0;
        z-index: 2;
        pointer-events: none;
    }
    .react-flow__renderer {
        z-index: 4;
    }
    .react-flow__selectionpane {
        z-index: 5;
    }
    .react-flow__nodesselection-rect:focus,
    .react-flow__nodesselection-rect:focus-visible {
        outline: none;
    }
    .react-flow .react-flow__edges {
        pointer-events: none;
        overflow: visible;
    }
    .react-flow__edge-path,
    .react-flow__connection-path {
        stroke: #cad1d5;
        stroke-width: 1;
        fill: none;
    }
    .react-flow__edge {
        pointer-events: visibleStroke;
        cursor: pointer;
    }
    .react-flow__edge.inactive {
        pointer-events: none;
    }
    .react-flow__edge.selected,
    .react-flow__edge:focus,
    .react-flow__edge:focus-visible {
        outline: none;
    }
    .react-flow__edge.selected .react-flow__edge-path,
    .react-flow__edge:focus .react-flow__edge-path,
    .react-flow__edge:focus-visible .react-flow__edge-path,
    .react-flow__edge:hover .react-flow__edge-path {
        stroke: #555;
    }
    .react-flow__edge-textwrapper {
        pointer-events: all;
    }
    .react-flow__edge-textbg {
        fill: white;
    }
    .react-flow__edge .react-flow__edge-text {
        pointer-events: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
    }
    .react-flow__connection {
        pointer-events: none;
    }
    .react-flow__connection.animated {
        stroke-dasharray: 5;
        -webkit-animation: dashdraw 0.5s linear infinite;
        animation: dashdraw 0.5s linear infinite;
    }
    .react-flow__connectionline {
        z-index: 1001;
    }
    .react-flow__nodes {
        pointer-events: none;
        transform-origin: 0 0;
    }
    .react-flow__node {
        position: absolute;
        border: none;
        // width: 250px;
        font-size: 12px;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
        pointer-events: all;
        transform-origin: 0 0;
        box-sizing: border-box;
        cursor: -webkit-grab;
        cursor: grab;
        transition: transform 200ms;
        &:hover {
            .react-flow__handle-bottom {
                opacity: 1;
            }
        }
    }
    .react-flow__node.dragging {
        cursor: -webkit-grabbing;
        cursor: grabbing;
        transition: transform 0ms;
        .nodeWrapper {
            background: var(--containerBgHover);
            box-shadow: var(--nodeShadow);
            &.router {
                background: var(--routerBgHover);
            }
            &.abRouter {
                background: var(--routerBgHover);
            }
            &.ab {
                background: var(--abBgHover);
            }
        }
    }
    .react-flow__nodesselection {
        z-index: 3;
        transform-origin: left top;
        pointer-events: none;
    }
    .react-flow__nodesselection-rect {
        position: absolute;
        pointer-events: all;
        cursor: -webkit-grab;
        cursor: grab;
    }
    .react-flow__handle {
        position: absolute;
        pointer-events: none;
        width: 10px;
        height: 10px;
        border: 1px solid #74838f;
        border-radius: 6px;
        transform-origin: center center;
        transition: all linear 100ms;
        opacity: 0;
        z-index: 1;
        &:before {
            content: "";
            background: #74838f;
            position: absolute;
            width: 6px;
            height: 6px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            border-radius: 4px;
        }
    }
    .react-flow__handle.connectable {
        pointer-events: all;
        cursor: crosshair;
    }
    .react-flow__handle-bottom {
        top: 100%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 15px;
        height: 15px;
        background: #fff;

        // &[data-nodeid^="set:"] {
        //     width: 0;
        //     height: 0;
        //     opacity: 0 !important;
        //     pointer-events: none !important;
        //     &:before {
        //         width: 0;
        //         height: 0;
        //     }
        // }
    }
    .react-flow__handle-top {
        left: 50%;
        top: -4px;
        transform: translate(-50%, 0);
        opacity: 0 !important;
        z-index: -1;
    }
    .react-flow__handle-left {
        top: 50%;
        left: -4px;
        transform: translate(0, -50%);
    }
    .react-flow__handle-right {
        right: -4px;
        top: 50%;
        transform: translate(0, -50%);
    }
    .react-flow__edgeupdater {
        cursor: move;
        pointer-events: all;
    }
    .react-flow__panel {
        position: absolute;
        z-index: 5;
        margin: 15px;
        display: none;
    }
    .react-flow__panel.top {
        top: 0;
    }
    .react-flow__panel.bottom {
        bottom: 0;
    }
    .react-flow__panel.left {
        left: 0;
    }
    .react-flow__panel.right {
        right: 0;
    }
    .react-flow__panel.center {
        left: 50%;
        transform: translateX(-50%);
    }
    .react-flow__attribution {
        font-size: 10px;
        background: rgba(255, 255, 255, 0.5);
        padding: 2px 3px;
        margin: 0;
    }
    .react-flow__attribution a {
        text-decoration: none;
        color: #999;
    }
    .react-flow__edge.updating .react-flow__edge-path {
        stroke: #777;
    }
    .react-flow__edge-text {
        font-size: 10px;
    }
    .react-flow__node-default,
    .react-flow__node-input,
    .react-flow__node-output,
    .react-flow__node-group {
        font-size: 12px;
        color: #222;
        text-align: center;
        border-radius: 8px;
    }
    .react-flow__node-default.selectable.selected,
    .react-flow__node-default.selectable:focus,
    .react-flow__node-default.selectable:focus-visible,
    .react-flow__node-input.selectable.selected,
    .react-flow__node-input.selectable:focus,
    .react-flow__node-input.selectable:focus-visible,
    .react-flow__node-output.selectable.selected,
    .react-flow__node-output.selectable:focus,
    .react-flow__node-output.selectable:focus-visible,
    .react-flow__node-group.selectable.selected,
    .react-flow__node-group.selectable:focus,
    .react-flow__node-group.selectable:focus-visible {
        // box-shadow: 0 0 0 0.5px #1a192b;
        // outline: none;
    }
    .react-flow__node-group {
        background-color: rgba(240, 240, 240, 0.25);
    }
    .react-flow__nodesselection-rect,
    .react-flow__selection {
        background: rgba(0, 89, 220, 0.08);
        border: 1px dotted rgba(0, 89, 220, 0.8);
    }
    .react-flow__nodesselection-rect:focus,
    .react-flow__nodesselection-rect:focus-visible,
    .react-flow__selection:focus,
    .react-flow__selection:focus-visible {
        outline: none;
    }
    .react-flow__controls {
        box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.08);
    }
    .react-flow__controls-button {
        border: none;
        background: #fefefe;
        border-bottom: 1px solid #eee;
        box-sizing: content-box;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 16px;
        height: 16px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
        padding: 5px;
    }
    .react-flow__controls-button:hover {
        background: #f4f4f4;
    }
    .react-flow__controls-button svg {
        width: 100%;
        max-width: 12px;
        max-height: 12px;
    }
    .react-flow__minimap {
        background-color: #fff;
    }
    .nodeWrapper.hideBottomHandle + .react-flow__handle-bottom {
        display: none;
    }

    [data-theme="darkMode"] {
        .react-flow__edge-path,
        .react-flow__connection-path {
            stroke: #465158;
        }
    }
}
