@keyframes hide {
    from {
        opacity: 1;
        position: relative;
        top: 0;
    }

    to {
        opacity: 0;
        position: relative;
        top: -15px;
    }
}

@keyframes show {
    from {
        opacity: 0;
        position: relative;
        top: -15px;
    }

    to {
        opacity: 1;
        position: relative;
        top: 0;
    }
}

.appNavigatorWrapper {
    position: fixed;
    max-width: 250px;
    width: 100%;
    background: var(--shade_ffffff);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    margin-right: 20px;
    min-height: 100vh;
    z-index: 998;
    transition: var(--transition2);
    &.collapseAppNavigatorWrapper {
        max-width: 66px;
        transition: var(--transition2);
        .logoWrapper {
            .arrowWrapper {
                margin-top: 20px;
                margin-left: 0px;
            }
        }
    }
    .logoWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 20px;
        border-bottom: 1px solid var(--shade_EAF0F3);
        margin-bottom: 20px;
        height: 89px;
        transition: var(--transition2);

        &.collapseLogoWrapper {
            flex-direction: column;
            justify-content: center;
            transition: var(--transition2);
            height: 126px;
            .mainLogoWrapper {
                width: 35px;
                overflow: hidden;
                margin-left: 0px;
                transition: var(--transition2);
            }
            .arrowWrapper {
                svg {
                    color: var(--brandColor);
                }
                &:hover {
                    svg {
                        color: var(--brandColor);
                    }
                }
            }
        }

        .mainLogoWrapper {
            width: 162px;
            overflow: auto;
            transition: var(--transition2);
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex: none;
        }

        .mainLogoWrapper::-webkit-scrollbar {
            display: none; /* Safari and Chrome */
        }

        .arrowWrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 28px;
            height: 28px;
            background: var(--shade_ffffff);
            box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
            border-radius: 8px;
            cursor: pointer;
            transition: var(--transition2);
            svg {
                color: var(--shade_74838F);
            }

            &:hover {
                svg {
                    color: var(--dark_color);
                }
            }
        }
    }

    .liContainer {
        height: Calc(100vh - 195px);
        overflow-y: scroll;
        overflow-x: hidden;
    }
}

.userProfileWrapper {
    cursor: pointer;
    width: 100%;
    position: fixed;
    bottom: 0;
    max-width: 250px;
    border-top: 1px solid var(--shade_EAF0F3);
    z-index: 9;
    overflow: hidden;
    transition: var(--transition2);
    &.userProfileWrapperCollapse {
        max-width: 66px;
        transition: var(--transition2);
    }

    &.profileDropdownOpen {
        display: none;
    }

    .userProfile {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: Calc(100% - 20px);
        margin: 10px;
        padding: 10px;
        overflow: hidden;
        &:hover {
            background: var(--shade_f0f6f6);
            border-radius: 8px;
        }

        &.userProfileCollapse {
            margin: 10px auto;
            padding: 7px;
            width: calc(100% - 10px);
        }

        .userInfoWrapper {
            display: flex;
            align-items: center;
            .defaultProfile {
                color: var(--brandColor);
                background: #eafffb;
                border-radius: 50%;
                margin-right: 10px;
            }
            .userName {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                color: #fff;
                font-weight: var(--fontBold);
                font-size: var(--fontRegular);
                margin-right: 10px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    text-align: center;
                    border-radius: 50%;
                }
            }

            .userDataInfo {
                display: flex;
                flex-direction: column;
                flex: none;
                .name {
                    color: var(--dark_color);
                    font-size: 14px;
                    span {
                        font-weight: 500;
                    }
                }
            }
        }
    }
}

.dropdown {
    cursor: pointer;
    position: fixed;
    width: 0px;
    bottom: 0;
    background: rgba(255, 255, 255, 0.9);
    border: 1px solid #eaf0f3;
    backdrop-filter: blur(6px);
    border-radius: 8px;
    height: 0px;
    transition: var(--transition2);
    padding-top: 10px;
    z-index: 99;
    visibility: hidden;
    > div input + div {
        visibility: hidden;
    }

    &.profileDropdownActive {
        width: 310px;
        height: 252px;
        transition: var(--transition2);
        visibility: visible;
        .dropdownList {
            animation: show 1s;
            animation-fill-mode: forwards;
        }
    }

    .dropdownList {
        padding-left: 10px;
        padding-right: 10px;
        animation: hide 0.2s;
        animation-fill-mode: forwards;

        &:hover {
            background: #f0f6f6;
            border-radius: 8px;
        }
        &:last-child {
            margin-bottom: 10px;
        }
    }

    .userInfodropdownContainer {
        border-top: 1px solid var(--shade_EAF0F3);
        margin-top: 8px;
        .userInfodropdownWrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: calc(100% - 20px);
            margin: 10px;
            padding: 10px;
            &:hover {
                background: var(--shade_f0f6f6);
                border-radius: 8px;
            }
            .userInfodropdownActive {
                display: flex;
                align-items: center;
                .defaultProfile {
                    color: var(--brandColor);
                    background: #eafffb;
                    border-radius: 50%;
                    margin-right: 10px;
                }
                .userName {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    color: #fff;
                    font-weight: var(--fontBold);
                    font-size: var(--fontRegular);
                    margin-right: 10px;
                    flex: none;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        text-align: center;
                        border-radius: 50%;
                    }
                }

                .userDataInfo {
                    display: flex;
                    flex-direction: column;
                    .name {
                        font-weight: 500;
                        font-size: 14px;
                        color: var(--dark_color);
                    }
                    .email {
                        font-weight: 400;
                        font-size: 14px;
                        color: var(--grey_shade);
                        margin-top: 2px;
                        word-break: break-all;
                        line-height: 1;
                    }
                }
            }
        }
    }
}

.dropdownList {
    display: flex;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
    width: Calc(100% - 20px);
    transition: 0.5s linear;
    padding-top: 10px;
    padding-bottom: 10px;
    .option {
        color: var(--grey_shade);
    }
    .icon {
        margin-right: 10px;
        color: var(--grey_shade);
    }
    .extras {
        margin-left: auto;
    }
    &:hover {
        .option {
            color: var(--dark_color);
        }
    }

    &.dropdownListCollapse {
        height: 38px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: 10px;
        margin-right: 10px;
        width: Calc(100% - 20px);
        padding: 0px 10px;
        &:hover {
            background: #f0f6f6;
            cursor: pointer;
            .option {
                color: var(--dark_color);
            }
        }
    }
}

.selectHeaderWrapper {
    padding: 20px;
    display: flex;
    border-bottom: 1px solid var(--borderColor4);
    .profileIcon {
        color: var(--brandColor);
        margin-right: 12px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }
    .userDetails {
        display: flex;
        flex-flow: column;
        word-break: break-all;
        .name {
            color: var(--dark_color);
            font-weight: 600;
        }
        .email {
            color: var(--grey_shade);
            margin-top: auto;
        }
    }
}

[data-theme="darkMode"] {
    .appNavigatorWrapper {
        background: var(--darkMode_PanelBg);
        .logoWrapper {
            .arrowWrapper {
                background: var(--darkMode_genericGrey);
            }
        }
    }

    .userProfileWrapper {
        .userProfile {
            &:hover {
                background: var(--darkMode_genericGrey);
            }
        }
    }

    .dropdown {
        background: var(--darkMode_PanelBg);
        border: 1px solid var(--borderColor5);
        .dropdownList {
            &:hover {
                background: var(--darkMode_genericGrey);
            }
        }

        .userInfodropdownWrapper {
            &:hover {
                background: var(--darkMode_genericGrey);
            }
        }
    }

    .subMenuWrapper {
        .dropdownList {
            &:hover {
                border-radius: 8px;
                background: var(--darkMode_genericGrey);
            }
        }
    }
}
