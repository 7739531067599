.searchBar {
    max-width: 320px;
}

.container {
    border-top: 1px solid var(--borderColor1);
    height: calc(100% - 90px);

    .containerChild {
        min-width: 50%;
        .top {
            height: 58px;
            color: var(--grey_shade);
            font-weight: var(--fontMedium);
            .btn {
                cursor: pointer;
                &:hover {
                    color: var(--brandColor);
                }

                &.disabled {
                    pointer-events: none;
                    opacity: 0.6;
                }
            }
        }

        &.visibilityWrapper {
            .top {
                border-bottom: 1px solid var(--borderColor4);
            }
        }

        &.pinnedWrapper {
            background: var(--headerBg);
            border-left: 1px solid var(--borderColor1);
        }

        .content {
            color: var(--dark_color);
            overflow: auto;
            max-height: calc(100% - 58px);

            .row {
                padding: 0 10px;
                height: 38px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                position: relative;
                background: var(--shade_ffffff);
                border: 1px solid var(--borderColor4);
                margin-bottom: 10px;
                border-radius: var(--radius2);
                // z-index: 1001; //to override popup's 999
                order: 3;

                &:hover,
                &.active {
                    background: var(--shade_FAFCFC);
                }

                &.disabled {
                    opacity: 0.6;
                    pointer-events: none;
                }

                // &.isPinned {
                //     order: 0;

                //     &:last-child {
                //         margin-bottom: 40px;
                //         &:after {
                //             content: "";
                //             height: 1px;
                //             background: var(--borderColor4);
                //             position: absolute;
                //             bottom: -20px;
                //             left: -20px;
                //             right: -20px;
                //         }
                //     }

                //     .pinIcon svg {
                //         fill: var(--brandColor) !important;
                //     }
                // }

                // &.isPinned:last-child {
                //     background: red !important;
                // }

                .textWrapper {
                    width: calc(100% - 56px);
                    .dragIcon {
                        &:hover svg {
                            fill: var(--dark_color) !important;
                        }
                    }
                    .text {
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                }

                .pinIcon:hover svg {
                    fill: var(--brandColor) !important;
                }

                svg {
                    cursor: pointer;
                }

                .disabled {
                    pointer-events: none;
                    opacity: 0.6;
                }
            }
        }
    }
}

[data-theme="darkMode"] {
    .container {
        .row:hover {
            background: var(--darkMode_genericGrey) !important;
        }
    }
}
