.internalSections {
    display: flex;
    margin-bottom: 20px;
    gap: 10px;
    color: var(--grey_shade);
    &__trackingTool {
        gap: 10px;
    }

    &:last-child,
    &.noMargin {
        margin-bottom: 0;
    }

    &.headerWithButtons {
        max-width: calc(100% - 140px);
        .value {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }

    .key {
        min-width: 62px;
        font-weight: var(--fontMedium);
        font-size: var(--fontSize);
        color: var(--grey_shade);
        line-height: var(--lineHeight);
        &.choiceKey {
            padding-top: 10px;
        }
    }
    .value {
        font-weight: var(--fontRegular);
        font-size: var(--fontSize);
        color: var(--dark_color);
        line-height: var(--lineHeight);
        gap: 20px;
    }
}

.tcpaBox {
    border: 1px solid var(--borderColor1);
    border-radius: var(--radius2);
    flex-direction: column;
    gap: 0;
    word-wrap: break-word;
    margin-top: 10px;
    position: relative;
    .key {
        margin-bottom: 10px;
    }
    .deleteIcon {
        visibility: hidden;
        cursor: pointer;
        position: absolute;
        right: 20px;
        top: 20px;
    }

    &:hover .deleteIcon {
        visibility: visible;
    }

    .btnwrap {
        padding: 20px;
        border-top: 1px solid var(--borderColor4);
    }

    .dropdownWrap__requiredToggle {
        .key {
            visibility: hidden;
        }
    }

    &.tcpaBox__readOnly {
        padding: 20px;
        gap: 30px;
        .textEditor .key ~ div * {
            color: inherit;
        }
        .tcpaBox__optin,
        .internalSections__trackingTool {
            flex-direction: row;
            gap: 10px;
        }
    }
    &:not(.tcpaBox__readOnly) {
        .textEditor {
            padding: 20px 20px 0;
        }
        .options {
            padding: 0px 20px 20px 20px;

            .dropdownWrap {
                width: 48%;
            }
        }
    }
}

.deletePopup {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 3;
}

:global {
    .flowTcpaBox {
        .rdw-fontfamily-dropdown {
            max-width: 80px;
        }
        .dropdownwrapper > div {
            div > div {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
}

input[type="file"] {
    display: none;
}
