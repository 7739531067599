.button {
    margin-right: 20px;
    &:last-child {
        margin-right: 0;
    }
}
.popUpWrap {
    border-radius: var(--radius1);
    &.showOverlay {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 999;
        display: flex;
        justify-content: flex-end;
        background: var(--popupbg1) !important;
    }
    .wrapper {
        width: 440px;
        border-radius: var(--radius1);
        background-color: var(--panelBg);
        box-shadow: 0 -1px 8px -1px rgba(66, 71, 85, 0.25);
        margin-top: -1px;
        z-index: 999;
        transition: all 0.6s ease-out;
        -webkit-transition: all 0.6s ease-out;
        animation: fadeIn 0.6s ease-out;
        -webkit-animation: fadeIn 0.6s ease-out;
        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid var(--borderColor1);
            background-color: var(--panelBg);
            border-radius: var(--radius2) var(--radius2) 0 0;
            position: relative;
            .leftSideCloseIconWrap {
                position: absolute;
                top: 27px;
                left: -53px;
                cursor: pointer;
                &:hover svg {
                    color: var(--dark_color);
                }
            }
            .title {
                color: var(--dark_color);
                font-size: 20px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 24px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .close {
                color: var(--textColor2);
                cursor: pointer;
                &:hover {
                    color: var(--dark_color);
                }
            }
            &.whiteHeader {
                background: var(--panelBg);
                .title {
                    font-weight: 500;
                    font-size: 20px;
                    color: var(--dark_color);
                }
            }
            .popUploader {
                position: absolute;
                // top: 59px;
                top: 65px;
            }
        }
        .promptMessageWrapper {
            padding: 20px;
            // background: var(--darkMode_depthGrey);
            border-radius: var(--radius2);
            text-align: center;
            &.leftAlign {
                text-align: left;
            }
            &.rightAlign {
                text-align: right;
            }
            .promptMessage {
                font-size: 14px;
                line-height: 20px;
                color: var(--grey_shade);
                word-break: break-word;
            }
            .promptButtonWrapper {
                margin-top: 20px;
                text-align: center;
                display: flex;
                justify-content: flex-end;
            }
        }
        .body {
            padding: 20px 15px;
            position: relative;
            &.borderRadiusTop {
                border-radius: var(--radius2) var(--radius2) 0 0;
            }
            &.borderRadiusBottom {
                border-radius: 0 0 var(--radius2) var(--radius2);
            }
            &.borderRadiusAll {
                border-radius: var(--radius2);
            }
        }

        .body::-webkit-scrollbar {
            width: 3px !important;
        }

        .footer {
            border-top: 1px solid var(--borderColor1);
            padding: 15px;
            display: flex;
            justify-content: space-between;
            background-color: var(--tabBg);
            border-radius: 0 0 var(--radius2) var(--radius2);
            &.showFooterBtnsEnd {
                justify-content: flex-start;
                flex-direction: row-reverse;
            }
            &.showWhiteFooter {
                background: var(--panelBg);
            }
            &.showActionButtonsAtEnd {
                justify-content: flex-end;
            }
        }
    }

    .imgWrapper {
        width: 533px;
        height: 533px;
        position: absolute;
        top: 0px;
        img {
            width: inherit;
            height: inherit;
            padding: 5px;
        }
    }
}

[data-theme="darkMode"] {
    .wrapper {
        box-shadow: var(--shadow3);
        .header {
            background: var(--darkMode_headerPatch);
            .title {
                color: var(--darkMode_white);
            }
        }
    }
}
