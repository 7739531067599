.noContainerWrap {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    .txt {
        font-size: var(--fontSizeRegular);
        font-weight: var(--fontBold);
        color: var(--shade_74838F);
        margin-top: 30px;
        text-align: center;
    }
}
