.navigatorRightPanel {
    width: 100%;

    &.rightPanelLarge {
        padding-left: 270px !important;
        transition: var(--transition2);
    }

    &.rightPanelSmall {
        padding-left: 86px !important;
        transition: var(--transition2);
    }

    &.maxWidth {
        max-width: 90%;
        margin: 0 auto;
    }

    &.maxWidthFlow {
        max-width: 100%;
        margin: 0 auto;
    }
}

.noAppNavigator {
    padding-left: 30px !important;
    padding-right: 30px !important;
    .navigatorRightPanel {
        padding-left: 0px !important;
    }
}
