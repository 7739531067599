.tableContainer {
    overflow: auto;

    &.withBorder {
        border: 1px solid var(--borderColor1);
        border-radius: var(--radius2);
    }

    .gridTableContainer {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(322px, 1fr));
        grid-gap: 20px;
        align-items: stretch;
        flex-wrap: wrap;
        padding: 20px 20px 20px 20px;
        background: url("../../assets/img/gridBG.svg");
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: 100%;
    }
    &.bottomPadding {
        padding-bottom: 14px;
    }
}
.innerScroll {
    top: 0;
}
.stickyWithScroll {
    top: -1px;
}

.table {
    width: 100%;
    text-align: left;

    .row {
        transition: background 200ms;
        &.accordionRow {
            line-height: var(--lineHeight);
            > .cell {
                border: none;
                background: var(
                    --backgroundColorAccordionTable
                ); //Updated accordion color as per design feedback
                > div {
                    position: sticky;
                    left: 0;
                }
                .tableContainer {
                    height: 100%;
                }
            }
        }
        .cellContent {
            // width: 100%;
            display: inline-block;
            vertical-align: middle;
        }
        .cell {
            white-space: nowrap;
            line-height: var(--lineHeight);
            color: var(--dark_color);
            padding: 10px 20px;
            border-bottom: 1px solid var(--borderColor5);
            transition: background 200ms;
            position: relative;
            vertical-align: middle;
            background: inherit;
            z-index: 1;
            &.expandCell {
                vertical-align: top;
                background: var(--shade_f7f7f7);
            }
            &.sticky {
                position: sticky;
                background: linear-gradient(
                    to right,
                    transparent,
                    var(--panelBg),
                    var(--panelBg),
                    var(--panelBg)
                );
            }
        }
    }
    .thead {
        position: relative;
        z-index: 3;
        box-shadow: var(--tableHeaderShadow1);
        background: var(--panelBg);

        &.stickyHeader {
            position: sticky;
        }

        .row {
            &:first-child {
                &:not(:last-child) {
                    // for grouped column header
                    text-align: center;
                    .cell {
                        border: none;
                        > div {
                            text-align: center;
                            justify-content: center;
                        }
                    }
                }
                .cell {
                    border-top: none;
                    background: var(--panelBg);
                }
            }
            .cell {
                color: var(--textColor2);
                font-family: var(--fontMedium);
                border-bottom: 2px solid var(--borderColor5);
                text-transform: capitalize;
                font-weight: 500;
                .sort-asc,
                .sort-des {
                    margin-left: 8px;
                    visibility: hidden;
                    opacity: 0;
                    cursor: pointer;
                    svg {
                        color: var(--shade_CAD1D4);
                    }
                    &:hover {
                        svg {
                            color: var(--grey_shade);
                        }
                    }
                }
                .sort-des {
                    transform: rotate(180deg);
                }
                &.active,
                &:hover {
                    .sort-asc,
                    .sort-des {
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }
        }
    }
    .tbody {
        > .row {
            background: var(--panelBg);
            &.accordionRow {
                &:hover {
                    > .cell {
                        background: var(--backgroundColorAccordionTable);
                    }
                }
            }
            &.expandRow {
                height: 150px;
                .cell {
                    .cellContent {
                        width: 100%;
                    }
                }
            }
            &.deletedRow {
                position: relative;

                .cell {
                    background: var(--softDelete) !important;
                    .cellContent {
                        text-decoration: line-through;
                        // width: 100%;
                    }
                }

                &:hover {
                    .cell {
                        background: var(--softDeleteHover) !important;
                    }
                }
            }
            &:active {
                background: var(--rowHoverBg);
            }
            &:hover {
                background: var(--rowHoverBg);
                > .cell {
                    &.sticky {
                        background: linear-gradient(
                            to right,
                            transparent,
                            var(--rowHoverBg),
                            var(--rowHoverBg),
                            var(--rowHoverBg)
                        );
                    }
                }
            }
        }
        .errorRow {
            background: var(--tagError);
            &:hover {
                background: var(--tagError);
            }
        }
        .warningRow {
            background: var(--tagWarning);
            &:hover {
                background: var(--tagWarning);
            }
        }
        .requiredRow {
            background: var(--tagRequired);
            &:hover {
                background: var(--tagRequired);
            }
        }
    }
}

:global {
    .actionBtnWrapper {
        padding: 0 !important;
        .btnWrap {
            height: 42px;
            display: flex;
            align-items: center;
            justify-content: end;
        }
    }
}

[data-theme="darkMode"] {
    .gridTableContainer {
        background: url("../../assets/img/gridBGDark.svg");
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: 100%;
    }

    .table {
        .tbody {
            > .row {
                &.expandRow {
                    .cell {
                        background: var(--darkMode_depthGrey);
                    }
                }
            }
        }
    }
}
