.multiComponent {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    & > div:first-child,
    & > div input {
        width: 70px;
        flex-shrink: 0;
    }
    .toggleSwitch {
        form {
            width: 210px;
        }
    }
    div {
        input {
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
        }
    }
    form {
        div {
            border-top-left-radius: 0px !important;
            border-bottom-left-radius: 0px !important;
        }
    }
}
