.singleDropdownCreateTagsWrapper {
    .utmSourcesWrapper {
        position: relative;
        .dropDownWrapper {
            position: absolute;
            left: 0px;
            top: -50px;
            width: 230px;
            .customOption {
                width: 100%;
                p:last-child {
                    color: var(--grey_shade);
                    margin-top: 5px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }
        }
    }
    .plusIconWrapper {
        cursor: pointer;
        border: 1px solid var(--borderColor4);
        border-radius: var(--radius2);
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
            background: var(--brandColor);
            svg {
                color: var(--shade_ffffff) !important;
            }
        }
    }
    ul li {
        margin: 0;
    }
}

.add-new-question {
    .tag-list-dragable-wrap {
        margin-bottom: 10px;
    }

    .question-container {
        padding: 20px;
        .has-offer {
            p {
                margin-bottom: 0px;
                margin-right: 10px;
            }
        }
    }
    .save-container {
        border-top: 2px solid var(--borderColor5);
        padding: 20px;
        justify-content: flex-end;
        Button {
            margin-right: 20px;
            &:last-child {
                margin-right: 0px;
            }
        }
    }
    .tableWrapper {
        max-width: var(--maxChildContainer);
    }
}

.add-question-wrap {
    margin-bottom: 20px;
}

.question-list-wrap {
    width: var(--maxChildContainer);
    // div:first-child {
    //     z-index: 1001;
    // }
}

.question-details-wrap-sidepanel {
    max-width: 640px;
    padding: 20px;
    margin-bottom: 20px;
    background: var(--shade_ffffff);
    border: 1px solid var(--borderColor1);
    box-sizing: border-box;
    border-radius: 8px;
    padding-bottom: 0px;

    .edit-options {
        visibility: hidden;
        .option {
            cursor: pointer;
            &__drag {
                cursor: drag;
            }
        }
    }

    &:hover .edit-options {
        visibility: visible;
    }

    .quesInnerDetails {
        max-width: 500px;
    }
    .questionContainer {
        max-width: 511px;
        display: flex;
        align-items: flex-start;
    }
    .key {
        color: var(--grey_shade);
        margin-right: 5px;
        max-width: 108px;
    }
    .value {
        color: var(--dark_color);
        font-weight: 400;
    }
    .questionWrapper {
        align-items: flex-start;
    }
    .questionValue {
        color: var(--dark_color);
        font-weight: 400;
        max-width: 420px;
        word-wrap: break-word;
    }

    .valueMulti {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        div {
            height: 38px;
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 0 10px;
            background: var(--drpdownBG);
            border-radius: var(--radius2);
            font-weight: 500;
            span {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                border: 1px solid var(--borderColor7);
            }
        }
    }

    .internalSections {
        display: flex;
        margin-bottom: 20px;
        gap: 10px;

        &__trackingTool {
            gap: 10px;
        }

        &:last-child,
        &.noMargin {
            margin-bottom: 0;
        }

        &.headerWithButtons {
            max-width: calc(100% - 140px);
            .value {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }

        .key {
            min-width: 62px;
            font-weight: var(--fontMedium);
            font-size: var(--fontSize);
            color: var(--grey_shade);
            line-height: var(--lineHeight);
            &.choiceKey {
                padding-top: 10px;
            }
        }
        .value {
            font-weight: var(--fontRegular);
            font-size: var(--fontSize);
            color: var(--dark_color);
            line-height: var(--lineHeight);
            gap: 20px;
        }
    }

    .infowrap p {
        display: inline-flex;
    }

    .tcpaBox {
        border: 1px solid var(--borderColor1);
        border-radius: var(--radius2);
        flex-direction: column;
        gap: 20px;
        // max-width: 650px;
        word-wrap: break-word;
        margin-top: 20px;

        .key {
            margin-bottom: 10px;
        }
        .btnwrap {
            padding: 20px;
            border-top: 1px solid var(--borderColor4);
        }

        &.tcpaBox__readOnly {
            padding: 20px;
            gap: 30px;
            .textEditor .key ~ div * {
                color: inherit;
            }
            .tcpaBox__optin,
            .internalSections__trackingTool {
                flex-direction: row;
                gap: 10px;
            }
        }
        &:not(.tcpaBox__readOnly) {
            .textEditor {
                padding: 20px 20px 0;
            }
            .options {
                padding: 0 20px;
            }
        }
    }

    .options-list {
        margin-bottom: 0px;
    }
    .question-name {
        line-height: 28px;
        margin-bottom: 0px;
        max-width: calc(100% - 130px);
    }

    .options-wrap {
        gap: 10px;
        padding: 10px;
        border-radius: var(--radius2);
        background: var(--drpdownBG);

        .option-bullet {
            border: 1px solid var(--borderColor7);
            border-radius: 50%;
            min-width: 10px;
            max-width: 10px;
            height: 10px;
        }
        .option-value {
            color: var(--dark_color);
            line-height: var(--lineHeight);
            font-weight: var(--fontMedium);
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}

.question-details-wrap {
    padding: 20px;
    background: var(--shade_ffffff);
    border: 1px solid var(--borderColor1);
    border-radius: 8px;
    margin-bottom: 10px;
    position: relative;
    word-break: break-all;
    // z-index: 1000;
    &::before {
        content: "";
        position: absolute;
        left: 0px;
        top: 4px;
        bottom: 4px;
        width: 1px;
        background: var(--brandColor);
        border-top-left-radius: 6px 20px;
        border-bottom-left-radius: 6px 20px;
    }
    &::after {
        content: attr(data-index);
        position: absolute;
        top: 20px;
        left: -11px;
        background: var(--shade_ffffff);
        border: 1px solid var(--brandColor);
        color: var(--brandColor);
        font-size: 11px;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
    }
    .question-header-wrap {
        border-bottom: 1px solid var(--borderColor5);
        padding-bottom: 10px;
        margin-bottom: 10px;
        min-height: 39px;
    }
    .edit-options {
        visibility: hidden;
        .option {
            cursor: pointer;
            &__drag {
                cursor: drag;
            }
        }
    }
    &:hover .edit-options {
        visibility: visible;
    }
    .questionContainer {
        max-width: 511px;
        display: flex;
        align-items: flex-start;
    }
    .questionTitle {
        display: none;
    }
    .quesInnerDetails {
        max-width: 500px;
    }
    .key {
        color: var(--grey_shade);
        margin-right: 5px;
        max-width: 105px;
    }
    .value {
        color: var(--dark_color);
        font-weight: 500;
    }
    .questionValue {
        color: var(--dark_color);
        font-weight: 500;
        max-width: 440px;
    }

    .valueMulti {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        div {
            height: 38px;
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 0 10px;
            background: var(--drpdownBG);
            border-radius: var(--radius2);
            span {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                border: 1px solid var(--borderColor7);
            }
        }
    }

    .additionalDetails {
        color: var(--dark_color);
    }
    .customTcpa {
        // margin-right: 114px;
        // min-width: 165px;
    }
    .tcpaHead {
        font-weight: 500;
        font-size: 14px;
        color: var(--grey_shade);
    }
    .tcpaHeadValue {
        font-weight: 400;
        font-size: 14px;
        color: var(--grey_shade);
    }
}

.editSection {
    position: relative;
    display: block;
    height: 100%;
    .title {
        font-size: var(--fontSize);
        background: var(--badgeBg);
        border: 1px solid var(--shade_E5EFEF);
        padding: 10px;
        border-radius: 8px;

        padding-left: 0px;
        padding-bottom: 0px;
        border: 0px;
        background: transparent;
        span:nth-child(1) {
            font-weight: var(--fontMedium);
            font-size: var(--fontSize);
            color: var(--grey_shade);
        }
        span:nth-child(2) {
            font-weight: normal;
            font-size: var(--fontSize);
            color: var(--dark_color);
        }
    }
    .hasOfferSwitch {
        display: flex;
        padding: 20px 0px;
        span {
            margin-right: 10px;
            color: var(--grey_shade);
            font-weight: var(--fontMedium);
            font-size: var(--fontSize);
        }
    }
    .adWrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        .subHeaderText {
            color: var(--grey_shade);
            font-weight: var(--fontMedium);
            font-size: var(--fontSize);
            padding-bottom: 10px;
        }
        .textToggleSwitch {
            width: 47%;
        }
        .dropdownWrapper {
            width: 50%;
        }
    }

    .inputBoxRow {
        margin-bottom: 20px;
        // form {
        //   display: flex;
        //   flex-direction: column;
        //   // align-items: flex-end;
        //   background: #ffffff;
        //   border: 1px solid var(--shade_E5EFEF);
        //   border-radius: 8px;
        //   padding-bottom: 20px;
        //   margin-top: 20px;
        //   .labelWrapper {
        //     padding: 10px 0px;
        //     background: var(--shade_FAFCFC);
        //     padding-left: 20px;

        //     label {
        //       max-width: 150px;
        //       margin-right: 24px;
        //     }
        //   }
        //   .inputContainer {
        //     display: flex;
        //     //   justify-content: space-between;
        //     padding-top: 20px;
        //     .inputWrapper {
        //       max-width: 150px;
        //       margin-right: 20px;
        //       &:nth-child(1) {
        //         margin-left: 20px;
        //       }
        //       input {
        //         width: 100%;
        //         margin-right: 20px;
        //       }
        //     }
        //   }
        // }
    }
    .fieldRow {
        flex-wrap: wrap;
        .badge {
            margin-bottom: 10px;
            margin-right: 18px;
            .textWrapper {
                font-weight: var(--fontMedium);
                font-size: var(--fontSize);
                color: var(--dark_color);
                .textOne {
                    color: var(--grey_shade);
                }
            }
            svg {
                color: var(--shade_CAD1D4);
                background: #fff;
                &:hover {
                    color: var(--dark_color);
                }
            }
        }
    }

    .cancelBtn {
        position: absolute;
        bottom: 20px;
        right: 20px;
    }
}

.badge {
    // margin-top: 20px;
    // cursor: pointer;
    background: transparent;
    border: 1px solid var(--borderColor3);
    padding-right: 12px;
    .icon {
        position: absolute;
        right: -7px;
        top: 6px;
    }

    svg {
        color: var(--shade_CAD1D4);
        background: #fff;
        &:hover {
            color: var(--dark_color);
        }
    }
}

.fieldValue {
    display: flex;
    flex-wrap: wrap;
    .badge {
        margin-bottom: 10px;
        margin-right: 10px;
    }
}

.internalSections {
    display: flex;
    margin-bottom: 20px;
    gap: 10px;
    color: var(--grey_shade);
    &__trackingTool {
        gap: 10px;
    }

    &:last-child,
    &.noMargin {
        margin-bottom: 0;
    }

    &.headerWithButtons {
        max-width: calc(100% - 140px);
        .value {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }

    .key {
        min-width: 62px;
        font-weight: var(--fontMedium);
        font-size: var(--fontSize);
        color: var(--grey_shade);
        line-height: var(--lineHeight);
        &.choiceKey {
            padding-top: 10px;
        }
    }
    .value {
        font-weight: var(--fontRegular);
        font-size: var(--fontSize);
        color: var(--dark_color);
        line-height: var(--lineHeight);
        gap: 20px;
    }
}

.quesCardTitle {
    border-bottom: 1px solid var(--borderColor4);
    color: var(--grey_shade);
    padding: 20px;
    margin: -20px -20px 20px -20px;
    font-weight: var(--fontMedium);
    font-size: 14px;
}

.questionBankDetails_Wrapper {
    padding-bottom: 0px;
    .card {
        position: relative;
        background: var(--shade_ffffff);
        border: 1px solid var(--shade_E5EFEF);
        border-radius: 8px;
        padding: 20px;
        margin-bottom: 20px;
        .fieldRow {
            &.questionRow {
                margin-bottom: 0px;
                padding-bottom: 10px;
                margin-bottom: 10px;
                border-bottom: 1px solid var(--shade_F3F9F9);
            }
            &.noFieldRow {
                margin-bottom: 0px;
                padding-bottom: 0px;
                border-bottom: 0px;
            }
            &.extraRow {
                align-items: flex-start;
                .fieldLabel {
                    margin-top: 5px;
                    // align-self: flex-start;
                }
            }
            .fieldValue {
                display: flex;
                flex-wrap: wrap;
                .badge {
                    margin-bottom: 10px;
                    margin-right: 10px;
                }
            }
        }
    }
    .editSection {
        position: relative;
        display: block;
        height: 100%;
        .title {
            font-size: var(--fontSize);
            background: var(--badgeBg);
            border: 1px solid var(--shade_E5EFEF);
            padding: 10px;
            border-radius: 8px;

            padding-left: 0px;
            padding-bottom: 0px;
            border: 0px;
            background: transparent;
            span:nth-child(1) {
                font-weight: var(--fontMedium);
                font-size: var(--fontSize);
                color: var(--grey_shade);
            }
            span:nth-child(2) {
                font-weight: normal;
                font-size: var(--fontSize);
                color: var(--dark_color);
            }
        }
        .hasOfferSwitch {
            display: flex;
            padding: 20px 0px;
            span {
                margin-right: 10px;
                color: var(--grey_shade);
                font-weight: var(--fontMedium);
                font-size: var(--fontSize);
            }
        }
        .adWrapper {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            .subHeaderText {
                color: var(--grey_shade);
                font-weight: var(--fontMedium);
                font-size: var(--fontSize);
                padding-bottom: 10px;
            }
            .textToggleSwitch {
                width: 47%;
            }
            .dropdownWrapper {
                width: 50%;
            }
        }

        .inputBoxRow {
            margin-bottom: 20px;
            // form {
            //   display: flex;
            //   flex-direction: column;
            //   // align-items: flex-end;
            //   background: #ffffff;
            //   border: 1px solid var(--shade_E5EFEF);
            //   border-radius: 8px;
            //   padding-bottom: 20px;
            //   margin-top: 20px;
            //   .labelWrapper {
            //     padding: 10px 0px;
            //     background: var(--shade_FAFCFC);
            //     padding-left: 20px;

            //     label {
            //       max-width: 150px;
            //       margin-right: 24px;
            //     }
            //   }
            //   .inputContainer {
            //     display: flex;
            //     //   justify-content: space-between;
            //     padding-top: 20px;
            //     .inputWrapper {
            //       max-width: 150px;
            //       margin-right: 20px;
            //       &:nth-child(1) {
            //         margin-left: 20px;
            //       }
            //       input {
            //         width: 100%;
            //         margin-right: 20px;
            //       }
            //     }
            //   }
            // }
        }
        .fieldRow {
            flex-wrap: wrap;
            .badge {
                margin-bottom: 10px;
                margin-right: 18px;
                .textWrapper {
                    font-weight: var(--fontMedium);
                    font-size: var(--fontSize);
                    color: var(--dark_color);
                    .textOne {
                        color: var(--grey_shade);
                    }
                }
                svg {
                    color: var(--shade_CAD1D4);
                    background: #fff;
                    &:hover {
                        color: var(--dark_color);
                    }
                }
            }
        }

        .cancelBtn {
            position: absolute;
            bottom: 20px;
            right: 20px;
        }
    }
    .subQuestions {
        background: var(--shade_f7f7f7);
        border: 1px solid var(--shade_E5EFEF);
        padding: 20px 20px 10px 20px;
        .title {
            font-weight: var(--fontMedium);
            font-size: var(--fontSize);
            color: var(--grey_shade);
            margin-bottom: 10px;
            span {
                color: var(--dark_color);
            }
        }
    }
    .fieldRow {
        margin-bottom: 20px;
        //   &:last-child {
        //     margin-bottom: 0px;
        //   }
    }
    .fieldRowChild {
    }
    .fieldLabel {
        color: var(--grey_shade);
        font-weight: var(--fontMedium);
        margin-right: 10px;
    }
    .fieldValue {
        color: var(--dark_color);
        font-weight: var(--fontMedium);
        margin-right: 10px;
        position: relative;
        &:last-child {
            margin-right: 0px;
        }
    }
    .floatIcons {
        // color: var(--borderColor3);
        position: absolute;
        right: 20px;
        top: 27px;
        transform: translateY(-50%);
        // opacity: 0;
        // visibility: hidden;
        transition: var(--transition);
        &:hover {
            opacity: 1;
            visibility: visible;
        }
        .active {
            color: black;
            transform: rotate(180deg);
        }

        .icon {
            width: 28px;
            height: 28px;
        }
    }
    .icon {
        margin-right: 20px;
        cursor: pointer;
        &:last-child {
            margin-right: 0px;
        }
    }
    .card:hover .floatIcons {
        opacity: 1;
        visibility: visible;
    }

    .panelGridBox:last-child,
    .fieldRow:last-child {
        margin-bottom: 0px;
    }
    .panelHeader {
        border-bottom: 1px solid var(--borderColor1);
        padding: 0px 20px 10px;
        margin: 0px -20px 20px;
    }
    //   .inputBoxRow {
    //     margin-bottom: 10px;
    //     input {
    //       width: calc(100% - 15px);
    //     }
    //   }
    .badge {
        // margin-top: 20px;
        // cursor: pointer;
        background: transparent;
        border: 1px solid var(--borderColor3);
        padding-right: 12px;
        .icon {
            position: absolute;
            right: -7px;
            top: 6px;
        }
    }
    .panelFooter {
        justify-content: flex-end;
        border-top: 2px solid var(--badgeBg);
        margin: 0px -20px;
        padding: 20px 20px 0px;
        position: sticky;
        bottom: 0;
        left: 0;
        right: 0;
        padding-bottom: 20px;
        background: var(--shade_FAFCFC);
        z-index: 1;
        .button:not(:last-child) {
            margin-right: 20px;
        }
    }
    &.scrollable {
        // max-height: 422px;
        // overflow-y: auto;
    }
    .removeBottomStyle {
        &.card {
            border-bottom: 0px;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            margin-bottom: 0px;
        }
    }
    .removeBottomStyle + .subQuestions {
        margin-bottom: 20px;
    }
    .errorSelect {
        border-color: var(--error) !important;
        label {
            color: var(--error) !important;
        }
    }
}

.tcpaHeader {
    color: var(--grey_shade);
    font-weight: var(--fontMedium);
    font-size: 14px;
    margin-bottom: 20px;
}

.sideTitle {
    font-weight: var(--fontMedium);
    color: var(--grey_shade);
    font-size: 14px;
    &.sideEditTitle {
        display: flex;
        align-items: center;
        svg {
            margin-right: 10px;
        }
        &:hover {
            color: var(--brandColor);
            cursor: pointer;
        }
    }
}

.hnpData {
    padding: 20px;
    border: 1px solid var(--borderColor4);
    border-radius: var(--radius2);
    color: var(--grey_shade);
    position: relative;
    &:not(:last-child) {
        margin-bottom: 20px;
    }
    p span {
        color: var(--dark_color);
        font-weight: 400;
    }
    .iconWrap {
        position: absolute;
        right: 20px;
        top: 20px;
        visibility: hidden;
    }
    &:hover .iconWrap {
        visibility: visible;
    }
    .adUnit {
        position: relative;
        top: 5px;
    }
    .adUnitKey {
        color: var(--grey_shade);
    }
    .more {
        color: var(--brandColor);
    }
    .hnpDetails {
        max-width: 450px;
        word-wrap: break-word;
    }
}

.popupStyle {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-right: 0px !important;
}

.panelNoPadding {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-right: 0px !important;
}

.itemContainer {
    height: calc(100vh - 146px);
    overflow: hidden;
}

.itemWrapper {
    border-right: 1px solid var(--shade_EAF0F3);
    overflow-y: scroll;
    width: 48%;
}

.inputWrapper {
    display: flex;
    flex-direction: column;
    .inputLabel {
        font-size: var(--fontSize);
        line-height: var(--lineHeight);
        letter-spacing: -0.022em;
        color: var(--grey_shade);
        margin-bottom: 10px;
    }
    .toggleWrapper,
    .dropdownWrapper {
        width: 100%;
    }
    .cardContainer {
        .subQuestionsWithAnswers {
            box-shadow: none;
            border: 1px solid var(--borderColor1);
            .header {
                padding-bottom: 10px;
                border-bottom: 1px solid var(--borderColor5);
                margin-bottom: 20px;
            }
            .keyValueWrapper {
                margin-bottom: 20px;
                &.noAlignCenter {
                    align-items: unset;
                }
                &.noMarginBottom {
                    margin-bottom: 0;
                }
                .value {
                    .validAnswerWrapper {
                        display: flex;
                        flex-direction: column;
                        .validAnswer {
                            display: flex;
                            align-items: center;
                            margin-bottom: 10px;
                            &:last-child {
                                margin-bottom: 0;
                            }
                            .validAnswer-bullet {
                                border: 2px solid var(--borderColor6);
                                border-radius: 24px;
                                min-width: 14px;
                                max-width: 14px;
                                height: 14px;
                                margin-right: 5px;
                            }
                            .validAnswer-key,
                            .validAnswer-value {
                                line-height: var(--lineHeight);
                                font-weight: var(--fontMedium);
                            }
                            .validAnswer-key {
                                color: var(--grey_shade);
                                border-right: 1px solid var(--borderColor6);
                                padding-right: 10px;
                                white-space: nowrap;
                            }
                            .validAnswer-value {
                                padding-left: 10px;
                                color: var(--dark_color);
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                            }
                        }
                    }
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .headerTitle {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .titleWrapper {
            margin-bottom: 0;
            flex-grow: 1;
            .titleValue {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
        .actions {
            margin-left: 5px;
            .icon {
                cursor: pointer;
            }
        }
    }
}

.rowInputWrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .inputWrapper {
        margin-bottom: 0;
    }
    .typeToggle {
        width: 64%;
    }
    .typeSelect {
        width: 100%;
    }
    &.noMarginBottom {
        margin-bottom: 0;
    }
}

.tiles {
    display: flex;
    flex-wrap: wrap;
    .addMoreWrapper {
        position: relative;
        .addMore {
            width: 28px;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            margin-bottom: 10px;
            color: var(--brandColor);
            border-radius: var(--radius2);
            border: 1px solid var(--borderColor1);
            cursor: pointer;
            &:hover {
                color: var(--btnColor);
                background: var(--brandColor);
            }
        }
        .plusDropdownWrapper {
            height: 30px;
            position: absolute;
            left: 0;
            max-width: 332px;
            min-width: 200px;
            width: 100%;
            top: 0;
            z-index: 1;
            .select {
                min-height: 30px !important;
                height: 30px;
            }
        }
    }
    .tileWrapper {
        display: flex;
        align-items: center;
        padding: 4px 10px;
        border: 1px solid var(--borderColor1);
        box-sizing: border-box;
        border-radius: var(--radius3);
        margin-right: 10px;
        position: relative;
        margin-bottom: 10px;
        .tileKey {
            font-size: var(--fontSize);
            line-height: var(--lineHeight);
            letter-spacing: -0.022em;
            color: var(--grey_shade);
        }
        .tileValue {
            @extend .tileKey;
            color: var(--dark_color);
        }
        .closeIcon {
            visibility: hidden;
            position: absolute;
            right: -6px;
            top: calc(50% - 7px);
            cursor: pointer;
        }
        &:last-child {
            margin-right: 0;
        }
        &:hover {
            background: var(--shade_f7f7f7);
            .closeIcon {
                visibility: visible;
            }
        }
    }
    &.editable {
        margin-top: 10px;
        .tileWrapper {
            margin-right: 18px;
        }
    }
    &.suggestion {
        .tileWrapper {
            cursor: pointer;
            background: var(--panelBg);
            margin-right: 10px;
            border: 1px dashed var(--borderColor7);
            .tileKey {
                color: var(--iconColor);
            }
            &:hover {
                background: var(--bodyBg);
                .tileKey {
                    color: var(--dark_color);
                }
            }
        }
    }
}

.editQuestionWrapper {
    display: flex;
    flex-direction: column;
    height: 385px;
    .editQuestionBody {
        min-height: calc(100% - 36px);
        overflow: auto;
        padding: 20px;
        .titleWrapper {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            .titleKey {
                font-size: var(--fontSize);
                line-height: var(--lineHeight);
                letter-spacing: -0.022em;
                color: var(--grey_shade);
            }
            .titleValue {
                @extend .titleKey;
                margin-left: 10px;
                color: var(--dark_color);
            }
        }
        .rowInputWrapper {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            .inputWrapper {
                width: 48%;
                margin-bottom: 0;
            }
            &.noMarginBottom {
                margin-bottom: 0;
            }
        }
    }
    .editQuestionActions {
        padding: 20px;
        display: flex;
        flex-direction: row-reverse;
        border-top: 2px solid var(--borderColor5);
        .button {
            margin-right: 20px;
            &:first-child {
                margin-right: 0;
            }
        }
    }
}

.tcpaDetail {
    display: flex;
    // justify-content: space-between;
    .additionalDetails {
        width: 100%;
        margin-right: 85px;
    }
    .additionalDetails:nth-child(1) {
        max-width: 90px;
    }
    .additionalDetails:nth-child(2) {
        max-width: 120px;
    }

    .additionalDetails:nth-child(3) {
        margin-right: 0px;
    }
}

.titleWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;
    .titleKey {
        font-size: var(--fontSize);
        line-height: var(--lineHeight);
        letter-spacing: -0.022em;
        color: var(--grey_shade);
    }
    .titleValue {
        @extend .titleKey;
        margin-left: 10px;
        color: var(--dark_color);
    }
}

.tcpaBox {
    border: 1px solid var(--borderColor1);
    border-radius: var(--radius2);
    flex-direction: column;
    gap: 20px;
    // max-width: 650px;
    word-wrap: break-word;
    margin-top: 20px;
    position: relative;
    .key {
        margin-bottom: 10px;
    }
    .deleteIcon {
        visibility: hidden;
        cursor: pointer;
        position: absolute;
        right: 20px;
        top: 20px;
    }

    &:hover .deleteIcon {
        visibility: visible;
    }
    .btnwrap {
        padding: 20px;
        border-top: 1px solid var(--borderColor4);
    }

    &.tcpaBox__readOnly {
        padding: 20px;
        gap: 30px;
        .textEditor .key ~ div * {
            color: inherit;
        }
        .tcpaBox__optin,
        .internalSections__trackingTool {
            flex-direction: row;
            gap: 10px;
        }
    }
    &:not(.tcpaBox__readOnly) {
        .textEditor {
            padding: 20px 20px 0;
        }
        .options {
            padding: 0 20px;
            margin-bottom: 20px;
        }
    }
}

.deletePopup {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
}

.configSelectWrap {
    position: absolute;
    top: 19px;
    left: -2px;
    z-index: 2;
    width: 100%;
}

.layoutKey {
    max-width: 95px;
    width: 100%;
    color: var(--grey_shade);
}

.idKey {
    max-width: 27px;
    width: 100%;
    color: var(--grey_shade);
}

.loader {
    position: absolute;
    top: 30px;
    width: 145px;
    left: 81px;
}

.btnViewDetails {
    cursor: pointer;
    &:hover {
        color: var(--brandColor) !important;
    }
}

.targettingValue {
    width: auto;
    height: 16px;
    display: inline;
}

.addItemsDropdownWrapper {
    position: absolute;
    bottom: 50px;
    width: 500px;
}

[data-theme="darkMode"] {
    .valueMulti {
        div {
            span {
                border: 1px solid var(--grey_shade);
            }
        }
    }
}
