.descContainer {
    display: flex;
    align-items: center;
}
.resetMargin {
    margin-bottom: 0;
    margin-right: 5px;
}
.subTitle {
    color: var(--grey_shade);
    font-size: var(--fontSize);
    font-weight: var(--fontMedium);
    line-height: var(--lineHeight);
    margin-bottom: 10px;
}
.customHeaderTitleRequired {
    text-transform: none;
}
.resetPadding {
    padding: 0;
    border: 1px solid var(--borderColor1);
    max-width: 100%;
    overflow: hidden;
}
.header {
    display: flex;
    padding: 10px 20px;
    background-color: var(--headerBg);
    border-radius: var(--radius2) var(--radius2) 0 0;
    border-bottom: 1px solid var(--borderColor1);
    .subHeader {
        color: var(--grey_shade);
        font-size: var(--fontSize);
        text-transform: capitalize;
        &:nth-child(1) {
            max-width: 10%;
            width: 100%;
        }
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
            max-width: 21%;
            width: 100%;
        }

        margin-right: 20px;

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
            margin-right: 32px;
        }

        &:nth-child(5) {
            margin-right: 18px;
        }

        &:nth-child(6) {
            max-width: 6%;
            width: 100%;
            visibility: hidden;
            margin-right: 0px;
        }
    }
}

.body {
    display: flex;
    padding: 20px;
    position: relative;
    background: var(--shade_ffffff);
    .input {
        max-width: 10%;
        width: 100%;
        height: 38px;
        &:first-child {
            margin-right: 20px;
        }
        input[type="text"] {
            height: 100%;
            padding: 10px;
            height: 38px;
        }
    }
}

.editInputClass {
    text-align: right;
}

.customSelectContainerLayer,
.secondCustomSelectContainerLayer {
    max-width: 21%;
    width: 100%;
    margin-bottom: 0px;
    margin-right: 20px;
    min-width: 0;
}

.secondCustomSelectContainerLayer,
.tableAdvDeal {
    [class*="dropdown"]:not([class*="dropdownList"]) {
        top: -240px !important;
    }
}

.tableAdvDeal {
    [class*="dropdown"]:not([class*="dropdownList"]) {
        top: -240px !important;
    }
}

.tableAdvDeal {
    width: 100%;
    margin-bottom: 0px;
    margin-right: 20px;
    min-width: 183px;
    max-width: 183px;
    width: 183px;
}

.formButton {
    max-width: 6%;
}

.saveBtnWrapper {
    display: flex;
    position: absolute;
    right: 20px;
    bottom: 20px;
    button:nth-child(1) {
        margin-right: 20px;
    }
}

.copyBtnWrapper {
    padding-right: 10px;
    margin-left: -5px;
}

.labelDealId {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--inputBg);
    padding: 11px;
    border-radius: var(--radius2);
    width: 100%;
    max-width: var(--maxChildContainer);
    border: 1px solid var(--borderColor1);
    position: relative;
    overflow: hidden;
    cursor: not-allowed;
    .loader {
        position: absolute;
        top: auto;
        bottom: 0;
    }
    &.placeholder {
        color: var(--textColor3);
    }
}

.disabled {
    background-color: var(--shade_f7f7f7);
    cursor: not-allowed;
}

.dealIdDropdownWrapper {
    .dealNameLabel {
        display: flex;
        align-items: center;
    }
    .multiValue {
        margin-top: 5px;
        font-size: 14px;
        color: var(--textColor2);
        display: flex;
        align-items: center;
    }
    span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.creativeWrap {
    border: 1px solid var(--borderColor4);
    border-radius: var(--radius2);
    background: var(--headerBg);
}

.keyHeading {
    color: var(--dark_color);
    font-weight: var(--fontMedium);
    font-size: var(--fontSizeRegular);
    padding-top: 10px;
    margin-bottom: 20px;
}

.keyValWrap {
    border: 1px var(--borderColor4) solid;
    border-radius: var(--radius2);
    position: relative;
    .deleteIcon {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
        visibility: hidden;
    }
    &:hover {
        .deleteIcon {
            visibility: visible;
        }
    }
}

.dropdownWrap {
    position: absolute;
    top: 42px;
    width: 300px;
    z-index: 2;
}

.deletePopup {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
}

:global {
    .dedupeAdUnit {
        z-index: 1000 !important;
    }
}
