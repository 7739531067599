[type="checkbox"] {
    display: none;
}
input[type="checkbox"]:disabled + label {
    cursor: not-allowed;
    opacity: 0.5;
}
.checkBoxLabel {
    // @include dp-parent-inline;
    cursor: pointer;
    display: flex;
    color: var(--grey_shade);
    width: 100%;
    .checkBox {
        width: 18px;
        height: 18px;
        box-sizing: border-box;
        border-radius: 4px;
        border: 1px solid var(--borderColor3);
        background-color: var(--btnBg);
    }
}

input:checked ~ label {
    .checkBox {
        background: var(--brandColor);
        border-color: transparent;
        .icon {
            opacity: 1;
        }
    }
}

.checkBox {
    .icon {
        opacity: 0;
        color: var(--shade_ffffff);
        font-size: 8px;
        display: flex;
    }
    span {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.labelText {
    margin-left: 10px;
    color: var(--dark_color);
    font-weight: var(--fontRegular);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
}

.rowReverseLabel {
    flex-direction: row-reverse;
}

.reverseRowIconWrapper {
    margin-right: 10px;
    display: flex;
}

.rowReverse {
    margin-left: 0px;
}

[data-theme="darkMode"] {
    .checkBoxLabel .checkBox {
        border-color: var(--darkMode_placeholder);
        background: var(--darkMode_depthGrey);
    }

    input:checked ~ label .checkBox {
        background: var(--brandColor);
    }

    input[type="checkbox"]:not(:disabled) + label {
        .checkBox:hover {
            border-color: var(--darkMode_primaryGrey);
        }
    }
}
