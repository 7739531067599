.fileUpload {
    position: relative;
    background: #fafcfc;
    // border: 1px dashed var(--borderColor4);
    border-radius: 8px;

    &.fileUpload__disabled {
        pointer-events: none;
    }

    .uploadIconWrapper {
        display: flex;
        align-items: center;
        width: 100%;
        margin-left: 10px;
        svg {
            margin-right: 10px;
            fill: var(--shade_74838F) !important;
            display: flex !important;
        }
    }

    .bottomRight {
        display: none;
        width: 210px;
        height: 32px;
        position: absolute;
        background: rgba(0, 0, 0, 0.7);
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        bottom: 25px;
        transform: translateX(-50%);
        left: 50%;
    }

    .flexHeight {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        > div {
            margin-right: 16px;
        }
        svg {
            color: #fff;
        }
        .replaceIconWrapper {
            svg {
                &:hover {
                    cursor: pointer;
                    color: var(--grey_shade);
                }
            }
        }
        .deleteIconWrapper {
            svg {
                &:hover {
                    cursor: pointer;
                    color: var(--errorHover);
                }
            }
        }
    }

    &:hover {
        .bottomRight {
            display: block;
        }
    }

    .mainImg {
        img {
            width: 100%;
            height: 100%;
        }
    }
}

.popUpHoverImg {
    img {
        object-fit: contain;
        width: 280px;
        height: 280px;
    }
}

.errMsg {
    display: flex;
    align-items: center;
    margin-top: 5px;
    svg {
        color: var(--error-color);
        margin-right: 5px;
    }
    font-weight: var(--fontRegular);
    font-size: var(--fontSizeSmall);
    color: var(--error-color);
}

.customMessage {
    display: flex;
    align-items: flex-start;
    margin-top: 10px;
    svg {
        margin-top: 3px;
    }
}

.isError {
    border: 1px solid var(--error-color);
}

.offlineConversion {
    max-width: 25%;
}

.dropzone {
    background: var(--shade_ffffff);
    display: flex;
    align-items: center;
    cursor: pointer;
    min-height: 48px;
    height: 100%;
    width: 100%;
    border: 1px dashed var(--borderColor4);
    border-radius: 8px;
    outline: none;
    padding-left: 10px;
    padding-right: 10px;
    .message {
        width: 100%;
    }
}

.dropzoneReject {
    border: 1px dashed var(--errorHover);
}

.dropzoneAccept {
    border: 1px dashed var(--brandColor);
}

.dropzoneActive {
    background: rgba(243, 249, 249, 0.9);
    border-radius: 8px;
    border: 1px dashed var(--brandColor);
}

.withPreview {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 0px;
    padding: 20px;
    background: var(--shade_FAFCFC);
    border: 1px dashed var(--borderColor4);
    border-radius: 8px;
    min-height: 230px;
}

.imgSelected {
    border: 1px solid var(--borderColor4);
}

.fileName {
    position: relative;
    z-index: 1;
    font-size: var(--fontSize);
    font-weight: var(--fontRegular);
    color: var(--grey_shade);
    display: flex;
    justify-content: flex-start;
    text-overflow: ellipsis;
    .name {
        overflow: hidden;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .removeFileBtn {
        font-weight: var(--fontRegular);
        font-size: var(--fontSize);
        line-height: 18px;
        color: var(--error-color);
        margin-left: 5px;
        cursor: pointer !important;
        max-width: 27%;
        &:hover {
            color: var(--errorHover);
        }
    }
}

.thumbsContainer {
    display: "flex";
    flex-direction: "row";
    flex-wrap: "wrap";
    margin-top: 16;
}

.thumb {
    display: "inline-flex";
    border-radius: 1px;
    border: 1px solid #eaeaea;
    margin-bottom: 8px;
    margin-right: 8px;
    width: 100px;
    height: 100px;
    padding: 4px;
    box-sizing: "border-box";
}

.thumbInner {
    display: "flex";
    min-width: 0;
    overflow: "hidden";
}

.imgPreview {
    display: "block";
    width: "auto";
    height: 100%;
}

.containerDrop {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    border-width: 1px;
    border-radius: 10px;
    border-color: blue;
    border-style: dashed;
    background-color: #fafafa;
    color: black;
    font-weight: bold;
    font-size: 1.4rem;
    outline: none;
    transition: border 0.24s ease-in-out;
}

.heading {
    display: flex;
    a {
        text-align: right;
        min-width: fit-content;
    }
    align-items: center;
    justify-content: space-between;
    svg {
        margin-right: 5px;
        display: flex !important;
        align-items: center;
    }
}

.title {
    color: var(--grey_shade);
    margin-right: 10px;
    margin-bottom: 10px;
    &.selectTitle {
        width: 100%;
    }
}
.fullWidthHeading {
    width: 640px;
}

.downloadTemplate {
    color: var(--grey_shade);
    text-decoration: underline;
    text-decoration-color: var(--grey_shade);
    display: flex;
    align-items: center;
    margin-right: 0px;
    &:hover {
        color: var(--brandColor);
        text-decoration-color: var(--brandColor);
    }
}

.infoText {
    display: flex;
    width: 100%;
    align-items: center;
    font-weight: var(--fontRegular);
    font-size: var(--fontSize);
    line-height: 18px;
    color: var(--shade_A8AEB1);
    margin-bottom: 10px;
}

.selectedImage {
    &:last-child {
        margin-right: 0px;
    }
    img {
        width: 210px;
        height: 210px;
        border-radius: 12px;
    }
    position: relative;
    text-align: center;
    color: white;
}

.imageContainer {
    display: flex;
}

.nameList {
    list-style: none;
    display: flex;
    > div {
        max-width: 310px;
    }
}
.withGreenTickImage {
    .listFileName {
        display: flex;
    }
    .nameList {
        color: var(--brandColor);
    }
    .fileLabel {
        margin: 0 5px 0 10px;
    }
}

.nameListWithPreview {
    justify-content: flex-start;
    width: 210px;
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
}

.listFileName {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;

    p {
        display: flex;
    }
}

.listFileNameWithPreview {
    max-width: 190px;
    div {
        width: inherit;
    }
}

.fileNameWithPreview {
    width: 210px;
}

.dropMessageWithPreview {
    display: flex;
    justify-content: center;
    align-items: center;
}

.floatingDropZone {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999999;
    background: transparent;
    width: 100%;
    height: 100%;
    pointer-events: none;
    &.active {
        pointer-events: all;
        background: rgba(243, 249, 249, 0.9);
        border-radius: 8px;
        border: 1px dashed var(--brandColor);
    }
    .imgDiv {
        display: flex;
        justify-content: center;
        height: 83%;
        flex-direction: column;
        align-items: center;
    }
    img {
        width: 25%;
    }

    .imgCaption {
        margin-top: 10px;
        color: var(--grey_shade);
        font-size: 20px;
    }

    .imgCaptionWithPreview {
        justify-content: center;
        align-items: center;
        flex: 1;
    }

    .imagePopUp {
        width: 511px;
        height: 511px;
        background: #fff;
        color: #000;
        display: flex;
        justify-content: center;
        align-items: center;
        &.active {
            border: 1px dashed var(--brandColor);
        }
        img {
            width: 100%;
            height: 100%;
        }
    }
}

@media screen and (max-width: 1220px) {
    .nameList {
        > div {
            max-width: 180px;
        }
    }
}

.popUp {
    position: absolute;
    white-space: normal;
    > div {
        background: none !important;
    }
}

.popupImage {
    position: absolute;
    right: -140px;
}

.uploadIconWrapperWithPreview {
    justify-content: center;
    align-items: center;
    flex: 1;
}

.uploadSuccessWrapper {
    display: flex;
    align-items: center;
    .uploadSuccessText {
        display: flex;
        align-items: center;
        font-weight: var(--fontMedium);
        font-size: var(--fontSize);
        color: var(--brandColor);
        svg {
            margin-right: 5px;
            color: var(--brandColor);
        }
    }
}
