.CreateSaveHeaderBox {
    display: flex;
    justify-content: space-between;
    margin: 0;
    position: relative;
    max-height: 70px;

    &::before {
        content: "";
        position: absolute;
        width: Calc(100% + 10px);
        height: 22px;
        background: var(--bodyBg);
        top: -23px;
        left: -10px;
    }

    &.noShadow {
        box-shadow: none;
    }

    .big-header {
        padding: 10px 0;
        .headerTitleBold {
            font-size: var(--fontSizexXL);
        }
        .flowName {
            max-width: 350px;
            color: var(--dark_color);
        }
        .editNameIcon {
            visibility: hidden;
            cursor: pointer;
        }
        &:hover .editNameIcon {
            visibility: visible;
        }
    }

    .bttnWrapper {
        .button:not(:last-child) {
            margin-right: 20px;
        }
        .switchViewWrapper {
            width: 120px;
            margin-right: 20px;
            font-weight: var(--fontMedium);
            z-index: 1;
            .select {
                border: 1px solid var(--borderColor1);
                &:hover,
                &__open {
                    background: var(--grey_shade);
                    > div {
                        color: var(--shade_ffffff);
                    }
                    svg {
                        fill: var(--shade_ffffff) !important;
                    }
                }
            }
        }
        .moreBtnWrapper {
            position: relative;
            margin-right: 20px;
            .more {
                width: 40px;
                height: 40px;

                svg {
                    transform: rotate(90deg);
                }
            }
            .moreBtnSelect {
                position: absolute;
                top: 0;
                right: 0;
                width: 100px;
                > div > div {
                    border: none !important;
                    background: none !important;
                    box-shadow: none !important;
                    input + div {
                        // visibility: hidden;
                        display: none;
                    }
                }
                .customOption:hover {
                    span {
                        color: var(--dark_color);
                    }
                }

                &.moreBtnSelect__flow {
                    width: 139px;
                }
            }
        }
        .publishButton {
            margin-left: 20px;
            position: relative;
            &:before {
                position: absolute;
                left: -20px;
                content: "";
                height: 100%;
                border-left: 1px solid var(--borderColor1);
            }
        }
    }

    .skeletonWrapper {
        .skeletonWrapper:not(:last-child) {
            padding-right: 20px;
        }
    }
}

.actionItem {
    padding: 5px;
    color: var(--textColor2);
    cursor: pointer;
    &:hover {
        color: var(--textColor1);
    }
}

.disabled {
    pointer-events: none;
    color: var(--textColor3);
}

.actionLayoutIconWrapper {
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.actionLayoutIconWrapperActive {
        background: var(--rowHoverBg);
        border-radius: 4px;
        svg {
            width: 15px;
            height: 16px;
            color: var(--brandColor);
        }
    }
}

.confirmText {
    color: var(--textColor2);
    font-weight: var(--fontRegular);
}

[data-theme="darkMode"] {
    .actionLayoutIconWrapper {
        color: var(--textColor3);
        &.actionLayoutIconWrapperActive {
            svg {
                color: var(--brandColor);
            }
        }
    }
    .actionItem {
        &:hover {
            color: var(--textColor3);
        }
    }
    .disabled {
        color: var(--darkMode_disabled);
    }
}
