.key-value-add-wrap {
    display: flex;
    flex-direction: column;
    // align-items: flex-end;
    background: #ffffff;
    border: 1px solid var(--shade_E5EFEF);
    border-radius: 8px;
    padding-bottom: 20px;
    margin-top: 20px;
    .labelWrapper {
        padding: 10px 0px;
        background: var(--shade_FAFCFC);
        padding-left: 20px;
        margin-right: 90px;
    }
    .inputContainer {
        display: flex;
        padding: 20px 20px 0;
        .inputWrapper {
            margin-right: 20px;
            &:last-child {
                margin-right: 0px;
            }
            input {
                width: 100%;
            }
        }
    }
}

.customTableCell {
    vertical-align: top;
}
