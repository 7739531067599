.status {
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    span {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 5px;
        &.live {
            background-color: var(--updateText);
        }
        &.active {
            background-color: var(--brandColor);
        }
        &.expired {
            background-color: var(--error);
        }
    }
}
