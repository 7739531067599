.switchField {
    .wrapper {
        position: relative;
        padding: 5px;
        background: var(--shade_E5EFEF);
        border: 1px solid var(--borderColor1);
        box-sizing: border-box;
        border-radius: 8px;
        flex-shrink: 0;
        // width: 100%;
    }
}

.switchField input {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;
}

.switchField label {
    position: relative;
    z-index: 1;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    color: var(--inputColor);
    opacity: 0.6;
    font-size: var(--fontSize);
    font-weight: normal;
    text-align: center;
    text-shadow: none;
    padding: 6px 0px;
    transition: all 0.1s ease-in-out;
}

.compact {
    label,
    .activator {
        width: 50px;
    }
}
.small {
    label,
    .activator {
        padding: 3px 0;
    }
}

.switchField label:hover {
    cursor: pointer;
}

.toggleValueSwitchDisabled label:hover {
    cursor: not-allowed;
}

.switchField input:checked + label,
.toggleValueSwitchActive {
    color: var(--dark_color) !important;
    opacity: 1 !important;
}

.toggleValueSwitchDisabled {
    cursor: not-allowed;
}

.disabled {
    opacity: 0.3;
}

.activator {
    left: 50%;
    position: absolute;
    width: 100px;
    height: calc(100% - 10px);
    background-color: var(--shade_ffffff);
    box-shadow: 2px 1px 4px rgba(172, 182, 182, 0.4);
    border-radius: 8px;
    padding: 6px 0px;
    transition: all 0.2s ease-in-out;
    &.toggleValueSwitchActive {
        left: 5px;
        transition: all 0.2s ease-in-out;
    }
}

.switchField label {
    border-radius: var(--radius2);
}

[data-theme="darkMode"] {
    .switchField {
        .wrapper {
            background: var(--darkMode_depthGrey);
        }
    }
    .activator {
        background: var(--darkMode_genericGrey);
        box-shadow: none;
    }
}
