.noCard {
    padding: 0px !important;
    box-shadow: none;
}

.errorTag {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #fffaf3;
    border: 1px solid #ffead2;
    border-radius: 8px;
    height: 37px;
    padding-left: 10px;
    font-weight: 500;
    font-size: 14px;
    color: #a8aeb1;
}

.templateTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .editBtn {
        width: 61px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 12px;
        color: var(--grey_shade);
        cursor: pointer;
        svg {
            margin-right: 6px;
        }

        &:hover {
            color: var(--brandColor);
            svg {   
                color: var(--brandColor);
            }
        }
    }
}

.sideTitle {
    font-weight: var(--fontMedium);
    color: var(--grey_shade);
    font-size: 14px;
    white-space: nowrap;
    display: flex;

    

    .infoIconWrap{
        margin-left: 5px;
    }
    
    &.sideEditTitle {
        display: flex;
        align-items: center;
        max-width: var(--maxChildContainer);
        justify-content: end;
        svg {
            margin-right: 10px;
        }
        &:hover {
            color: var(--brandColor);
            cursor: pointer;
        }
    }
}

.toolTipClass > div{
    word-wrap: break-word;
    word-break: break-word;
}

.popupStyle {
    padding:0px !important;
}

.idWrapper {
    color: var(--grey_shade);
}

.configSelectWrap {
    position: absolute;
    top: 19px;
    left: -2px;
    z-index: 2;
    width: 100%;
}

.checkboxLabel {
    color: var(--grey_shade);
}

.optionText {
    width: calc(100% - 16px);
}

[data-theme="darkMode"] {
    .errorTag {
        background: #101010;
        border-color: var(--error-color);
        box-shadow: var(--inputShadowError);
        color: var(--error-color);
    }
}

:global {
    .checkboxLabelContainer .labelText {
        color: var(--grey_shade);
    }
}
