.question-details-wrap {
    width: 640px;
    position: relative;
    word-break: break-all;
    &:hover {
        .edit-options {
            display: flex;
        }
    }
    .edit-options {
        display: none;
        position: absolute;
        top: 20px;
        right: 10px;
        .option {
            margin-right: 10px;
            cursor: pointer;
            width: 28px;
            &:last-child {
                margin-right: 0px;
            }
            &.drag-handle {
                border-radius: 50%;
                background: var(--grey_shade);
                color: var(--shade_ffffff);
                &:hover {
                    background: var(--grey_shade);
                }
                span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }

    .internalSections {
        display: flex;
        margin-bottom: 20px;
        gap: 10px;

        &__trackingTool {
            gap: 10px;
        }

        &:last-child,
        &.noMargin {
            margin-bottom: 0;
        }

        &.headerWithButtons {
            max-width: calc(100% - 140px);
            .value {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }

        .key {
            min-width: 62px;
            font-weight: var(--fontMedium);
            font-size: var(--fontSize);
            color: var(--grey_shade);
            line-height: var(--lineHeight);
            &.choiceKey {
                padding-top: 10px;
            }
        }
        .value {
            font-weight: var(--fontRegular);
            font-size: var(--fontSize);
            color: var(--dark_color);
            line-height: var(--lineHeight);
            gap: 20px;
        }
    }

    .infowrap p {
        display: inline-flex;
    }

    .options-list {
        margin-bottom: 0px;
    }
    .question-name {
        line-height: 28px;
        margin-bottom: 0px;
        max-width: calc(100% - 130px);
    }

    .options-wrap {
        gap: 10px;
        padding: 10px;
        border-radius: var(--radius2);
        background: var(--drpdownBG);

        .option-bullet {
            border: 1px solid var(--borderColor7);
            border-radius: 50%;
            min-width: 10px;
            max-width: 10px;
            height: 10px;
        }
        .option-value {
            color: var(--dark_color);
            line-height: var(--lineHeight);
            font-weight: var(--fontMedium);
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}

.add-question-wrap {
    margin-bottom: 20px;
}

.add-new-question {
    width: 640px;
    background: var(--shade_ffffff);
    border: 1px solid var(--borderColor1);
    box-sizing: border-box;
    border-radius: 8px;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        left: 0px;
        top: 4px;
        bottom: 4px;
        width: 1px;
        background: var(--brandColor);
        border-top-left-radius: 6px 20px;
        border-bottom-left-radius: 6px 20px;
    }
    &::after {
        content: attr(data-index);
        position: absolute;
        top: 20px;
        left: -11px;
        background: var(--shade_ffffff);
        border: 1px solid var(--brandColor);
        color: var(--brandColor);
        font-size: 11px;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
    }
    .tag-list-dragable-wrap {
        margin-bottom: 10px;
    }

    .question-container {
        padding: 20px;
        .has-offer {
            p {
                margin-bottom: 0px;
                margin-right: 10px;
            }
        }
        ul {
            max-width: 450px;
        }
    }
    .save-container {
        border-top: 2px solid var(--borderColor5);
        padding: 20px;
        justify-content: flex-end;
        Button {
            margin-right: 20px;
            &:last-child {
                margin-right: 0px;
            }
        }
    }
    .tableWrapper {
        max-width: var(--maxChildContainer);
    }
}

.deletePopup {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
}

.panelNoPadding {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-right: 0px !important;
}

.itemContainer {
    height: calc(100vh - 146px);
    overflow: hidden;
}

.itemWrapper {
    border-right: 1px solid var(--shade_EAF0F3);
    overflow-y: scroll;
    width: 48%;
}

.sortableList {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.add-new-question {
    margin-bottom: 20px;
}

.question-list-wrap {
    margin-bottom: 20px;
    width: var(--maxChildContainer);
}

.question-details-card {
    margin-bottom: 20px !important;
}

.tcpaDetail {
    max-width: 400px;
    padding: 0 20px 20px 20px;
    .key:nth-of-type(1) {
        max-width: 200px;
        width: 100%;
    }
}

.tcpaHeader {
    color: var(--grey_shade);
    font-weight: var(--fontMedium);
    font-size: 14px;
    margin-bottom: 20px;
}

.layoutWrapper {
    padding: 0px 20px 0px 20px;
    .layoutKey {
        max-width: 95px;
        width: 100%;
        color: var(--grey_shade);
    }
}

.tcpaHead {
    font-weight: 500;
    font-size: 14px;
    color: var(--grey_shade);
}

.tcpaHeadValue {
    font-weight: 400;
    font-size: 14px;
    color: var(--grey_shade);
}

.infowrap p {
    display: inline-flex;
}

.tcpaBox {
    border: 1px solid var(--borderColor1);
    border-radius: var(--radius2);
    flex-direction: column;
    gap: 20px;
    // max-width: 650px;
    word-wrap: break-word;
    margin-top: 10px;
    position: relative;
    .key {
        margin-bottom: 10px;
    }
    .deleteIcon {
        visibility: hidden;
        cursor: pointer;
        position: absolute;
        right: 20px;
        top: 20px;
    }

    &:hover .deleteIcon {
        visibility: visible;
    }

    .btnwrap {
        padding: 20px;
        border-top: 1px solid var(--borderColor4);
    }

    &.tcpaBox__readOnly {
        padding: 20px;
        gap: 30px;
        .textEditor .key ~ div * {
            color: inherit;
        }
        .tcpaBox__optin,
        .internalSections__trackingTool {
            flex-direction: row;
            gap: 10px;
        }
    }
    &:not(.tcpaBox__readOnly) {
        .textEditor {
            padding: 20px 20px 0;
        }
        .options {
            // padding: 20px;
            padding: 0px 20px 20px 20px;

            .dropdownWrap {
                width: 48%;
            }
        }
    }
}

.options-list {
    margin-bottom: 0px;
}
.question-name {
    line-height: 28px;
    margin-bottom: 0px;
    max-width: calc(100% - 130px);
}

.options-wrap {
    gap: 10px;
    padding: 10px;
    border-radius: var(--radius2);
    background: var(--drpdownBG);

    .option-bullet {
        border: 1px solid var(--borderColor7);
        border-radius: 50%;
        min-width: 10px;
        max-width: 10px;
        height: 10px;
    }
    .option-value {
        color: var(--dark_color);
        line-height: var(--lineHeight);
        font-weight: var(--fontMedium);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.internalSections {
    display: flex;
    margin-bottom: 20px;
    gap: 10px;
    color: var(--grey_shade);
    &__trackingTool {
        gap: 10px;
    }

    &:last-child,
    &.noMargin {
        margin-bottom: 0;
    }

    &.headerWithButtons {
        max-width: calc(100% - 140px);
        .value {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }

    .key {
        min-width: 62px;
        font-weight: var(--fontMedium);
        font-size: var(--fontSize);
        color: var(--grey_shade);
        line-height: var(--lineHeight);
        &.choiceKey {
            padding-top: 10px;
        }
    }
    .value {
        font-weight: var(--fontRegular);
        font-size: var(--fontSize);
        color: var(--dark_color);
        line-height: var(--lineHeight);
        gap: 20px;
    }
}

.infowrap p {
    display: inline-flex;
}

.sideTitle {
    font-weight: var(--fontMedium);
    color: var(--grey_shade);
    font-size: 14px;
    &.sideEditTitle {
        display: flex;
        align-items: center;
        svg {
            margin-right: 10px;
        }
        &:hover {
            color: var(--brandColor);
            cursor: pointer;
        }
    }
}

.hnpData {
    padding: 20px;
    border: 1px solid var(--borderColor4);
    border-radius: var(--radius2);
    color: var(--grey_shade);
    position: relative;
    &:not(:last-child) {
        margin-bottom: 20px;
    }
    p span {
        color: var(--dark_color);
        font-weight: 500;
    }
    .iconWrap {
        position: absolute;
        right: 20px;
        top: 20px;
        visibility: hidden;
    }
    &:hover .iconWrap {
        visibility: visible;
    }
}

.popupStyle {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-right: 0px !important;
}

.detailPropertiesWrap {
    color: var(--grey_shade);
    margin-bottom: 20px;

    .pill {
        height: 28px;
        padding: 0 10px;
        border-radius: var(--radius3);
        border: 1px solid var(--borderColor7);
        background: var(--badgeBg);
        display: flex;
        align-items: center;
        color: var(--dark_color);
        font-weight: var(--fontMedium);
        font-size: 14px;
        text-transform: capitalize;
    }
}

.layoutPopupContainer {
    display: flex;
    height: Calc(100vh - 185px);
    .layoutConfigWrapper {
        overflow-y: scroll;
        height: 100%;
        padding-right: 20px;
        .boxWrapper {
            max-width: 400px;
            width: 100%;
            padding-bottom: 20px;
            border-bottom: 1px solid var(--shade_EAF0F3);
            margin-bottom: 20px;
            .titleWrapper {
                display: flex;
                align-items: center;
                font-size: 14px;
                margin-bottom: 10px;
                div:nth-child(1) {
                    color: var(--grey_shade);
                    font-weight: 500;
                }
                div:nth-child(2) {
                    color: var(--dark_color);
                }

                &.titleWrapperSecond {
                    margin-bottom: 20px;
                }
            }

            .inputSecond {
                margin-left: 20px;
            }

            &:last-child {
                border-bottom: none;
            }
        }
    }

    .preview_container {
        // padding: 0 0 30px;
        // padding: 10px;
        padding-top: 20px;
        // padding-left: 30px;
        flex: 4;
        background-color: var(----shade_EAF0F3);
        .buttonGroupHeader {
            display: flex;
            justify-content: center;
            border-bottom: 1px solid var(--borderColor1);
            .downloadButton {
                margin-right: 20px;
                svg {
                    fill: var(--shade_74838F) !important;
                    cursor: pointer;
                }
            }
        }
        .buttonGroup {
            display: flex;
            // padding: 0px 10px 10px 20px;
            padding: 0px 10px 0px 0px;
            // border-bottom: 1px solid var(--borderColor1);
        }
        .tab {
            // padding: 10px 60px;
            cursor: pointer;
            opacity: 0.6;
            // background: white;
            border: 0;
            outline: 0;
            margin-right: 30px;
            padding-bottom: 10px;
            text-transform: capitalize;
            display: flex;
            align-items: center;
            &:first-child {
                margin-left: 30px;
            }

            &.active {
                border-bottom: 2px solid var(--brandColor);
                opacity: 1;
            }
        }
        .previewDeviceContainer {
            display: flex;
            justify-content: center;
            align-items: center;
            .fullHeight {
                display: flex;
                flex-direction: column;
                width: 100%;
                height: calc(91vh - 200px);
                justify-content: center;
                align-items: center;
            }
            .imgContainer {
                border-radius: 5px;
                margin-bottom: 20px;
                width: 90%;
                height: inherit;
                margin-left: auto;
                margin-right: auto;
                position: relative;

                &.isBigScreen {
                    margin-top: 10px;
                }
            }
        }
    }
}

.configSelectWrap {
    position: absolute;
    top: 19px;
    left: -2px;
    z-index: 2;
    width: 100%;
}

[data-theme="darkMode"] {
    --borderColor7: var(--darkMode_primaryGrey);
}
