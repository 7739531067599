.addButton {
    position: absolute;
    pointer-events: all;
    color: var(--textColor2) !important;
    cursor: pointer;
    background: var(--shade_ffffff);
    border: 1px solid var(--shade_EAF0F3);
    border-radius: 4px;
    display: flex;
    align-items: center;
    overflow: hidden;

    span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        border-right: 1px solid var(--shade_EAF0F3);
        .icon {
            cursor: pointer;
            &.icon__disabled {
                pointer-events: none;
            }
        }

        &:empty {
            width: 0px;
            height: 0px;
        }

        &:last-child {
            border-right: 0px;
        }

        &:hover {
            background-color: var(--grey_shade);
            svg {
                color: var(--shade_ffffff);
            }
        }
    }
}

.edge {
    &:hover {
        .addButton {
            display: block;
        }
    }
}

.addDisable {
    .icon {
        cursor: not-allowed;
    }
}

[data-theme="darkMode"] {
    .addButton {
        background: var(--darkMode_genericGrey);
        span {
            &:hover {
                background-color: var(--darkMode_placeholder);
                svg {
                    color: #fff;
                }
            }
        }
    }
}
