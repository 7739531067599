.cardContainer {
    background: var(--panelBg);
    border-radius: var(--radius1);
    margin-bottom: var(--gridSpacing2);
    box-shadow: var(--shadow1);
    box-sizing: border-box;
    border: 1px solid transparent;
    .header {
        &.border {
            border-bottom: 1px solid var(--borderColor4);
        }
        &.displayFlex {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px;
        }
        .titleWrapper {
            display: flex;
            // align-items: center;
            .icon {
                position: relative;
                top: 5px;
                margin-right: 10px;
                cursor: pointer;
                color: var(--grey_shade);
                &:hover {
                    color: var(--dark_color);
                }
            }
            h3 {
                cursor: pointer;
                margin-bottom: 0px;
                // color: inherit;
            }
            .secondaryHeaderTitle {
                margin-bottom: 0px;
            }
            [data-id="tooltip"] {
                display: flex;
            }
            .error {
                margin-left: 10px;
                color: var(--warningYellow);
            }
            &.showBrandColor {
                h3 {
                    font-size: 14px;
                    color: var(--brandColor2);
                }
                svg {
                    color: var(--brandColor2);
                    margin-right: 5px;
                }
            }
        }
        &.border {
            .titleWrapper svg {
                color: var(--brandColor);
            }
        }
        .infoWrapper {
            display: flex;
            align-items: center;
            color: var(--infoText);
            font-size: 14px;
            .infoIcon {
                width: 18px;
                height: 18px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 5px;
            }
        }
    }
    &.error {
        border-color: var(--error);
        color: var(--error);
        h3 {
            color: var(--error);
        }
    }
    .childWrapper {
        // display: initial;
        display: block;
        &.hide {
            display: none;
        }
    }
}
