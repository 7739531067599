.infoText {
    margin-bottom: 10px;
}

.popUpContainer {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.8);
    z-index: 999;
    .popUpWrapper {
        .popUpHeader {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 20px;
            border-bottom: 1px solid var(--shade_EAF0F3);
            margin-bottom: 20px;
            padding-left: 20px;
            padding-right: 20px;
            margin-left: -14px;
            margin-right: -14px;
            .text {
                font-weight: 500;
                font-size: 20px;
                color: var(--dark_color);
            }
            svg {
                color: var(--shade_CAD1D4);
                cursor: pointer;
                &:hover {
                    color: var(--dark_color);
                }
            }
            .popUploader {
                position: absolute;
                top: 35px;
            }
        }
        .popUpFooter {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            background: var(--shade_ffffff);
            box-shadow: 0px -2px 10px rgba(197, 197, 197, 0.2);
            padding-top: 20px;
            padding-left: 20px;
            padding-right: 20px;
            margin-left: -14px;
            margin-right: -14px;
            margin-bottom: -20px;
            padding-bottom: 20px;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            // margin-bottom: -20px;
            .footerBtnWrapper {
                button:last-child {
                    margin-left: 20px;
                }
            }
        }
        .imgContainer {
            width: 250px;
            height: 250px;
            background: var(--shade_FAFCFC);
            border: 1px solid var(----shade_EAF0F3);
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            .imgWrapper {
                width: 210px;
                height: 210px;
                border-radius: 12px;
                img {
                    width: 100%;
                    height: 100%;
                }
                .imgWrap {
                    position: relative;
                    width: 210px;
                    height: 210px;
                    object-fit: contain;
                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 12px;
                    }
                    .overLay {
                        display: none !important;
                    }
                    &:hover {
                        .overLay {
                            display: flex !important;
                            align-items: center;
                            justify-content: center;
                            position: absolute;
                            top: 0;
                            left: 0;
                            background: rgba(0, 0, 0, 0.6);
                            width: 100%;
                            height: 100%;
                            z-index: 2;
                            border-radius: 12px;
                            svg {
                                position: relative;
                                z-index: 2;
                                cursor: pointer;
                                color: var(--shade_ffffff);
                            }
                        }
                    }
                }
            }
        }

        .fileAdded {
            max-width: 250px;
        }

        .errMsg {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .errorMsgText {
                display: flex;
                align-items: center;
                margin-top: 5px;
                svg {
                    color: var(--error-color);
                    margin-right: 5px;
                }
                font-weight: var(--fontRegular);
                font-size: var(--fontSizeSmall);
                color: var(--error-color);
            }
            .previewText {
                font-weight: var(--fontMedium);
                font-size: var(--fontSizeSmall);
                color: var(--grey_shade);
                text-decoration: underline;
                cursor: pointer;
                &:hover {
                    color: var(--brandColor);
                }
            }
        }
    }
}

.popUpOverlay {
    opacity: 0.35;
}

.previewPopUp {
    max-width: 300px;
    width: 100%;
    padding: 5px;
    border: 1px solid #f1f1f1;
    box-shadow: 0px 32px 32px -24px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.9);
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 12px;
    }
}

[data-theme="darkMode"] {
    .extensionWrap {
        background: inherit;
    }

    .gridCardWrapper {
        .imgWrapper {
            .imgType {
                background: var(--dark_color);
            }
        }

        .overLay {
            background: var(--dark_color);
            opacity: 0.7;
            svg {
                color: var(--shade_FAFCFC);
            }
        }
    }

    .popUpContainer {
        background: var(--popupbg1);
    }
}
