.descContainer {
    display: flex;
    align-items: center;
}
.resetMargin {
    margin-bottom: 0;
    margin-right: 5px;
}
.subTitle {
    color: var(--grey_shade);
    font-size: var(--fontSize);
    font-weight: var(--fontMedium);
    line-height: var(--lineHeight);
    margin-bottom: 10px;
}
.resetPadding {
    padding: 0;
    border: 1px solid var(--borderColor1);
    max-width: 353px;
}
.header {
    display: flex;
    padding: 10px 20px;
    background-color: var(--headerBg);
    border-radius: var(--radius2) var(--radius2) 0 0;
    border-bottom: 1px solid var(--borderColor1);
    .subHeader {
        flex-grow: 2;
        color: var(--grey_shade);
        font-size: var(--fontSize);
        &.flexGrow {
            flex-grow: 1;
        }
        &:nth-child(1) {
            max-width: 210px;
            // max-width: 117px;
            width: 100%;
        }
        &:nth-child(2) {
            max-width: 210px;
            width: 100%;
            margin-left: 30px;
            // margin-left: 20px;
            margin-right: 20px;
        }
        &:nth-child(3) {
            max-width: 117px;
            width: 100%;
        }
    }
}
.body {
    display: flex;
    align-items: flex-start;
    padding: 20px;
    position: relative;
    background: var(--shade_ffffff);
    .input {
        max-width: 220px;
        width: 100%;
        height: 38px;
        margin-right: 20px;

        input[type="text"] {
            height: 100%;
            padding: 10px;
            height: 38px;
        }
    }
    .toggleSwitch {
        max-width: 220px;
        width: 100%;
        .wrapper {
            max-width: 500px;
        }
        label {
            width: 105px;
        }
        .customActive {
            left: 109px;
        }
    }
    .marginLeft {
        margin-left: 20px;
    }
}

.saveBtnWrapper {
    display: flex;
    position: absolute;
    right: 20px;
    bottom: 20px;
    button:nth-child(1) {
        margin-right: 20px;
    }
}

.collapseBtnWarpper {
    display: flex;
    align-items: center;
    svg {
        margin-right: 5px;
        position: relative;
        top: 0px;
        width: 18px;
        height: 18px;
    }
}
