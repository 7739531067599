:root {
    /* --font: "Source Sans Pro", sans-serif; */
    --font: "Roboto", sans-serif;
    /* fontWeight */
    --fontRegular: normal;
    --fontMedium: 500;
    --fontBold: 600;

    /* fontSize */
    --fontSize: 14px;
    --fontSizeSmall: 12px;
    --lineHeight: 18px;
    --fontSizeRegular: 16px;
    --lineHeightRegular: 20px;
    --fontSizeMedium: 20px;
    --lineHeightMedium: 24px;
    --fontSizeXL: 24px;
    --lineHeightXL: 28px;
    --fontSizexXL: 24px;
    --lineHeightxXL: 28px;

    /* z-index */
    --tooltipZindex: 1001;

    /* font colors */
    --textColor1: var(--dark_color);
    --textColor2: var(--grey_shade);
    --textColor3: var(--inputPlaceholderColor);

    /* color shades */
    --grey_shade: #74838f;
    --greyShadeTwo: #6c958d;
    --infoText: #a8aeb1;
    --dark_color: #0d2238;
    --brandColor: #41c3a9;
    --brandColor2: #35b098;
    --brandColor3: #237b69;
    --brandColorOpacity1: #41c3a91a;
    --updateText: #4a90e2;
    --updateBg: #f0f7ff;
    --addBg: #f5fafa;
    --removeBg: #fff1f1;
    --disabled: #eaf0f3;
    --infoColor2: #f0f7ff;
    --infoBorder: #c2deff;
    --infoIllustration: #f7fcfc;

    --brandHoverColor: #2fb89c;
    --brandHoverBorder: #027b62;
    --btnColor: #ffffff;
    --btnBg: #ffffff;
    --shade_E5EFEF: #e5efef;
    --shade_FAFCFC: #fafcfc;
    --shade_CAD1D4: #cad1d4;
    --shade_f7f7f7: #f7f7f7;
    --shade_ffffff: #ffffff;
    --shade_F3F9F9: #f3f9f9;
    --shade_EAF0F3: #eaf0f3;
    --shade_A8AEB1: #a8aeb1;
    --shade_74838F: #74838f;
    --shade_F6F9FA: #f6f9fa;
    --shade_fbffff: #fbffff;
    --shade_000000: #000;
    --shade_f0f6f6: #f0f6f6;
    --shade-fcfcfc: #fcfcfc;
    --shade-F0F7F7: #f0f7f7;
    --shade-FAFDFD: #fafdfd;
    --lightBlueShade: #90d8ff;
    --error: #e76e6e;
    --errorHover: #d65555;
    --listing-search-placeholder: #cad1d5;
    --pagination-color: #424755;
    --pagination-colorHover: var(----pagination-color);
    --hoverColor: #41c3a9;

    --tagError: #fff4f4;
    --tagWarning: #fffdf3;
    --tagRequired: #fff0de;
    --tagErrorBorder: #ffdcdc;
    --tagWarningColor: #e8bb1d;

    --requiredIcon: #ff971b;
    --errorIcon: #e76e6e;

    --bodyBg: #f3f9f9;
    --letterSpacing: 0.01em;
    --container: 1360px;
    --sidePadding1: 0px 10px;
    --sidePadding2: 0px 20px;
    --sidePadding3: 0px 30px;
    --marginAuto: 0px auto;
    --transition: all 0.1s ease;
    --transition2: 0.2s linear;
    --transition3: all 0.3s ease;
    --transition6: all 0.6s ease;
    --transition01: all 1s ease;

    /* panel css */
    --panelBg: #ffffff;
    --tabBg: #f1f5f8;
    --HNPValueBgColor: #e5efef;
    --gridSpacing1: 30px;
    --gridSpacing2: 20px;
    --shadow1: 0px 4px 20px rgba(0, 0, 0, 0.04);
    --shadow2: 0px 4px 3px rgba(229, 239, 239, 0.46);
    --shadow3: 0px 4px 10px rgba(0, 0, 0, 0.5);
    --shadow4: 0px -2px 10px rgba(197, 197, 197, 0.2);
    --tableHeaderShadow: 0px 5px 10px rgb(0 0 0 / 6%);
    --tableHeaderShadow1: 0 1px 0 0 var(--borderColor5);
    --inputBg: var(--shade_ffffff);
    --inputColor: var(--dark_color);
    /* --inputColor: #000; */
    --inputPlaceholderColor: var(--shade_CAD1D4);
    --inputShadow: 0px 0px 0px 1px var(--borderColor2);
    --inputShadowError: 0px 0px 0px 1px var(--error-color);
    --radius1: 12px;
    --radius2: 8px;
    --radius2Child: 7px;
    --radius3: 24px;
    --radius4: 20px;
    --radius5: 4px;
    --borderColor1: #e5efef;
    --borderColor2: #a8e2e2;
    --borderColor3: #cad1d4;
    --borderColor4: #eaf0f3;
    --borderColor5: #f3f9f9;
    --borderColor6: #74838f;
    --borderColor7: #cad1d5;
    --borderColor8: #f1f1f1;
    --borderColor9: #ebeced;
    --borderColor10: #cad0d4;
    --copyRender: var(--borderColor10);

    /* listing header */
    --listingHeaderElemHeight: 48px;

    /* table css */
    --backgroundColorTable: #f9fafb;
    --softDelete: #fbe9e9;
    --softDeleteHover: #fcdbdb;
    --backgroundColorAccordionTable: #f7f7f7;

    --rowBg: var(--shade_ffffff);
    --rowHoverBg: #f2fcfa;

    --tabMinHeight: 48px;
    --tabBgColor: #eaf0f3;
    --dragHandleBgColor: #eaf0f3;
    --headerBg: #fafcfc;
    --headerBgV2: var(--headerBg);
    --iconColor: #cad1d5;
    --toggleDefaultColor: #74838f;
    --toggleDisabledColor: #cad1d4;
    --toggleReadOnlyColor: #cad1d4;

    --drpdownBG: #f5fafa;
    --maxChildContainer: 640px;
    --badgeBg: #f7f7f7;
    --copyIconBg: #d8dee1;

    /* --topSpacing: 67px; */
    --topSpacing: 20px;
    --nav-header-listing-pos-end: 116px;
    --table-header-listing-pos-end: 181px;
    --error-color: #e76e6e;
    --error: var(--error-color);

    --active-color: var(--brandColor);
    --inactive-color: #e76e6e;
    --warningYellow: #f6b656;
    --warningYellowHover: #ec991d;

    --reviewStatus: #efc01c;

    /* Pop up */
    --popupbg1: rgba(255, 255, 255, 0.8);
    --popupbg2: rgba(255, 255, 255, 0.95);
    --popupHeaderBg: #fff;

    /* Max Width Above 1400px */

    --maxWidthAbove1400: 1384px;
    --toastify-toast-width: 400px;
    
    /* Transition */
    --transitionSpeed1: 300ms;

    --stickyRowBg: var(--shade_ffffff);

    /* flow  */
    --lightBlue: #f1f7ff;
    --routerBorder: #ff9e57;
    --routerBg: #fff9f5;
    --routerBgHover: #ffeadb;
    --flowBg: #fffde8;
    --flowBgHover: #fffac6;
    --flowBgActive: #fff388;
    --flowBorder: #e2d55d;
    --nodeShadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

    --abBorder: #9490fb;
    --abBg: #f6f5ff;
    --abBgHover: #ebe9fe;

    --containerBorder: #2198db;
    --containerBg: #f8fcff;
    --containerBgHover: #daf2ff;

    /* name intials */
    --bg1: #311c9d;
    --bg2: #b00068;
    --bg3: #117287;
    --bg4: #b3772d;
    --bg5: #367618;
    --bg6: #866b36;

    /* treeview row colors */
    --tbg1: #fafafa;
    --tbg2: #f1f1f1;
    --tbg3: #e8e8e8;
    --tbg4: #dbdbdb;
    --tbg5: #cacaca;

    --impressionBg: #e0eeff;
    --forwardBg: #e7fdf8;
    --dropRateBg: var(--tagError);
    --statsActiveBg: #207fb5;
    --dateHighlight: #e5efef;

    /* dark theme colors */
    --darkMode_primaryGrey: #81919e;
    --darkMode_genericGrey: #282c2f;
    --darkMode_depthGrey: #101010;
    --darkMode_PanelBg: #1b1b1b;
    --darkMode_white: #d2e2f2;
    --darkMode_headerPatch: #232323;
    --darkMode_placeholder: #465158;
    --darkMode_disabled: #465158;
    --darkModeInfoColor2: #282c2f;
    --darkModeInfoBorder: #4a90e2;
    --darkMode_body: #0b0b0b;
    --darkMode_text: #b3babf;
}

[data-theme="darkMode"] {
    background: var(--bodyBg) !important; /*to override inline style*/
    color: var(--darkMode_primaryGrey);
    --btnColor: var(--darkMode_depthGrey);
    --btnBg: var(--darkMode_genericGrey);
    --bodyBg: var(--darkMode_depthGrey);
    --grey_shade: var(--darkMode_primaryGrey);
    --panelBg: var(--darkMode_PanelBg);
    --dark_color: var(--darkMode_white);
    --shade_EAF0F3: var(--darkMode_genericGrey);
    --borderColor1: var(--darkMode_genericGrey);
    --borderColor2: var(--darkMode_genericGrey);
    --borderColor3: var(--darkMode_genericGrey);
    --borderColor4: var(--darkMode_genericGrey);
    --borderColor5: var(--darkMode_genericGrey);
    --borderColor6: var(--darkMode_genericGrey);
    --borderColor7: var(--darkMode_genericGrey);
    --borderColor8: var(--darkMode_genericGrey);
    --borderColor10: var(--darkMode_genericGrey);
    --headerBgV2: var(--darkMode_genericGrey);
    --copyRender: var(--darkMode_disabled);
    --pagination-color: var(--darkMode_primaryGrey);
    --pagination-colorHover: var(--darkMode_white);
    --inputBg: var(--darkMode_depthGrey);
    --inputColor: var(--darkMode_white);
    --inputShadow: 0px 0px 10px 0 rgba(65, 195, 169, 0.5);
    --inputPlaceholderColor: var(--darkMode_placeholder);
    --listing-search-placeholder: var(--darkMode_placeholder);
    --infoText: var(--darkMode_placeholder);
    --stickyRowBg: var(--darkMode_PanelBg);
    --tabBgColor: var(--darkMode_headerPatch);
    --tabBg: var(--darkMode_genericGrey);
    --rowBg: var(--darkMode_PanelBg);
    --rowHoverBg: var(--darkMode_genericGrey);
    --backgroundColorTable: var(--darkMode_genericGrey);
    --backgroundColorAccordionTable: var(--darkMode_genericGrey);
    --shade_000000: var(--darkMode_white);
    --iconColor: var(--darkMode_primaryGrey);
    --copyIconBg: var(--darkMode_depthGrey);
    --infoColor2: var(--darkModeInfoColor2);
    --infoBorder: var(--darkModeInfoBorder);
    --infoIllustration: var(--darkMode_headerPatch);

    --dragHandleBgColor: var(--darkMode_genericGrey);

    --shade_ffffff: var(--darkMode_depthGrey);

    --shade_FAFCFC: var(--darkMode_depthGrey);

    --shade_F3F9F9: var(--darkMode_depthGrey);
    --shade_f7f7f7: var(--darkMode_genericGrey);
    --shade_F6F9FA: var(--darkMode_PanelBg);
    --drpdownBG: var(--darkMode_genericGrey);

    --headerBg: var(--darkMode_depthGrey);
    --tableHeaderShadow1: 0 1px 0 0 var(--darkMode_genericGrey);
    --shadow2: var(--shadow1);
    --badgeBg: var(--darkMode_PanelBg);
    --popupbg1: rgba(70, 81, 88, 0.8);
    --popupbg2: var(--darkMode_PanelBg);
    --popupHeaderBg: var(--darkMode_headerPatch);
    --HNPValueBgColor: var(--darkMode_genericGrey);

    --softDelete: #7a1d1d;
    --softDeleteHover: #952727;

    --updateBg: rgba(150, 180, 215, 0.2);
    --addBg: rgba(242, 252, 250, 0.12);
    --removeBg: rgba(209, 151, 151, 0.12);
    --disabled: var(--darkMode_disabled);

    --tagError: rgba(226, 71, 60, 0.4);
    --tagWarning: rgba(239, 188, 45, 0.4);
    --tagRequired: rgba(211, 115, 78, 0.4);

    /* flow  */
    --containerBg: #27363f;
    --containerBgHover: #36586a;
    --containerBorder: #1673a7;
    --abBg: #322e51;
    --abBgHover: #49427e;
    --routerBorder: #a9612c;
    --routerBg: #332921;
    --flowBg: #212116;
    --flowBgHover: #4d4e2f;
    --flowBgActive: #897f21;
    --flowBorder: #897f21;

    --toggleReadOnlyColor: var(--darkMode_disabled);

    /* treeview dark row colors */
    --tbg1: var(--darkMode_body);
    --tbg2: #161616;
    --tbg3: #1f1f1f;
    --tbg4: #2e2e2e;
    --tbg5: #3d3d3d;

    --impressionBg: var(--darkMode_genericGrey);
    --forwardBg: var(--darkMode_genericGrey);
    --dropRateBg: var(--darkMode_genericGrey);
    --statsActiveBg: #0b4666;
    --dateHighlight: var(--darkMode_placeholder);
}
