.tagContainer {
    display: inline-flex;
    height: 20px;
    padding: 2px 5px;
    align-items: center;
    font-size: var(--fontSize);
    border-radius: 4px;
    position: relative;
    white-space: nowrap;
    font-style: italic;

    &.new {
        background: #f0f7ff;
        color: #4a90e2;
        border: 1px solid #e0eeff;
    }

    &.deleted {
        background: var(--error-color) !important;
        color: #fff !important;
        text-decoration: none !important;
    }

    &.default {
        height: inherit;
        max-height: 30px;
        padding: 5px 14px 5px 10px;
        color: var(--grey_shade);
        border: 1px solid var(--borderColor3);
        border-radius: var(--radius3);
        background: var(--shade_ffffff);

        &:hover {
            background: var(--shade_f7f7f7);
        }
    }

    &.default2 {
        height: inherit;
        max-height: 30px;
        padding: 5px 10px;
        color: var(--grey_shade);
        border: 1px solid var(--borderColor4);
        border-radius: var(--radius3);
        background: var(--shade_ffffff);
        gap: 10px;
        font-style: normal;

        &:hover {
            background: var(--shade_f7f7f7);
        }

        .close {
            position: static;
            display: flex;
        }
    }

    &.brand {
        background: var(--addBg);
        color: var(--brandColor);
        border: 1px solid var(--borderColor1);
    }

    &.brandInvert {
        background: var(--brandColor);
        color: var(--shade_ffffff);
        border: 1px solid var(--borderColor1);
    }

    &.noHover {
        height: inherit;
        max-height: 30px;
        padding: 5px 14px 5px 10px;
        color: var(--grey_shade);
        border: 1px solid var(--borderColor3);
        border-radius: var(--radius3);
        background: var(--shade_ffffff);
    }

    &.keyValueContainer {
        background: transparent !important;
        padding: 0px;
        flex-wrap: wrap;
        grid-gap: 10px;

        max-height: 276px;
        overflow-y: auto;
        height: auto;

        .keyValueWrapper {
            display: flex;
            gap: 5px;
            position: relative;
            background: transparent;
            border: 1px solid var(--borderColor3);
            padding: 5px 14px;
            border-radius: var(--radius3);
            font-weight: var(--fontMedium);
            font-size: var(--fontSize);
            color: var(--grey_shade);
            max-width: 598px;
            word-break: break-all;
            background: var(--shade_ffffff);

            div:nth-child(1) {
                color: var(--grey_shade);
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: 582px;
            }

            div:nth-child(2) {
                color: var(--dark_color);
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: 582px;
            }

            &.keyValueClickable {
                &:hover {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }

            &:hover {
                background: var(--shade_f7f7f7);
            }
        }
    }

    &.deprecated {
        background: var(--tagWarning);
        color: var(--tagWarningColor);
        border: 1px solid var(--tagWarningColor);
        border-radius: 4px;
        font-size: 10px !important;
        font-weight: 400 !important;
        font-style: italic;
    }

    .close {
        position: absolute;
        right: -7px;
        top: calc(50% - 7px);
        cursor: pointer;
        display: none;

        svg {
            color: var(--grey_shade);
        }
    }

    &:hover {
        .close {
            display: inline-block;

            &:hover {
                svg {
                    color: var(--error-color);
                }
            }
        }
    }
}

[data-theme="darkMode"] {
    .tagContainer.new {
        background: var(--darkMode_genericGrey);
    }

    .tagContainer.default {
        border: 1px solid var(--darkMode_genericGrey);
        color: var(--darkMode_white);
        background: var(--darkMode_PanelBg);

        &:hover {
            background: var(--darkMode_genericGrey);
        }
    }
}
