.details {
    font-size: 12px;
    margin-left: 5px;
    color: var(--brandColor);
}

@mixin flexGrid {
    display: flex;
    gap: 70px;
}

.externalWrap {
    transform: translateY(calc(-100% - 40px));
    position: fixed;
    background: var(--popupbg2);
    border: 1px solid var(--borderColor8);
    box-shadow: 0px 32px 32px -24px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    font-weight: normal;
    z-index: 1000;
    transform: translate(-100%, -50%);

    &.show {
        display: block;
    }

    .externalHeader {
        color: var(--shade_CAD1D4);
        padding: 15px 25px;
        border-bottom: 1px solid var(--borderColor8);
        background: var(--popupHeaderBg);
        @include flexGrid;
        .externalChannel {
            max-width: 90px;
            text-align: left;
            text-transform: capitalize;
        }
    }

    .externalBody {
        padding: 10px 20px;
        max-height: 276px;
        overflow-y: auto;
        .row {
            @include flexGrid;
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }
            .name {
                color: var(--dark_color);
            }
            .value {
                color: var(--dark_color);
                max-width: 90px;
            }
        }
    }

    .externalNumber {
        max-width: 90px;
        text-align: left;
    }
}

.centreAlign {
    text-align: center !important;
}
