:global {
  .numRender__common {
    width: 100%;
    text-align: right;
    display: flex;
    justify-content: flex-end;

    .sort{
      order: -1;
      margin-right: 5px;
      display: inline-flex;
      align-items: center;
    }
  }
}