:global {
    .draggableSelectedList {
        position: relative;
        display: flex;
        align-items: center;
        padding-left: 20px;
        justify-content: space-between;
        padding-right: 20px;
        height: 46px;
        list-style: none;
        &.active,
        &:hover {
            background: var(--shade_FAFCFC);
            cursor: pointer;
        }

        span {
            font-size: var(--fontSize);
            color: var(--dark_color);
            font-weight: var(--fontMedium);
            &.dragHandleIcon {
                margin-right: 10px;
            }
            &.icon {
                color: var(--iconColor);
                &:hover {
                    color: var(--grey_shade);
                }
            }
            &:last-child {
                width: 20px;
                display: flex;
            }
            &.dataBoxListTitle {
                flex: 1 0 auto;
                width: calc(100% - 60px);
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                margin-right: 10px;
            }
        }
        .warning.icon {
            display: flex;
            margin-right: 5px;
        }
    }

    body > li.draggableSelectedList {
        background: var(--shade_FAFCFC);
        .dragHandleIcon,
        .icon {
            color: var(--dark_color) !important;
        }
    }

    [data-theme="darkMode"] {
        .draggableSelectedList span.icon:hover {
            color: var(--darkMode_white);
        }
    }
}

.selectListContainer {
    width: 100%;
    height: 100%;
    border-radius: 3px;
    background-color: var(--shade_ffffff);
    position: relative;
    font-weight: normal;

    .searchContainer {
        width: 100%;
        font-size: 12px;
        padding: 5px 5px 5px 0;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        padding-top: 20px;

        .inputWrapper {
            position: relative;
            width: 100%;
            .inputBox {
                min-width: 230px;
            }
            input {
                height: 100%;
                height: 48px;
                border-color: var(--borderColor1);
                box-sizing: border-box;
                line-height: 30px;
                padding-left: 48px;
                padding-right: 15px;
                background-color: var(--shade_FAFCFC);
                font-size: var(--fontSize);
            }
            .icon {
                top: 35%;
                position: absolute;
                width: 15px;
                height: 15px;
                display: block;
                z-index: 1;
                &.clearSearch {
                    right: 15px;
                    cursor: pointer;
                    &:hover svg {
                        color: var(--dark_color) !important;
                    }
                }
            }
            &.clearSearchInput {
                input {
                    padding-right: 44px;
                }
            }
        }
    }
    .dropdownWrapper {
        width: 100%;
        align-items: stretch;
        height: calc(100% - 69px);
        justify-content: space-between;

        .dropdownList {
            max-width: 350px;
            width: 100%;
            // padding-left: 20px;
            // padding-top: 10px;
            // border: 1px solid #eaecf0;
            border-left: 0px;
            border-bottom: 0px;
            // &:nth-child(2) {
            //   border-left: 1px solid var(--shade_E5EFEF);
            //   padding-top: 0px;
            // }

            // &:nth-child(1) {
            //   border-right: 1px solid var(--shade_E5EFEF);
            // }

            &.checkedList {
                max-width: 230px;
                // padding-left: 0px;
                .dataBox {
                    // padding: 0px 0px 20px 0px;
                }
            }

            .dropdownListTitle {
                align-items: center;
                justify-content: space-between;
                padding-top: 20px;
                padding-bottom: 20px;
                .selectedNumber {
                    font-size: var(--fontSize);
                    color: var(--grey_shade);
                    font-weight: var(--fontMedium);
                    // margin-left: 10px;
                }
                .resetText {
                    font-size: var(--fontSize);
                    color: var(--brandColor);
                    font-weight: var(--fontMedium);
                    // margin-left: 92px;
                    cursor: pointer;
                    &.textDisabled {
                        pointer-events: none;
                        opacity: 0.6;
                    }
                }
            }

            .dataBox {
                height: calc(100% - 58px);
                padding: 10px 20px 20px 20px;
                border: 1px solid var(--borderColor1);
                border-radius: 8px;
                // min-height: 423px;
                padding: 0px;
                &.quesBankDataBox {
                    max-height: 474px;
                    // max-height: 424px;
                    overflow-y: auto;
                    padding-bottom: 0px;
                }
            }
        }
    }

    &.dragable {
        // .searchContainer {
        //   padding: 0px 0px 10px 0px;
        // }
        // .dropdownWrapper {
        //   height: 100%;
        //   .dropdownList {
        //     .dataBox {
        //       height: calc(100% - 58px);
        //       border-right: 0px;
        //       border-top-right-radius: 0px;
        //       border-bottom-right-radius: 0px;
        //     }
        //     &.checkedList {
        //       .dropdownListTitle {
        //         padding-left: 20px;
        //         padding-right: 20px;
        //         padding-top: 15px;
        //         padding-bottom: 25px;
        //       }
        //       .dataBox {
        //         // border-left: 0px;
        //         border-top-left-radius: 0px;
        //         border-bottom-left-radius: 0px;
        //         padding-top: 0px;
        //         .selected_List {
        //           justify-content: flex-start;
        //         }
        //       }
        //     }
        //   }
        // }

        .searchWrapper {
            border: 1px solid var(--borderColor1);
            border-width: 1px 0px 0px 1px;
            border-top-left-radius: 8px;
        }
        .searchWrapper + .dataBox {
            border-bottom-left-radius: 8px !important;
        }
        .dropdownWrapper {
            height: 100%;
            // height: calc(100% - 11px);
            .dropdownList {
                width: 50%;
                max-width: 100%;
                .dataBox {
                    // border:0px;
                    height: calc(100% - 69px);
                    // height: calc(100% - 58px);
                    border-right: 0px;
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px;
                }
                .dropdownListTitle {
                    border: 1px solid var(--borderColor1);
                    border-width: 1px 0px 0px 0px;
                    min-height: 69px;
                    padding: 0px 20px;
                }
                .dataBox {
                    // border-left: 0px;
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                    padding-top: 0px;
                    .selected_List {
                        justify-content: space-between;
                        list-style-type: none;
                    }
                }
                &.checkedList {
                    .dropdownListTitle {
                        padding-left: 20px;
                        padding-right: 20px;
                        padding-top: 25px;
                        padding-bottom: 25px;
                    }
                    .dataBox {
                        // border-left: 0px;
                        border-top-left-radius: 0px;
                        border-bottom-left-radius: 0px;
                        padding-top: 0px;
                        .selected_List {
                            justify-content: flex-start;
                            list-style-type: none;
                            &:hover {
                                background: var(--shade_FAFCFC);
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
        .inputWrapper {
            .inputBoxWrapper {
                border-radius: 12px;
                input {
                    border-radius: 12px;
                }
            }
        }
        .selectListContainer {
            max-width: 100%;
            width: 100%;
        }
        .searchContainer {
            padding: 0px 10px;
            min-height: 68px;
        }
    }

    &.featMapListLayer {
        .inputWrapper {
            .inputBoxWrapper {
                border-radius: 12px;
                input {
                    border: 1px solid var(--borderColor1);
                    border-radius: 12px;
                }
            }
        }
        .selectListContainer {
            max-width: 100%;
            width: 100%;
        }
        .searchContainer {
            padding: 0px 10px;
            min-height: 68px;
        }
        .searchWrapper {
            border: 1px solid var(--borderColor1);
            border-width: 1px 0px 0px 1px;
            border-top-left-radius: 8px;
        }
        .searchWrapper + .dataBox {
            border-bottom-left-radius: 8px !important;
        }
        .dropdownWrapper {
            height: 100%;
            // height: calc(100% - 11px);
            .dropdownList {
                width: 50%;
                max-width: 100%;
                .dataBox {
                    // border:0px;
                    height: calc(100% - 69px);
                    // height: calc(100% - 58px);
                    border-right: 0px;
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px;
                }
                .dropdownListTitle {
                    border: 1px solid var(--borderColor1);
                    border-width: 1px 0px 0px 0px;
                    min-height: 69px;
                    padding: 0px 20px;
                }
                .dataBox {
                    // border-left: 0px;
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                    padding-top: 0px;
                    min-height: 437px;
                    .selected_List {
                        justify-content: space-between;
                        &:hover {
                            background: var(--shade_FAFCFC);
                            cursor: pointer;
                        }
                        span {
                            &.warning {
                                // position: absolute;
                                // right: 45px;
                                width: 20px;
                            }
                        }
                    }
                }
            }
        }
    }

    &.featSet {
        .dropdownWrapper .dropdownList {
            width: 100%;
            .dataBox {
                min-height: 320px;
                position: relative;

                .subhead {
                    height: 38px;
                    padding: 0 10px 0 20px;
                    border-bottom: 1px solid #eaf0f3;
                    position: relative;

                    .selectedCount {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 18px;
                        color: var(--dark_color);
                    }

                    .add {
                        color: var(--brandColor);
                        padding: 5px;
                        height: 28px;
                        border-radius: 8px;
                        transition: 400ms all ease;
                        cursor: pointer;
                        &:hover {
                            background: #e3f6f2;
                        }
                    }
                }
                .customSelect {
                    position: absolute;
                    top: 38px;
                    left: 10px;
                    width: 400px;
                    z-index: 1;
                    height: 252px;
                    background: #fff;
                    border: 1px solid var(--borderColor1);
                    box-shadow: 0px 4px 3px rgba(229, 239, 239, 0.46);
                    border-radius: 8px;

                    // > div input + div {
                    //   display: none;

                    //   + div > div{
                    //     top: 0 !important;
                    //     width: 100% !important;
                    //     border: none;
                    //     box-shadow: none;

                    //     > div:first-child{
                    //       margin: 10px;
                    //       border: 1px solid #EAF0F3;
                    //       width: calc(100% - 20px);
                    //       box-sizing: border-box;
                    //       border-radius: 8px;
                    //     }
                    //   }

                    // }

                    .btnSet {
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        border-top: 2px solid #f3f9f9;
                        padding: 10px;
                        display: flex;
                        justify-content: flex-end;
                        gap: 20px;
                    }
                }
            }
        }
    }

    &.filterSearchHeight {
        .dataBox {
            // min-height: 424px;
        }
    }
}
