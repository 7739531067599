.pageWrap {
    height: calc(100vh - 210px);
    max-height: 100%;
    background: var(--shade_ffffff);
    width: calc(100% - 30px);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    position: relative;
    z-index: 1;

    &.fullHeight {
        min-height: calc(100vh - 90px);
        box-shadow: 0px 4px 20px rgb(0 0 0 / 4%);
    }
    .img_wrap {
        img {
            height: auto;
            max-width: 300px;
        }
    }
    .txt {
        margin: 20px auto 55px;
        font-weight: 500;
        font-size: 14px;
        color: #a8aeb1;
        text-align: center;
        p {
            font-size: var(--fontSizeRegular);
            font-weight: var(--fontBold);
            color: var(--shade_74838F);
            margin-bottom: 10px;
        }
        span {
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }
    }

    .goBackBtn {
        margin-top: 30px;
    }
}

@media (min-height: 670px) {
    .pageWrap {
        .img_wrap {
            img {
                max-width: 500px;
            }
        }
        .txt {
            margin: 20px auto 55px;
        }
    }
}
