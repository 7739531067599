.accordianAddTableWrapper {
    padding: 0;
    border: 1px solid var(--borderColor1);
    max-width: 100%;
    //   width: 1000px;
    width: 100%;
    .header {
        display: flex;
        padding: 10px 20px;
        background-color: var(--headerBg);
        border-radius: var(--radius2) var(--radius2) 0 0;
        border-bottom: 1px solid var(--borderColor1);
        .subHeader {
            color: var(--grey_shade);
            font-size: var(--fontSize);
            text-transform: capitalize;
            &:nth-child(1),
            &:nth-child(2) {
                max-width: 140px;
                width: 100%;
            }
            &:nth-child(1) {
                margin-right: 20px;
            }
            &:nth-child(3),
            &:nth-child(4) {
                max-width: 250px;
                width: 100%;
            }
            margin-right: 30px;
        }
    }
    .body {
        display: flex;
        padding: 20px;
        position: relative;
        background: var(--shade_ffffff);
        .input {
            max-width: 140px;
            width: 100%;
            height: 38px;
            margin-right: 30px;
            &:first-child {
                margin-right: 20px;
            }
            input[type="text"] {
                height: 100%;
                padding: 10px;
                height: 38px;
            }
        }
        button {
            margin-left: auto;
        }
    }
    .customSelectContainerLayer,
    .secondCustomSelectContainerLayer {
        max-width: 250px;
        width: 100%;
        margin-bottom: 0px;
        margin-right: 30px;
    }
    .secondCustomSelectContainerLayer {
        [class*="dropdown"]:not([class*="dropdownList"]) {
            top: -240px !important;
        }
    }
}
.innerHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    .noDataContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        width: 100%;
        .noDataGraphicsContainer {
            display: block;
            img {
                height: 150px;
                width: 150px;
            }
            div {
                margin: 20px 0;
                color: var(--textColor2);
                font-weight: 500;
            }
        }
    }
    .noDataGraphicsContainer {
        display: none;
    }
}

.inlineTooltip {
    [data-id="tooltip"] {
        display: inline-block;
    }
}

.offerCardContainer {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    @media screen and (max-width: 1600px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
    @media screen and (max-width: 1300px) {
        grid-template-columns: 1fr 1fr;
    }
    .offerCardItem {
        border-radius: 8px;
        padding: 20px;
        border: 1px solid var(--borderColor1);
        font-size: var(--fontSize);
        color: var(--textColor2);
        background: var(--panelBg);
        min-height: 300px;
        overflow: hidden;
        position: relative;
        cursor: pointer;

        &.deleted {
            background: var(--softDelete);
            .value {
                text-decoration: line-through;
            }
        }

        &:hover {
            &.deleted {
                background: var(--softDeleteHover);
            }
            .offerCardActionBar {
                display: flex;
            }
        }
        .offerCardActionBar {
            position: absolute;
            top: 0;
            right: 0;
            display: none;
            .dragIcon {
                margin-left: 10px;
            }
            :global {
                .editIconWrapper {
                    padding: 0;
                }
            }
        }
        &.addItem {
            border: 1px dashed var(--borderColor1);
            cursor: pointer;
            transition: all 200ms;
            &:hover {
                background: #f5fafa;
                color: var(--brandColor);
                border-color: var(--brandColor);
            }
        }
        .row {
            margin-bottom: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &:first-child {
                position: relative;
                border-bottom: 1px solid var(--borderColor1);
                padding-bottom: 10px;
            }
            label {
                color: var(--textColor2);
                text-transform: capitalize;
            }
            .value {
                color: var(--textColor1);
                font-family: var(--fontMedium);
            }
        }
    }
}
.saveBtnWrapper {
    display: flex;
    position: absolute;
    right: 20px;
    bottom: 20px;
    button:nth-child(1) {
        margin-right: 20px;
    }
}
.editContainer {
    .body {
        min-height: calc(100vh - 176px);
    }
}

.footer {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0 20px 0;
    position: sticky;
    bottom: 0;
    transform: translateY(20px);
    right: 0;
    z-index: 1;
    border-top: 1px solid var(--borderColor1);
    background-color: var(--panelBg);
    button {
        margin-left: 20px;
    }
}

.dealIdDropdownWrapper {
    .dealNameLabel {
        display: flex;
        align-items: center;
    }
    .multiValue {
        margin-top: 5px;
        font-size: 14px;
        color: var(--textColor2);
        display: flex;
        align-items: center;
    }
}

.labelDealId {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--inputBg);
    padding: 11px;
    border-radius: var(--radius2);
    width: 100%;
    max-width: var(--maxChildContainer);
    border: 1px solid var(--borderColor1);
    position: relative;
    overflow: hidden;
    cursor: not-allowed;
    .loader {
        position: absolute;
        top: auto;
        bottom: 0;
    }
    &.placeholder {
        color: var(--textColor3);
    }
}

.creativeWrap {
    border: 1px solid var(--borderColor4);
    border-radius: var(--radius2);
    background: var(--headerBg);
}

.keyHeading {
    color: var(--dark_color);
    font-weight: var(--fontMedium);
    font-size: var(--fontSizeRegular);
    padding-top: 10px;
    margin-bottom: 20px;
}

.keyValWrap {
    border: 1px var(--borderColor4) solid;
    border-radius: var(--radius2);
    position: relative;
    .deleteIcon {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
        visibility: hidden;
    }
    &:hover {
        .deleteIcon {
            visibility: visible;
        }
    }
}

.dropdownWrap {
    position: absolute;
    bottom: -10px;
    width: 300px;
    z-index: 2;
}

.deletePopup {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
}

[data-theme="darkMode"] {
    .offerCardContainer {
        .offerCardItem {
            .row {
                .value {
                    color: var(--dark_color);
                }
            }
        }
    }
}
