
.accordionTable{
    overflow-y: auto;
    max-height: 620px;
    max-width: calc(100vw - 104px);
    position: sticky;
    left: 20px;
    padding: 20px 0 0 0;
    background: var(--panelBg);
    border: 1px solid var(--borderColor4);
    box-shadow: 0px 4px 30px -5px rgba(0, 0, 0, 0.1);
    border-radius: var(--radius2);
    margin: 20px 0;
    .tableContainer{
        height: 500px;
    }
}

.confirmPopupContainer{
    position: relative;
    .confirmPopup{
        position: absolute;   
        right: 60px;
        top: -30px;
    }
}