.multiplierFormContainer {
    :global {
        .confimBoxBody {
            padding: 0 !important;
        }
        button {
            margin-bottom: 20px;
            &:nth-child(2) {
                margin-right: 20px !important;
            }
        }
    }
    .formula {
        padding: 10px;
        background: var(--shade_FAFCFC);
    }
    .multiplierForm {
        padding: 20px;
        table {
            tr {
                td {
                    vertical-align: middle;
                    padding-right: 20px;
                    white-space: nowrap;
                    color: var(--textColor2);
                    &:last-child {
                        padding-right: 0;
                    }
                }
            }
        }
    }
}
