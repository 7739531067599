.PopupTargettingParams_wrapper {
    max-width: 332px;
    width: 100%;
    padding: 20px;
    background: var(--shade_ffffff);
    border: 1px solid var(--borderColor1);
    box-shadow: 0px 4px 3px rgba(229, 239, 239, 0.46);
    border-radius: var(--radius2);

    .textWrapper {
        font-size: var(--fontSize);
        color: var(--grey_shade);
        span {
            color: var(--dark_color);
        }
    }
    .popupBtnWrapper {
        justify-content: flex-end;
        margin-top: 20px;
        button {
            padding: 5px 10px;
        }
        .button:not(:last-child) {
            margin-right: 20px;
        }
    }
}

[data-theme="darkMode"] {
    .PopupTargettingParams_wrapper {
        box-shadow: 0px 4px 3px rgba(229, 239, 239, 0.1);
    }
}
