.wrapper {
    max-width: 236px;
    width: 100%;
    background: var(--shade_ffffff);
    border-radius: var(--radius1);
    position: fixed;
    // top: 159px;
    // height: calc(100% - 180px);
    top: 110px;
    // height: 100%;
    height: Calc(100vh - 113px);
    transition: all var(--transitionSpeed1) ease-out;

    @media screen and (max-width: 1900px) {
        transform: translateX(calc(-100% - 30px));
    }

    &.show {
        transform: none;
    }

    .panelBox {
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;

        .section {
            padding: 20px 10px 20px 20px;
            border-bottom: 1px solid var(--borderColor1);
            .title {
                font-size: var(--fontSizeRegular);
                margin-bottom: 23px;
                font-weight: var(--fontBold);
            }
            .parent {
                display: flex;
                align-items: center;
                font-weight: var(--fontBold);
                font-size: var(--fontSize);
                color: var(--grey_shade);
                .icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 15px;
                    &.error {
                        color: var(--error);
                    }
                    &:hover {
                        color: var(--brandHoverColor);
                    }
                }
                &.selected {
                    color: var(--brandColor);
                }
            }
            .children {
                display: flex;
                align-items: center;
                margin-top: 20px;
                font-weight: var(--fontMedium);
                font-size: var(--fontSizeSmall);
                color: var(--grey_shade);
                cursor: pointer;
                margin-left: 33px;
                position: relative;
                .icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 5px;
                }
                &::before {
                    content: "";
                    position: absolute;
                    border-top: 1px dashed var(--grey_shade);
                    width: 20px;
                    left: -24px;
                }
                &::after {
                    content: "";
                    position: absolute;
                    border-right: 1px dashed var(--grey_shade);
                    width: 1px;
                    height: calc(100% + 20px);
                    bottom: 50%;
                    left: -24px;
                }
                .error {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    left: -19px;
                    color: var(--error);
                    width: 15px;
                    height: 15px;
                    line-height: 15px;
                    &:hover {
                        color: var(--errorHover);
                    }
                }
                &.errorChild {
                    &::before {
                        width: 5px;
                    }
                }
                &:hover,
                &.selected {
                    color: var(--brandColor);
                    &::after,
                    &::before {
                        border-color: var(--brandColor) !important;
                    }
                }
            }
            .parent + .children {
                margin-top: 35px;
                &::after {
                    height: calc(50% + 30px);
                }
            }
            &:last-child {
                border: none;
            }
            &.active {
                .children {
                    &::after {
                        border-color: var(--brandColor);
                    }
                }
                .children.selected ~ .children,
                .children.selected ~ .nestedChildWrap .children {
                    &::after {
                        border-color: var(--grey_shade);
                    }
                }
            }
            .nestedChildWrap {
                flex-direction: column;
                align-items: flex-start;
                &:last-child {
                    &::after {
                        bottom: 86px;
                        height: 36px;
                    }
                }
                &::before {
                    margin-top: 7px;
                }
                &::after {
                    bottom: 10px;
                }
            }
            .nestedChild:first-child {
                &:after {
                    height: calc(100% + 10px);
                }
            }
        }
    }

    @mixin sideMenuLink {
        padding: 12px 24px;
        gap: 14px;
        color: var(--grey_shade);
        cursor: pointer;
        &:hover {
            color: var(--brandColor);
        }
    }

    .homeBtn {
        @include sideMenuLink;
    }

    &.userAccessControl {
        background: none;
        height: auto;
        top: 20px;
        // top: 65px;
        z-index: 1;
        .panelBox {
            .section {
                padding: 20px;
                border-bottom: 1px solid var(--borderColor5);

                .parent.selected .text {
                    color: var(--dark_color);
                }
                .children {
                    margin-top: 20px;
                    font-weight: normal;
                    font-size: var(--fontSize);
                    &:before,
                    &:after {
                        display: none;
                    }
                }
            }
            .logout {
                @include sideMenuLink;
                padding: 24px;
            }
        }
    }

    &.noNestingNav {
        .panelBox .section {
            border: none;
            .parent {
                font-weight: 500;
                cursor: pointer;
            }

            &.active {
                .parent,
                .icon svg {
                    color: var(--brandColor);
                }
            }
        }
    }

    .skeletonCard {
        padding: 20px;
        &.skeletonCardLeftPanel {
            height: 100%;
            div {
                margin-bottom: 15px;
                &:nth-child(1) {
                    // width:200px;
                    margin-bottom: 20px;
                }
            }
        }
    }
}
.wrpperBox {
    .panelBox {
        border: 0px;
        .section:hover {
            background: var(--rowHoverBg);
            .text {
                color: var(--dark_color);
            }
            svg {
                color: var(--brandColor);
            }
        }
    }
}
