.typeLabel {
    color: var(--dark_color) !important;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 18px;
}

.typeDisabled {
    color: var(--grey_shade) !important;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 18px;
}

.heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 638px;
}

.editLink {
    color: var(--grey_shade);
    text-decoration: underline;
    text-decoration-color: var(--grey_shade);
    display: flex;
    align-items: center;
    margin-right: 0px;
    &.enableLink {
        cursor: pointer;
        &:hover {
            color: var(--brandColor);
            text-decoration-color: var(--brandColor);
        }
    }
    &.disabledLink {
        cursor: not-allowed;
    }
}

.adUnitWrapper {
    color: var(--dark_color);
}

.gratificationOfferTable {
    width: 638px;
}
