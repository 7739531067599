.side-panel-header {
    display: flex;
    justify-content: space-between;
    line-height: 64px;
    border-bottom: 1px solid var(--borderColor4);
    padding: 0 20px;
    font-style: normal;
    font-weight: var(--fontMedium);
    font-size: var(--fontSizeMedium);
    align-items: center;
    color: var(--dark_color);
}
.side-panel-close,
.side-panel-navigate,
.side-panel-history {
    cursor: pointer;
    svg {
        color: var(--grey_shade);
    }

    &:hover {
        svg {
            color: var(--dark_color);
        }
    }
}

.loading {
    pointer-events: none;
    cursor: not-allowed;
}

.side-panel-content {
    padding: 20px;
}

.allowOverflow {
    overflow-y: unset !important;
    height: calc(100% - 146px);
}

.loader {
    position: absolute;
    top: 65px;
}

.toolTipAlignWithSaveBtn{
    width: 200px;
    div{
        margin-right: 20px;
        word-break: inherit;
    }
}

.side-panel-footer {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    border-top: 1px solid var(--borderColor4);
    gap: 20px;
}
