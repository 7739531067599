.descContainer {
    display: flex;
    align-items: center;
}
.resetMargin {
    margin-bottom: 0;
    margin-right: 5px;
}
.subTitle {
    color: var(--grey_shade);
    font-size: var(--fontSize);
    font-weight: var(--fontMedium);
    line-height: var(--lineHeight);
    margin-bottom: 10px;
}



.multiplierFormContainer{
    :global{
        .confimBoxBody{
            padding: 0!important;
        }
        button{
            margin-bottom: 20px;
            &:nth-child(2){
                margin-right: 20px!important;
            }
        }
    }
    .formula{
        padding: 10px;
        background: var(--shade_FAFCFC);
    }
    .multiplierForm{
        padding: 20px;
        table{
            tr{
                td{
                    vertical-align: middle;
                    padding-right: 20px;
                    white-space: nowrap;
                    color: var(--textColor2);
                    &:last-child{
                        padding-right: 0;
                    }
                }
            }
        }
    }
}

.inlineRow{
    display: flex;
    margin-right: 20px;

}

.formulaDisplay{
    display: inline-block;
    vertical-align: top;
    line-height: var(--lineHeight);
}

.offerTable {
    padding: 0;
    border: 1px solid var(--borderColor1);
    max-width: 490px;
    transition: max-width 0.6s;
    &.maxWidth {
        max-width: 800px;
        transition: max-width 0.6s;
    }
    .header,
    .row {
        display: flex;
        gap: 20px;
    }
    .row > * {
        height: 42px;
    }
    .col {
        flex: 1;
        &.offerSelect {
            flex: 1.5;
        }
        &.addBtn {
            flex: 0.4;
        }
    }
    .header {
        padding: 10px 20px;
        background-color: var(--headerBg);
        border-radius: var(--radius2) var(--radius2) 0 0;
        border-bottom: 1px solid var(--borderColor1);
        color: var(--grey_shade);
        font-size: var(--fontSize);
        white-space: nowrap;
    }
    .row {
        padding: 20px;
        position: relative;
        background: var(--shade_ffffff);
    }
    .select {
        width: 145px;
    }
    .offerSelect {
        min-width: 170px;
        width: 170px;
    }
}

.collapseBtnWarpper {
    display: inline-flex;
    align-items: center;
    svg {
        margin-right: 5px;
        position: relative;
        top: 0px;
        width: 18px;
        height: 18px;
    }
    span {
        width: 65px;
    }
}

.saveBtnWrapper {
    display: flex;
    position: absolute;
    right: 20px;
    bottom: 20px;
    button:nth-child(1) {
        margin-right: 20px;
    }
}
