.InputWithBtnWrapper {
    .inputWidth {
        width: 100%;
    }

    .toggleSwitchWrapper {
        display: flex;
        input {
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
        }
        form {
            .toggleValueSwitchContainer {
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
                white-space: nowrap;
                label {
                    width: 59px;
                }
                span {
                    width: 59px;
                }
            }
        }
    }

    .inputImmediateWrapper {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        input {
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
        }
    }

    button {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        min-height: 40px;
    }

    button.btnWithIcon {
        background-color: var(--btnBg);
        border: 1px solid var(--brandColor);
        svg {
            color: var(--brandColor);
        }
        &:hover {
            background-color: var(--brandColor);
            // border: 1px solid var(--shade_ffffff);
            svg {
                color: var(--shade_ffffff);
            }
        }
    }

    .loadingInput {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 11px;
        border-radius: var(--radius2);
        width: 100%;
        max-width: var(--maxChildContainer);
        border: 1px solid var(--borderColor1);
        position: relative;
        overflow: hidden;
        height: 42px;
        background: var(--badgeBg);
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        cursor: not-allowed;
        .loader {
            position: absolute;
            top: auto;
            bottom: 0;
        }
        &.placeholder {
            color: var(--textColor3);
        }
    }
    .pillsWrapper {
        display: inline-flex;
        align-items: center;
        margin-top: 20px;
        flex-wrap: wrap;
        .pills {
            display: inline-flex;
            line-height: 18px;
            margin-bottom: 10px;
            margin-right: 10px;
            position: relative;
        }
    }
}
