.popupBox {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;

    .header {
        height: 64px;
        border-bottom: 1px solid var(--borderColor4);
        font-size: 20px;
        color: var(--dark_color);
        font-weight: var(--fontMedium);
        padding: 0 20px;
        .closeIcon {
            cursor: pointer;
            i {
                display: flex;
            }
        }
        .subTitle {
            color: var(--grey_shade);
        }
    }

    .content {
        max-height: calc(100% - 145px);
        h1 {
            margin-bottom: 15px;
        }
    }
    .contentNormal {
        max-height: 50vh;
        overflow: auto;
        h1 {
            margin-bottom: 15px;
        }
    }

    .contentOverflow {
        overflow: auto;
    }

    .footer {
        border-top: 1px solid var(--borderColor4);
    }

    .dependencyDetailsWrapper {
        border: 1px solid var(--borderColor1);
        border-radius: var(--radius2);
        min-height: 100px;
        position: relative;
        max-height: 50vh;
        overflow: auto;
    }

    .dependencyTableWrap {
        background: var(--badgeBg);
        padding: 20px;
        .dependencyTable {
            border-radius: var(--radius2);
            background: var(--shade_ffffff);
            max-height: 300px;
            overflow: auto;
            transition: 300ms all ease;
            & > div {
                overflow: hidden;
                overflow-x: auto;
            }

            &.hide {
                max-height: 0;
                padding: 0;
            }

            &.topBorder {
                border-top: 1px solid var(--borderColor4);
            }
        }
        .noTransition {
            transition: none;
        }
    }

    .dependencyInfo {
        color: var(--grey_shade);
    }

    .dependencyKey {
        color: var(--dark_color);
    }

    .dependencyDataWrapper {
        color: var(--grey_shade);
        height: auto;
        :global {
            .dependencyTag {
                svg {
                    color: var(--brandColor);
                }
            }
        }
        .publishWrapper {
            border: 1px solid var(--disabled);
            border-radius: var(--radius1);
            background-color: var(--badgeBg);
            height: 100%;
            overflow: auto;
        }

        .noChangesWrapper {
            border: none;
            display: flex;
            background-color: transparent;
            border-radius: 0px;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .noChanges {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                img {
                    width: 250px;
                    height: 250px;
                }
                .selectonText {
                    font-weight: 500;
                    font-size: 16px;
                    margin-top: 30px;
                    margin-bottom: 10px;
                    color: var(--grey_shade);
                }
            }
        }
    }

    .flowContainer {
        border: 1px solid var(--borderColor4);
        border-radius: var(--radius1);
        height: calc(100% - 86px);
        .flowWrap {
            border-radius: var(--radius1) var(--radius1) 0 0;
            position: relative;
            height: 100%;
            overflow: hidden;
        }
    }
}

.dependencyItem {
    background: var(--shade_ffffff);
    border: 1px solid var(--borderColor4);
    box-shadow: var(--shadow1);
    border-radius: var(--radius2);
    margin-bottom: 20px;
    overflow: hidden;
    font-size: var(--fontSize);
    font-weight: var(--fontRegular);

    .typeValue {
        color: var(--dark_color);
    }

    .btn {
        cursor: pointer;
        &:hover {
            color: var(--brandColor);
        }
    }

    .details {
        transition: var(--transition3);
        margin-bottom: 20px;
    }

    .btnViewDetails {
        cursor: pointer;
        margin-right: 22px;
        position: relative;

        &::after {
            content: "\e181";
            font-family: "icomoon";
            position: absolute;
            right: -20px;
            top: calc(50% - 6px);
            width: 14px;
            height: 8px;
            // Styling For Icon
            font-family: "icomoon" !important;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            color: var(--grey_shade);
            transition: var(--transition1);
        }

        &.open {
            color: var(--dark_color);
            &:hover {
                color: var(--brandColor);
            }
            &::after {
                transform: rotate(180deg);
                transition: var(--transition1);
                right: -20px;
                top: calc(50%);
                color: var(--dark_color);
                &:hover {
                    color: var(--brandColor);
                }
            }
            &:hover:after {
                color: var(--brandColor);
            }
        }
        &:hover {
            color: var(--brandColor) !important;
        }
        &:hover:after {
            color: var(--brandColor) !important;
        }
    }

    .buttonGroupHeader {
        display: flex;
        justify-content: flex-start;
        .downloadButton {
            margin-right: 20px;
            svg {
                fill: var(--shade_74838F) !important;
                cursor: pointer;
            }
        }
    }
    .buttonGroup {
        display: flex;
        padding: 0px 10px 0px 0px;

        .disable {
            pointer-events: none;
            color: var(--infoText);
            cursor: not-allowed;
        }
    }
    .tab {
        cursor: pointer;
        opacity: 0.6;
        border: 0;
        outline: 0;
        margin-right: 30px;
        padding-bottom: 10px;
        text-transform: capitalize;
        display: flex;
        align-items: center;
        font-size: 16px;
        color: var(--grey_shade);
        margin-bottom: 2px;

        &.active {
            border-bottom: 2px solid var(--brandColor);
            opacity: 1;
            color: var(--brandColor);
            font-size: 16px;
            margin-bottom: 0px;
        }
    }
    .onlyDifference {
        background-color: var(--badgeBg);
        padding: 10px 0px;
        .onlyDifferenceTab {
            margin: 10px 19px;
            padding: 20px;
            background: var(--shade_ffffff);
            border-radius: var(--radius2);
        }
    }
}

.multiStepForm {
    max-width: 30%;
    margin: 0 auto;
    .formStep {
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 20px;
    }
    ul {
        counter-reset: section;
        padding: 20px 60px;
        .formStepperCircle {
            position: relative;
            span {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateY(-50%) translateX(-50%);
            }
        }
        li {
            a {
                display: flex;
                flex-direction: column;
                align-items: center;
                max-width: 50px;
                .formStepperCircle {
                    display: inline-block;
                    width: 40px;
                    height: 40px;
                    margin-right: 0;
                    text-align: center;
                    background: rgba(0, 0, 0, 0.38);
                    border-radius: 50%;
                }
            }
            .label {
                width: 156px;
                text-align: center;
                margin-top: 10px;
                font-size: 16px;
                font-weight: 500;
                line-height: 20px;
            }
        }
        .formStepperActive {
            .formStepperCircle {
                background-color: var(--btnBg);
                border: 1px solid var(--brandColor);
                color: var(--brandColor);
                box-shadow: 0 -1px 8px -1px rgba(66, 71, 85, 0.25);
            }
            .label {
                color: var(--brandColor);
            }
            span.textMuted {
                color: var(--grey_shade);
            }
        }
        .formStepperUnfinished {
            .formStepperCircle {
                background-color: #f8f7ff;
                background-color: var((--btnBg));
                border: 1px solid var(--grey_shade);
                color: var(--grey_shade);
            }
            .label {
                color: var(--grey_shade);
            }
        }
        .formStepperCompleted {
            .formStepperCircle {
                background-color: var(--brandColor);
                color: var(--btnBg);
            }
            .label {
                color: var(--brandColor);
            }
            span.textMuted {
                color: var(--btnBg);
            }
        }
    }
    .formStepperHorizontal {
        position: relative;
        display: flex;
        justify-content: space-between;
        li {
            position: relative;
            display: flex;
            flex: 1;
            align-items: start;
            transition: var(--transition3);
            &:not(:last-child):after {
                position: relative;
                flex: 1;
                height: 3px;
                content: "";
                top: 22%;
            }
            &:after {
                background-color: var(--disabled);
            }
            &.formStepperCompleted:after {
                background-color: var(--brandColor);
            }
            &:last-child {
                flex: unset;
            }
        }
    }
    .formStepperHorizontal > li:not(:last-of-type) {
        margin-bottom: 0 !important;
    }
}

.defaultMsgPopup {
    width: 100%;
    border-radius: 0px;
    border: none !important;
    border-top-left-radius: 11px;
    border-top-right-radius: 11px;
    z-index: 99;
    svg {
        color: var(--updateText);
    }
}

.popupTableWrap {
    max-height: 200px;
    overflow: auto;
}

@keyframes appear {
    0% {
        transform: scale(0);
    }
}

@keyframes disappear {
    100% {
        transform: scale(0);
    }
}

[data-theme="darkMode"] {
    .popupBox {
        box-shadow: 0px 32px 32px -24px #000 !important;
        .dependencyTable {
            background: var(--darkMode_headerPatch);
        }
    }
    .formStepperActive {
        .formStepperCircle {
            box-shadow: var(--shadow3);
        }
    }
}
