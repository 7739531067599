// @import "mixins";
// @import "react-datepicker/dist/react-datepicker.css";
@import url("https://cdnjs.cloudflare.com/ajax/libs/react-datepicker/2.8.0/react-datepicker.min.css");

html,
body {
    width: 100%;
    //height: 100%;
    font-family: var(--font);
}
:global(#root) {
    //height: 100%;
}

*:focus {
    outline: none;
    // border: 1px solid var(--borderColor2) !important;
    // box-shadow: var(--inputShadow);
}

/****** Elad Shechter's RESET *******/
/*** box sizing border-box for all elements ***/
*,
*::before,
*::after {
    box-sizing: border-box;
}

a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}

button {
    background-color: transparent;
    color: inherit;
    border-width: 0;
    padding: 0;
    cursor: pointer;
}

figure {
    margin: 0;
}

input::-moz-focus-inner {
    border: 0;
    padding: 0;
    margin: 0;
}

/*
 *  scrollbar
 */

*::-webkit-scrollbar-track {
    border-radius: 10px;
}

*::-webkit-scrollbar {
    width: 3px;
    height: 5px;
}

*::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: var(--shade_CAD1D4);
}

/*
 *  Placeholder
 */
*::-webkit-input-placeholder {
    /* Edge */
    color: #c5c5c5;
}

*::placeholder {
    color: #c5c5c5;
}
svg:not(:root) {
    overflow: visible;
}

input::-ms-clear {
    display: none;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

/* Remove arrows for input number  */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    // -webkit-appearance: none;
    margin: 0;
    // opacity: 1;
    margin-right: -10px;
}
/* Firefox */
input[type="number"] {
    // -moz-appearance: textfield;
}

/* Css Reset Starts */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}
button {
    border: 0px;
    outline: 0;
    background: transparent;
    cursor: pointer;
}
button:hover {
    background: transparent;
}
/* Css Reset Ends */

/* Fonts */
@font-face {
    font-family: "Roboto";
    src: local("Roboto"),
        url(../assets/fonts/roboto/Roboto-Regular.ttf) format("truetype");
}
@font-face {
    font-family: "Roboto";
    font-weight: 500;
    src: local("Roboto"),
        url(../assets/fonts/roboto/Roboto-Medium.ttf) format("truetype");
}

@font-face {
    font-family: "Roboto";
    font-weight: 600;
    src: local("Roboto"),
        url(../assets/fonts/roboto/Roboto-Bold.ttf) format("truetype");
}

strong {
    font-weight: 600;
    color: var(--dark_color);
}
body {
    font-size: var(--fontSize);
    line-height: var(--lineHeight);
    letter-spacing: var(--letterSpacing);
    font-family: var(--font);
    background: var(--bodyBg);
    overscroll-behavior-y: none;
}
:global {
    .partialHidden {
        height: 0px;
        width: 0px;
        position: absolute;
        border: none;
        overflow: hidden;
        outline: none;
        background: none;
        z-index: -1;
        pointer-events: none;
    }
    .visibleHidden {
        visibility: hidden;
    }
}

:global {
    .z-1000 {
        z-index: 1000;
    }
}
:global {
    .w-100 {
        width: 100%;
    }
    .w-30 {
        width: 30%;
    }
    .w-50 {
        width: 50%;
    }
    .w-60 {
        width: 52%;
    }
    .h-100 {
        height: 100%;
    }
    .mr-5 {
        margin-right: 5px;
    }
    .mr-10 {
        margin-right: 10px;
    }

    .cursorPointer {
        cursor: pointer;
    }

    .cursorGrab {
        cursor: grab;
    }

    .pointer-none {
        pointer-events: none;
    }
    .pointer-all {
        pointer-events: all;
    }

    .fontMedium {
        font-weight: var(--fontMedium);
    }

    .overflowHidden {
        overflow: hidden;
    }

    .ml-0 {
        margin-left: 0;
    }
    .ml-5 {
        margin-left: 5px;
    }
    .ml-10 {
        margin-left: 10px;
    }
    .ml-20 {
        margin-left: 20px;
    }
    .ml-30 {
        margin-left: 30px;
    }
    .gap-5 {
        gap: 5px;
    }
    .gap-10 {
        gap: 10px;
    }
    .gap-15 {
        gap: 15px;
    }
    .gap-20 {
        gap: 20px;
    }
    .gap-30 {
        gap: 30px;
    }
    .gap-40 {
        gap: 40px;
    }
    .gap-2030 {
        gap: 20px 30px;
    }
}
:global(.maxChildContainer) {
    max-width: var(--maxChildContainer);
}
:global(.unitCheckbox .createPathLayer) {
    margin-right: 5px !important;
}
:global(.activeUnitCheckbox label) {
    color: var(--dark_color) !important;
}
:global(.addBorderRadiusBottom > div) {
    border-bottom: 1px solid var(--borderColor4);
    border-radius: var(--radius2) var(--radius2) 0 0;
    box-shadow: 0px -6px 20px 0 rgba(0, 0, 0, 0.04);
}
:global(.leftIconMenuActive svg),
:global(.parentLinkActive),
:global(.subTitleActive) {
    color: var(--brandColor) !important;
}
:global(.subTitleActive::after),
:global(.subTitleActive::before) {
    border-top: 1px dashed #41c3a9 !important;
}
:global(.ellipsisText label:hover) {
    background-color: var(--backgroundColorTable);
}
:global(.ellipsisText .labelText) {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
:global(.ellipsisText label) {
    padding: 10px 20px;
}

:global(.relative) {
    position: relative;
}

:global(.absolute) {
    position: absolute;
}

:global(.dp-parent) {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

:global(.dp-parent-inline) {
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

:global(.dp-child) {
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}

:global(.flex-auto) {
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
}

:global(.dp-child-50) {
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

:global(.dp-parent-col) {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

:global(.dp-parent-ver-center) {
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

:global(.dp-parent-ver-end) {
    -webkit-box-align: flex-end;
    -moz-box-align: flex-end;
    -ms-flex-align: flex-end;
    -webkit-align-items: flex-end;
    align-items: flex-end;
}

:global(.dp-child-ver-center) {
    -webkit-align-self: center;
    align-self: center;
}

:global(.dp-child-hor-center) {
    -webkit-justify-self: center;
    justify-self: center;
}

:global(.dp-parent-hor-center) {
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
}
:global(.dp-parent-hor-end) {
    -moz-box-pack: flex-end;
    -ms-flex-pack: flex-end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
}

:global(.dp-parent-hor-space-between) {
    -moz-box-pack: space-between;
    -ms-flex-pack: space-between;
    -webkit-justify-content: space-between;
    justify-content: space-between;
}

:global(.dp-parent-hor-space-around) {
    -moz-box-pack: space-around;
    -ms-flex-pack: space-around;
    -webkit-justify-content: space-around;
    justify-content: space-around;
}

:global(.dp-parent-hor-flex-end) {
    -moz-box-pack: flex-end;
    -ms-flex-pack: flex-end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
}

:global {
    .dp-child-no-grow {
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
    }

    .dp-parent-no-shrink {
        flex-shrink: 0;
    }
}

:global(.flex-wrap) {
    flex-wrap: wrap;
}

:global(.text-center) {
    text-align: center;
}
:global(.text-right) {
    text-align: right !important;
    justify-content: flex-end !important;
}
:global(.card-padding) {
    padding: 20px;
}
:global(.form-row:not(:last-child)) {
    margin-bottom: 20px;
}

:global(.headerRegular) {
    font-weight: var(--fontMedium);
    font-size: var(--fontSizeRegular);
    color: var(--dark_color);
}
:global(.headerTitle) {
    font-weight: var(--fontMedium);
    font-size: var(--fontSizeMedium);
    line-height: var(--lineHeightMedium);
    margin-bottom: 20px;
    color: var(--dark_color);
}
:global(.headerTitleBold) {
    font-weight: var(--fontMedium);
    font-size: var(--fontSizeMedium);
    line-height: var(--lineHeightXL);
    // margin-bottom: 10px;
    color: var(--dark_color);
    span {
        color: var(--grey_shade);
    }
    &:last-child {
        margin-bottom: 0px;
    }
}
:global(.headerTitleRequired) {
    color: var(--grey_shade);
    margin-bottom: 10px;
    font-weight: var(--fontMedium);
}

:global(.capitalize) {
    text-transform: capitalize;
}
:global(.header-title-no-bold) {
    color: var(--grey_shade);
    margin-bottom: 10px;
}
:global(.infoText) {
    color: var(--infoText);
    margin-bottom: 10px;
    min-height: 17px;
    display: none;
    a {
        color: var(--brandColor);
    }
    &:last-child {
        margin-bottom: 0px;
    }
}
:global(.infoText.show) {
    display: block;
}
:global(.flex) {
    display: flex;
    align-items: center;
    justify-content: center;
}
:global(.panelBox) {
    background: var(--panelBg);
    border-radius: var(--radius1);
    margin-bottom: var(--gridSpacing2);
    padding: var(--gridSpacing2);
    &:last-child {
        margin-bottom: 0px;
    }
}
:global(.flexHeightContainer) {
    justify-content: flex-start;
    margin-bottom: 20px;
    padding-top: 10px;
    padding: 0px;
}
:global(.flexHeightTab) {
    padding: 20px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    // for single page border divider
    position: relative;
    // padding-bottom: 30px;
    &:not(:last-child) {
        // margin-bottom: 30px !important;
        margin-bottom: 10px !important;
        padding-bottom: 30px;
        &::before {
            content: "";
            position: absolute;
            border-bottom: 1px solid var(--borderColor1);
            bottom: 0;
            left: 20px;
            right: 20px;
        }
    }
}
:global(.row:not(:last-child)) {
    margin-bottom: 20px;
}
:global(.stickyRow) {
    position: sticky;
    top: 90px;
    // top: 135px;
    background: var(--stickyRowBg);
    z-index: 7;
    border-radius: var(--radius1);
    margin-bottom: 0px !important;
}
:global(.stickyRowZindex) {
    z-index: 995;
}
:global(.stickyRowCreateContainer) {
    // top: 147px;
    // top: 139px;
    top: 92px;
}
:global(.modalPopup) {
    background: rgba(255, 255, 255, 0.8);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}
:global(.descContainer) {
    padding: 10px 20px;
}
:global(.badge) {
    background-color: var(--badgeBg);
    border: 1px solid var(--borderColor1);
    padding: 5px 10px;
    border-radius: var(--radius3);
    color: var(--dark_color);
    word-break: break-word;
}
:global(.badge.active) {
    background-color: #eaf0f3;
    border: 1px solid #cad1d5;
}
:global(.descContainer) {
    padding: 10px 20px;
}
:global(.TabTitle) {
    height: calc(100% - 10px);
    background: #fff;
    top: 0px;
    align-self: flex-end;
    color: var(--grey_shade);
    padding: 10px 20px;
    border-radius: 12px 12px 0px 0px;
    box-shadow: var(--shadow1);
    cursor: pointer;
}

:global(.resetMarginBottom) {
    margin-bottom: 0;
    padding: 10px 20px;
}

:global(.dropdownLayer) {
    width: 100%;
    max-width: var(--maxChildContainer);
}
:global(.checkboxLayer) {
    margin-left: 10px;
    padding: 10px 0;
}
:global(.inputLayer) {
    max-width: var(--maxChildContainer);
    width: 100%;
}
:global(.checkboxLayer) {
    label {
        color: var(--grey_shade);
    }
}
:global(.checkBox) {
    margin-right: 5px;
}

:global(.fadeIn) {
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    animation: fadeIn 0.3s ease-out;
    -webkit-animation: fadeIn 0.3s ease-out;
}

:global(.leftStickyLabels) {
    display: inline-block;
    margin-left: -20px;
    padding: 10px 20px;
    background: var(--shade_EAF0F3);
    border-radius: 0 var(--radius2) var(--radius2) 0;
    color: var(--shade_74838F);
    font-weight: 500;
    font-size: 16px;
}

@-webkit-keyframes dashdraw {
    from {
        stroke-dashoffset: 10;
    }
}
@keyframes dashdraw {
    from {
        stroke-dashoffset: 10;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        visibility: hidden;
    }
    100% {
        opacity: 1;
        visibility: visible;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
        visibility: hidden;
    }
    100% {
        opacity: 1;
        visibility: visible;
    }
}
:global(.sticky-below-header) {
    position: sticky;
    // top: 20px;
    top: var(--topSpacing);
    z-index: 99;
    border-radius: 0px;
    background-color: var(--bodyBg);
}
:global {
    .bodyContainer {
        margin: var(--marginAuto);
        padding: var(--sidePadding3);
        min-height: 100vh;
        // min-height: Calc(100vh - 21px);
        padding-top: var(--topSpacing);
        background: var(--bodyBg);
        padding-left: 0px;
        padding-right: 0px;
    }
}

:global(.listingPage) {
    background: var(--shade_ffffff);
    position: relative;
    [class*="menuLayer"] {
        background: var(--shade_F3F9F9);
    }
    // &::after {
    //     content: "";
    //     position: fixed;
    //     top: 65px;
    //     height: 52px;
    //     width: 100%;
    //     background: var(--shade_F3F9F9);
    //     left: 0;
    // }
}

:global {
    .tableResponsive {
        height: 250px;
        overflow-y: auto;
        border: 1px solid #ddd;
    }

    .table {
        display: table;
        width: 100%;
        table-layout: fixed;
        border-collapse: separate;
        border: 1px solid var(--borderColor1);
        border-radius: var(--radius2);
        overflow: hidden;
        background: var(--shade_ffffff);
    }

    .tableHeader {
        display: table-header-group;
        background-color: var(--shade_FAFCFC);
        border-bottom: 1px solid #ddd;
    }
}

:global(.tableRow) {
    display: table-row;
    width: 100%;
}
:global(.tableBody) {
    display: table-row-group;
    width: 100%;
}
:global(.tableCell) {
    display: table-cell;
    padding: 8px 0px;
}
:global(.tableHeader .tableCell) {
    padding: 0px;
}
:global(.tableCellChild) {
    padding: 10px;
    color: var(--grey_shade);
}
:global(.tableCell:first-child) {
    padding-left: 10px;
}
:global(.tableCell:last-child) {
    padding-right: 10px;
    width: 100px;
}
:global(.form-rowParent) {
    width: 100%;
}

:global(.form-rowParent .form-row) {
    flex: 1 1 0;
}

:global(.form-rowParent .form-row:not(:last-child)) {
    margin-right: 25px;
}

:global(.MuiLinearProgress-bar1Indeterminate) {
    background-color: #41c3a9 !important;
}

:global(.MuiLinearProgress-bar2Indeterminate) {
    background-color: #41c3a9 !important;
}

:global(.file-drop) {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    top: 0;
    border-radius: var(--radius2);
}

// :global(.file-drop-dragging-over-target){
//   // position: absolute;
//   // left: 0;
//   width: 100%;
//   height: 100%;
//   border: 1px dashed #41C3A9;
//   // top: 0;
//   border-radius: var(--radius2);
// }
// :global(.margin-1) {
//   position: relative;
//   display: flex;
//   align-items: center;

//   &::before{
//     content:'';
//     position: absolute;
//     height: 100%;
//     width: 1px;
//     left:43px;
//     border:1px dashed var(--shade_CAD1D4);

//   }

//   svg{
//     position: relative;
//     z-index: 1;
//     margin-right: 5px;
//     transform: rotate(-90deg);
//     color:var(--shade_CAD1D4);
//   }
// }

// :global(.margin-2) {
//   display: flex;
//   align-items: center;
//   padding-left: 55px!important;
//    svg{
//     margin-right: 5px;
//     transform: rotate(-90deg);
//     color:var(--shade_CAD1D4);
//   }
// }

// :global(.margin-3){
//   display: flex;
//   align-items: center;
//   padding-left:65px!important;
//    svg{
//     margin-right: 5px;
//     transform: rotate(-90deg);
//     color:var(--shade_CAD1D4);
//   }
// }

// :global(.margin-4){
//   display: flex;
//   align-items: center;
//   padding-left:75px!important;
//    svg{
//     margin-right: 5px;
//     transform: rotate(-90deg);
//     color:var(--shade_CAD1D4);
//   }
// }

// :global(.margin-5){
//   display: flex;
//   align-items: center;
//   padding-left:85px!important;
//    svg{
//     margin-right: 5px;
//     transform: rotate(-90deg);
//     color:var(--shade_CAD1D4);
//   }
// }

// :global(.margin-6){
//   display: flex;
//   align-items: center;
//   padding-left:95px!important;
//    svg{
//     margin-right: 5px;
//     transform: rotate(-90deg);
//     color:var(--shade_CAD1D4);
//   }
// }

:global {
    .m-5 {
        margin: 5px;
    }
    .m-10 {
        margin: 10px;
    }
    .m-20 {
        margin: 20px;
    }
    .mt-5 {
        margin-top: 5px;
    }
    .mt-10 {
        margin-top: 10px;
    }
    .mt-20 {
        margin-top: 20px;
    }
    .mt-30 {
        margin-top: 30px;
    }
    .mb-0 {
        margin-bottom: 0px !important;
    }
    .mb-5 {
        margin-bottom: 5px !important;
    }
    .mb-10 {
        margin-bottom: 10px !important;
    }

    .mb-20 {
        margin-bottom: 20px !important;
    }

    .mb-30 {
        margin-bottom: 30px !important;
    }
    .mb-40 {
        margin-bottom: 40px !important;
    }

    .mr-5 {
        margin-right: 5px;
    }

    .mr-10 {
        margin-right: 10px;
    }

    .mr-20 {
        margin-right: 20px;
    }

    .ml-10 {
        margin-left: 10px;
    }

    .pl-0 {
        padding-left: 0 !important;
    }
    .p-0 {
        padding: 0 !important;
    }

    .p-5 {
        padding: 5px;
    }

    .p-10 {
        padding: 10px;
    }

    .p-20 {
        padding: 20px;
    }
    .p-30 {
        padding: 30px;
    }
    .pl-5 {
        padding-left: 5px;
    }

    .pl-10 {
        padding-left: 10px !important;
    }

    .pl-20 {
        padding-left: 20px !important;
    }

    .pl-25 {
        padding-left: 25px;
    }

    .pr-10 {
        padding-right: 10px !important;
    }
    .pr-15 {
        padding-right: 15px !important;
    }
    .pr-20 {
        padding-right: 20px !important;
    }
    .pr-35 {
        padding-right: 35px !important;
    }
    .pr-40 {
        padding-right: 40px !important;
    }

    .pt-10 {
        padding-top: 10px;
    }
    .pt-20 {
        padding-top: 20px;
    }
    .pb-0 {
        padding-bottom: 0;
    }
    .pt-0 {
        padding-top: 0px;
    }
    .pb-20 {
        padding-bottom: 20px;
    }
    .pb-10 {
        padding-bottom: 10px;
    }
    .noShadow {
        box-shadow: none;
    }
}

:global(.margin-7) {
    display: flex;
    align-items: center;
    padding-left: 105px !important;
    svg {
        margin-right: 5px;
        transform: rotate(-90deg);
        color: var(--shade_CAD1D4);
    }
}

:global(.margin-8) {
    display: flex;
    align-items: center;
    padding-left: 115px !important;
    svg {
        margin-right: 5px;
        transform: rotate(-90deg);
        color: var(--shade_CAD1D4);
    }
}

:global(.margin-9) {
    display: flex;
    align-items: center;
    padding-left: 125px !important;
    svg {
        margin-right: 5px;
        transform: rotate(-90deg);
        color: var(--shade_CAD1D4);
    }
}

:global(.margin-10) {
    display: flex;
    align-items: center;
    padding-left: 135px !important;
    svg {
        margin-right: 5px;
        transform: rotate(-90deg);
        color: var(--shade_CAD1D4);
    }
}

:global(.textError) {
    color: var(--error);
    margin-top: 5px;
    font-weight: var(--fontMedium);
    font-size: 12px;
    line-height: 16px;
}

:global {
    @mixin circleIconColor__states {
        &--active,
        &--darkHover:hover {
            color: var(--dark_color);
        }
        &--enable:hover {
            color: var(--brandColor);
        }
        &--delete:hover,
        &--disable:hover,
        &--error {
            color: var(--error) !important;
        }
        &--error:hover {
            color: var(--errorHover) !important;
        }
    }

    .circleIconColor {
        color: var(--grey_shade);
        @include circleIconColor__states;
    }

    [data-theme="darkMode"] {
        .circleIconColor {
            &:hover {
                color: #fff;
            }
            @include circleIconColor__states;
        }
    }
}

:global(.widthAbove1400) {
    max-width: var(--maxWidthAbove1400);
    margin: 0 auto;
    width: 100%;
}
:global(#toastPortal) {
    position: fixed;
    right: 0px;
    bottom: -54px;
    z-index: 9999;
}
:global {
    .addFocus {
        box-shadow: var(--inputShadow);
        transition: all 0.3s ease;
    }
    .addBoxShadow1 {
        box-shadow: var(--shadow1);
    }
}
:global(.disableOverlay) {
    position: relative;
    opacity: 0.35;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 6;
    }
}

:global(.noResults) {
    text-align: left;
    padding: 20px;
    text-transform: lowercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: var(--fontMedium);
    color: var(--infoText);
    cursor: text;
    &:first-letter {
        text-transform: capitalize;
    }
}

:global(.noData) {
    padding: 10px;
}

:global {
    .delete_icon {
        &:hover {
            color: var(--error);
        }
    }

    .warningBox {
        background: var(--tagError);
        border: 1px solid var(--tagErrorBorder);
        border-radius: var(--radius2);
        color: var(--grey_shade);
        padding: 10px;
        svg {
            flex-shrink: 0;
        }
    }
}

:global {
    .underline {
        text-decoration: underline;
        cursor: pointer;
    }
    .bold {
        font-weight: 600;
    }
    .italic {
        font-style: italic;
    }
    .link {
        color: var(--brandColor);
        transition: color var(--transitionSpeed1);
        cursor: pointer;
        &:hover {
            color: var(--brandHoverColor);
            text-decoration: underline;
        }
    }
    .greyText {
        color: var(--grey_shade);
    }
    .smallText {
        font-size: var(--fontSizeSmall);
    }
    .t-row {
        .popUp_link {
            padding: 15px 0;
            margin: -15px 0; //to increase height without affecting table cell height
        }
        &:hover {
            .popUp_link {
                text-decoration: underline;
                cursor: default;
                &:hover {
                    color: var(--brandColor);
                }
            }
        }
    }

    .popUp_link {
        .popUp_linkActive {
            color: var(--brandColor);
            text-decoration: underline;
        }
    }

    .sidePanelToggle__hamburger {
        cursor: pointer;
        margin-right: 20px;
        span {
            color: var(--brandColor);
        }
        &:hover span {
            color: var(--brandHoverColor);
        }
        &--expanded {
            span {
                color: var(--grey_shade);
            }
            &:hover span {
                color: var(--dark_color);
            }
        }
    }

    .rightPanel {
        height: 100%;
        width: 100%;
        padding-left: 256px;
        transition: var(--transitionSpeed1) ease-out;
        @media screen and (max-width: 1900px) {
            &.rightPanel__expanded {
                padding-left: 0;
            }
        }
    }

    .max-width-container {
        width: 100%;
    }

    @media screen and (min-width: 1900px) {
        .max-width-container {
            max-width: 1600px !important;
            margin: 0 auto !important;
        }

        .sidePanelToggle__hamburger {
            display: none;
        }
    }
}

:global(.ellipsis) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

:global {
    button {
        .icon-wrapper {
            margin: 0 10px;
            &:first-child {
                margin-left: 0;
                margin-right: 10px;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
    .icon-wrapper {
        .icon-hover,
        .icon-normal {
            display: inline-flex;
        }
        .icon-hover {
            display: none !important;
        }
        &:hover {
            .icon-hover {
                display: inline-flex !important;
            }
            .icon-normal {
                display: none !important;
            }
        }
    }

    // for icon hover to work for hover on every immediate parent
    :hover {
        > .icon-wrapper {
            .icon-hover {
                display: inline-flex !important;
            }
            .icon-normal {
                display: none !important;
            }
        }
    }
}

:global {
    .offlineDatePicker .rdrCalendarWrapper {
        padding: 0px !important;
    }

    .offlineDatePicker .rdrSelected {
        background-color: var(--brandColor);
    }
    .offlineDatePicker .rdrDayToday .rdrDayNumber span {
        font-weight: 500;
    }
}

:global(.react-flow__edge.animated path) {
    stroke-dasharray: 5;
    -webkit-animation: dashdraw 0.5s linear infinite;
    animation: dashdraw 0.5s linear infinite;
}
@-webkit-keyframes dashdraw {
    from {
        stroke-dashoffset: 10;
    }
}
@keyframes dashdraw {
    from {
        stroke-dashoffset: 10;
    }
}

:global(.spinLoader) {
    animation: spin 1s linear infinite;
}
@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}
@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

:global(.stickyTableHead) {
    top: 134px !important;
}

:global(.stickyTableNestedHead) {
    z-index: 1 !important;
    position: initial !important;
}

:global {
    #createJTBasics .JTBasicsName {
        font-weight: var(--fontMedium);
    }
}

:global(.popupStyle) {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-right: 0px !important;
}

:global(.fixedPopup) {
    position: fixed !important;
    display: none;
}

:global(.show) {
    display: block;
}

:global(.sliderLabelText) {
    color: var(--infoText);
    font-size: var(--fontSizeSmall);
    font-weight: var(--fontMedium);
}

:global(.iconInsideDiv svg) {
    color: var(--brandColor);
}

:global {
    .cloneSelectWrapper {
        margin: -10px;
        padding: 10px;
        svg {
            display: none !important;
        }
        &:hover {
            svg {
                display: block !important;
            }
        }
    }
}

:global {
    [data-theme="darkMode"] {
        *::-webkit-scrollbar-thumb {
            background-color: var(--darkMode_primaryGrey);
        }
        .warningBox {
            background: var(--darkMode_genericGrey);
        }
    }
}
