.tableImgWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    .normalImg {
        border-radius: var(--radius2);
        object-fit: contain;
        cursor: zoom-in;
    }

    .text {
        margin-left: 10px;
    }
}

.listingTableHoverImg {
    img {
        object-fit: contain;
        width: 280px;
        height: 280px;
    }
}
