:global {
    .stickyCell {
        position: sticky;
        z-index: 1;
        &.stickyCell__last {
            border-right: 2px solid var(--borderColor4);
        }
    }
    .t-cellWrapper .stickyCell__last {
        border: none;
    }

    .sticky-header-shadow {
        box-shadow: var(--tableHeaderShadow);
    }
    .accordionTableWrap {
        padding: 20px;
        background: var(--backgroundColorTable);
        border-top: 2px solid var(--borderColor4);
        border-bottom: 2px solid var(--borderColor4);
    }
    .table__newRow {
        transition: 300ms all linear;
        & :local {
            animation: rowSlideIn 1s;
        }
    }
}

.table-wrapper {
    background: var(--panelBg);
    border-bottom-left-radius: var(--radius1);
    border-bottom-right-radius: var(--radius1);
    position: relative;

    .table {
        width: 100%;
        overflow: auto;
        &.hideNaturalScroll {
            margin-bottom: -5px;
        }
    }
    .t-head {
        background: var(--panelBg) !important; //to override inline style
        overflow: auto;
        .t-row {
            background: inherit;
            .t-head__cell {
                color: var(--grey_shade);
                text-transform: capitalize;
                padding: 10px 25px; //to accomodate cell padding added due to copy text design
                &.hasMergedColumns {
                    height: 66px;
                    .commonTitle {
                        display: flex;
                        justify-content: center;
                        flex: 1 0 100%;
                        margin-bottom: 10px;
                        margin-left: 20px;
                    }
                }
                &.expandCollapseText {
                    text-align: right;
                    justify-content: flex-end;
                    &:hover {
                        color: var(--dark_color);
                        font-weight: var(--fontMedium);
                        cursor: pointer;
                    }
                }
                .sort-asc,
                .sort-des {
                    margin-left: 8px;
                    visibility: hidden;
                    opacity: 0;
                    cursor: pointer;
                    svg {
                        color: var(--shade_CAD1D4);
                    }
                    &:hover {
                        svg {
                            color: var(--grey_shade);
                        }
                    }
                }
                .sort-des {
                    transform: rotate(180deg);
                }
                &.active,
                &:hover {
                    .sort-asc,
                    .sort-des {
                        visibility: visible;
                        opacity: 1;
                    }
                }
                .bulkRowOps__icons {
                    margin-right: 30px;
                }
            }
            .t-head__cell_without_copy {
                padding: 10px 20px;
            }
        }

        &.sticky-header {
            position: sticky;
            top: 141px;
            transition: 250ms linear box-shadow;
            z-index: 2;
            -ms-overflow-style: none; /* Internet Explorer 10+ */
            scrollbar-width: none; /* Firefox */
            &.sticky-header-shadow {
                box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
            }
        }

        &::-webkit-scrollbar {
            display: none; /* Safari and Chrome */
        }
    }
    .mainEditButtons {
        display: flex;
        margin-right: 10px;
        vertical-align: middle;
        height: 18px;
        opacity: 0;
        visibility: hidden;
        position: absolute !important;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
        .editIcon {
            margin-right: 5px;
            color: var(--iconColor);
            cursor: pointer;
            display: inline-block;
            width: 18px;
            height: 18px;
            &:last-child {
                margin-right: 0px;
            }
            &:hover,
            &.active {
                color: var(--grey_shade);
            }
            &.disabled {
                cursor: auto;
                &:hover,
                &.active {
                    color: var(--iconColor);
                }
            }
            &.addIcon {
                transform: none;
            }
        }
    }
    .t-row {
        display: flex;
        &:last-child {
            border-bottom: 0px;
        }
    }
    .t-body__cell,
    .t-head__cell {
        text-align: left;
        font-size: var(--fontSize);
        // height: 53px;
        min-height: 53px;
        padding: 10px 20px;
        display: flex;
        align-items: center;
        border-bottom: 2px solid var(--borderColor5);
        background: inherit;
        white-space: nowrap;
        &.stickyCell {
            position: sticky;
        }
    }

    .t-body {
        &__withStickyHeader {
            // padding-top: 53px;
            &--mergedCols {
                // padding-top: 66px;
            }
        }
        .t-body__cell {
            .arrowWrapper {
                display: inline-block;
                vertical-align: top;
                margin-right: 7px;
                margin-left: -5px;
                position: relative;
                svg {
                    transform: rotate(-90deg);
                }
                &.activeArrow {
                    svg {
                        transform: rotate(0deg) !important;
                    }
                }
                &::after {
                    position: absolute;
                    border-left: 1px dashed var(--borderColor3);
                    top: -2px;
                    width: 1px;
                    height: 20px;
                    transform: rotate(90deg) translateY(0px);
                    content: "";
                    left: -70%;
                }
            }
            .saveBtnWrapper {
                display: flex;
                button:nth-child(1) {
                    margin-right: 20px;
                }
            }
            color: var(--dark_color);
        }
        .editDropdown {
            min-width: auto;
        }
        .t-row {
            background: var(--rowBg);
            &.t-row_col {
                flex-direction: column;
            }
            &:hover {
                .mainEditButtons {
                    visibility: visible;
                    opacity: 1;
                }
            }
            .t-body__cellWrapper {
                display: flex;
                width: 100%;
                height: 100%;
                background: inherit;
                z-index: 1;
            }
            .t-body__cell {
                &.containsSkeleton {
                    &:last-child {
                        display: none;
                    }
                    > span {
                        display: flex;
                        flex: 1;
                    }
                }
                &.drag-item {
                    width: 58px;
                    color: var(--shade_CAD1D4);
                    &:active {
                        color: var(--dark_color);
                    }
                }
            }
            &.editActive {
                height: 150px;
                // height: 183px;
                &:hover {
                    background: var(--shade_f7f7f7);
                    [data-theme="darkMode"] {
                        --shade_f7f7f7: var(--darkMode_genericGrey);
                    }
                }
                .t-body__cell {
                    padding: 15px 20px 0;
                    height: inherit;
                    align-items: flex-start;
                    background: var(--shade_f7f7f7);

                    &:first-child {
                        // padding-left: 40px;
                        // div {
                        //     position: relative;
                        //     top: 13px;
                        // }
                    }
                    &:last-child {
                        align-items: inherit;
                        position: relative;
                        overflow: visible;
                        right: 0;
                        min-width: 0;
                        padding: 0;
                    }
                }
                .saveBtnWrapper {
                    position: absolute;
                    right: 20px;
                    bottom: 20px;
                }
            }
            &:not(.editActive) {
                .actionBtns {
                    justify-content: flex-end;

                    &.actionBtns--compact {
                        position: sticky;
                        right: 0;
                        background: var(--shade_ffffff) !important;
                        width: 58px !important;
                        min-width: 58px !important;
                        border-left: 2px solid var(--borderColor4);
                        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.04);
                    }
                }
            }
            &:not(.containsNestedTable) {
                &:hover,
                &.rowActive {
                    .t-body__cell {
                        background: var(--rowHoverBg);
                    }
                }
            }
            &.containsNestedTable {
                .containsNestedTable__cell {
                    background: inherit;
                }
            }
        }
    }

    .headerClass {
        &:first-child {
            padding-left: 25px;
        }
    }
    .Select_input {
        min-width: 100%;
    }

    &.table-border {
        border: 1px solid var(--borderColor1);
        border-collapse: separate;
        border-radius: var(--radius2);
        .t-head {
            border-radius: var(--radius2) var(--radius2) 0 0;
            .t-row {
                border-radius: var(--radius2) var(--radius2) 0 0;
                .t-head__cell {
                    &:first-child {
                        border-top-left-radius: var(--radius2);
                    }
                    &:last-child {
                        border-top-right-radius: var(--radius2);
                    }
                }
            }
        }
        .t-body {
            .t-row:last-child {
                border-radius: 0 0 var(--radius2) var(--radius2);
                .t-body__cellWrapper {
                    border-radius: 0 0 var(--radius2) var(--radius2);
                    .t-body__cell {
                        border: none;
                        &:first-child {
                            border-bottom-left-radius: var(--radius2);
                        }
                        &:last-child {
                            border-bottom-right-radius: var(--radius2);
                        }
                    }
                }
            }
        }
    }

    .level-1 {
        .arrowWrapper::after {
            display: none;
        }
    }

    ::-webkit-scrollbar {
        width: 0;
    }
    .gridTableContainer {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(322px, 1fr));
        grid-gap: 20px;
        align-items: stretch;
        flex-wrap: wrap;
        padding: 20px 20px 20px 20px;
        background: url("../../assets/img/gridBG.svg");
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: 100%;
    }
}

.tableError {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: var(--infoText);
    padding: 20px;
    height: calc(100vh - 200px);
    .imgWrapper {
        text-align: center;
        margin-bottom: 60px;
    }
    span {
        color: var(--brandColor);
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }
}

.fakeScrollWrap {
    width: 100%;
    overflow-x: scroll;
    height: 5px;
    position: sticky;
    bottom: 50px;
    z-index: 2;
    &.fakeScrollWrap__bottom {
        bottom: 0;
    }
    &.bottomPos {
        bottom: 70px;
    }
    .fakeScroll {
        width: 100%;
        height: inherit;
    }
}

.t-row {
    background: var(--shade_ffffff);
    &.isDeleted {
        &:hover {
            > .t-body__cellWrapper * {
                background: var(--softDeleteHover) !important;
            }
        }
        > .t-body__cellWrapper {
            background: var(--softDelete) !important;
            text-decoration: line-through;
        }
        .accordionTableContainer {
            position: relative;
            &:before {
                background: rgba(255, 255, 255, 0.5);
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: 6;
            }
        }
    }
    &.t-row_col {
        flex-direction: column;
    }
    .emptyHeadCell {
        min-width: 58px;
    }
    .t-body__cellWrapper {
        display: flex;
        width: 100%;
        background: inherit;
        height: inherit;
        * {
            transition: background 200ms;
        }
    }
    .t-body__cell {
        // height: 53px;
        min-height: 53px;
        display: flex;
        align-items: center;
        &.containsSkeleton {
            &:last-child {
                display: none;
            }
            > span {
                display: flex;
                flex: 1;
            }
        }
        &.drag-item {
            width: 58px;
            color: var(--dark_color);
            cursor: pointer;
        }
    }
    &.editActive {
        height: 150px;
        position: relative;
        &::before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            height: 2px;
            background: var(--shade_EAF0F3);
            bottom: 75px;
            z-index: 1;
        }
        &:hover {
            background: var(--shade_f7f7f7);
        }
        .t-body__cell {
            padding: 10px 10px 0;
            height: inherit;
            align-items: flex-start;
            background: var(--shade_f7f7f7);

            &:first-child {
                padding-left: 40px;
            }
            &:last-child {
                align-items: inherit;
                position: relative;
                overflow: visible;
                right: 0;
                min-width: 0;
                padding: 0;
            }
        }
        .saveBtnWrapper {
            position: absolute;
            right: 20px;
            bottom: 20px;
        }
    }
    &:not(.editActive) {
        .actionBtns {
            &.actionBtns--compact {
                position: sticky;
                right: 0;
                border-left: 2px solid var(--borderColor4);
                box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.04);
            }
        }
    }
    &:not(.containsNestedTable) {
        background: var(--rowHoverBg);
    }
    &.containsNestedTable {
        .containsNestedTable__cell {
            background: var(--rowHoverBg);
        }
    }
    &.deletedRowWrapper {
        overflow-x: hidden;
        transition: 300ms all ease-in;
        animation: rowDeleteWrap 200ms forwards;
        -webkit-animation: rowDeleteWrap 200ms forwards;
        animation-delay: 1.3s;

        .deletedRow {
            transition: inherit;
            animation: rowDelete 1.5s forwards;
            -webkit-animation: rowDelete 1.5s forwards;
        }
    }
}

@keyframes rowSlideIn {
    0% {
        background: var(--brandColor);
        transform: translateX(+100%);
    }
    100% {
        background: initial;
        transform: translateX(0);
    }
}

@keyframes rowDelete {
    0% {
        background: var(--error-color);
    }
    100% {
        background: initial;
        transform: translateX(100%);
    }
}

@keyframes rowDeleteWrap {
    100% {
        max-height: 0;
    }
}

[data-theme="darkMode"] {
    .table-wrapper .t-head .t-row .t-head__cell {
        .sort-asc,
        .sort-des {
            svg {
                color: var(--grey_shade);
            }
            &:hover {
                svg {
                    color: #fff;
                }
            }
        }
    }

    .gridTableContainer {
        background: url("../../assets/img/gridBGDark.svg");
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: 100%;
    }

    .table-wrapper {
        .table {
            .t-body {
                .t-row {
                    &.editActive {
                        &:hover {
                            background: var(--darkMode_depthGrey);
                        }
                        .t-body__cell {
                            background: var(--darkMode_depthGrey);
                        }
                    }
                }
            }
        }
    }
}
