.flexDate {
    display: flex;
    flex-direction: column;

    .dateWithTime {
        margin-bottom: 5px;
    }

    .subTime {
        color: var(--grey_shade);
    }
}
