.mainParent {
    width: 100%;
    height: 100%;
}

.resetPadding {
    padding: 0;
    border: 1px solid var(--borderColor1);
    max-width: 490px;
}

.panelWidth {
    max-width: 638px;
}

.header {
    display: flex;
    padding: 10px 20px;
    background-color: var(--headerBg);
    border-radius: var(--radius2) var(--radius2) 0 0;
    border-bottom: 1px solid var(--borderColor1);
    .subHeader {
        flex-grow: 2;
        color: var(--grey_shade);
        font-size: var(--fontSize);
        &.flexGrow {
            flex-grow: 1;
        }
        &:nth-child(1) {
            max-width: 245px;
            width: 100%;
        }
        &:nth-child(2) {
            max-width: 240px;
            width: 100%;
            margin-left: 20px;
            margin-right: 15px;
        }
        &:nth-child(3) {
            max-width: 117px;
            width: 100%;
        }
    }
}

.body {
    display: flex;
    padding: 20px;
    position: relative;
    background: var(--shade_ffffff);
    .input {
        max-width: 245px;
        width: 100%;
        height: 38px;

        input[type="text"] {
            height: 100%;
            padding: 10px;
            height: 38px;
        }
    }
    .toggleSwitch {
        margin-right: 15px;
        max-width: 245px;
        width: 100%;
    }
    .marginLeft {
        margin-left: 20px;
    }
}

.dealIdDropdownWrapper {
    .dealNameLabel {
        display: flex;
        align-items: center;
    }
    .multiValue {
        margin-top: 5px;
        font-size: 14px;
        color: var(--textColor2);
        display: flex;
        align-items: center;
    }
}

.offerWallTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
        width: 61px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 12px;
        color: var(--grey_shade);
        cursor: pointer;
        svg {
            margin-right: 6px;
        }

        &:hover {
            background: var(--addBg);
            border-radius: 8px;
            color: var(--brandColor);
            svg {
                color: var(--brandColor);
            }
        }
    }
    .editText {
        font-weight: var(--fontMedium);
        color: var(--grey_shade);
        font-size: 14px;
        white-space: nowrap;
    }
}

.minimumDealInput {
    width: 640px;
}

.offerLink {
    &:hover {
        text-decoration: underline;
        cursor: pointer;
        &:hover {
            color: var(--brandColor);
        }
    }
}

.deletePopup {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
}

.popUp {
    position: absolute;
    right: 10px;
    white-space: normal;
    > div {
        background: none !important;
    }
}

.popupChannel {
    position: absolute;
    right: 10px;
}
