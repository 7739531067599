$tooltip-color: rgba(0, 0, 0, 0.75);
// $tooltip-color: #000;
$tooltip-text-color: #fff;

.tooltip {
    position: relative;
}

.tooltip-trigger {
    // display: inline-block;
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
}

.tooltip-bubble {
    // min-width: 125px;
    // max-width: 210px;
    max-width: 350px;
    position: absolute;
    z-index: var(--tooltipZindex);
    pointer-events: none;
    // @include fadeIn(0.4s);

    &.customWidth {
        width: 250px;
    }

    /* animation: fadeinout 0.4s linear forwards;
  -webkit-animation: fadeinout 0.4s linear forwards; */

    &::after {
        content: "";
        position: absolute;
        display: none;
    }

    .variantLight {
        background: rgba(255, 255, 255, 0.9);
        border: 1px solid #f1f1f1;
        border-radius: 8px; // Made consistent
        box-shadow: 0px 32px 32px -24px rgba(0, 0, 0, 0.1); // Updated as per design for light version
        color: #74838f;
        font-size: 16px;
    }
}

.tooltip-top {
    // bottom: 100%;
    left: 50%;
    // padding-bottom: 9px;
    // transform: translateX(-50%);
    transform: translate(-50%, -100%);
    margin-top: -5px;

    &::after {
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-top: 9px solid $tooltip-color;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }
}

.tooltip-bottom {
    top: 100%;
    left: 50%;
    // padding-top: 9px;
    // transform: translateX(-50%);
    transform: translate(-50%, 0%);

    &::after {
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-bottom: 9px solid $tooltip-color;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }
}

.tooltip-bottomLeft {
    top: 100%;
    left: -50%;
    padding-top: 9px;
    transform: translateX(-50%);

    &::after {
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-bottom: 9px solid $tooltip-color;
        top: 0;
        left: 70%;
        transform: translateX(-50%);
    }
}

.tooltip-left {
    top: 50%;
    //   right: 100%;
    //   padding-right: 9px;
    //   transform: translateY(-50%);
    transform: translate(-100%, -50%);

    &::after {
        border-left: 9px solid $tooltip-color;
        border-top: 9px solid transparent;
        border-bottom: 9px solid transparent;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
    }
}

.tooltip-right {
    top: 50%;
    //   left: 100%;
    //   padding-left: 9px;
    //   transform: translateY(-50%);
    transform: translate(0%, -50%);

    &::after {
        border-right: 9px solid $tooltip-color;
        border-top: 9px solid transparent;
        border-bottom: 9px solid transparent;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }
}

.tooltip-message {
    background: $tooltip-color;
    border-radius: 8px;
    color: $tooltip-text-color;
    font-size: 0.75rem;
    line-height: 1.4;
    // padding: 5px 5px;
    text-align: left;
    font-weight: normal !important;
    white-space: nowrap;
    padding: 10px; //As per discussion with design team
    &__wrap {
        white-space: unset;
    }
}

.tooltip-message__wrap {
    word-break: break-all;
}

.tooltip_MoreThanOneValue {
    white-space: pre-line;
    line-height: 2;
}

@-webkit-keyframes fadeinout {
    0%,
    100% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}

@keyframes fadeinout {
    0%,
    100% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}

[data-theme="darkMode"] {
    .tooltip-bubble {
        .variantLight {
            background: var(--darkMode_PanelBg);
            border: 2px solid var(--borderColor4);
            box-shadow: var(--shadow1);
            color: var(--grey_shade);
            font-size: 16px;
        }
    }

    .tooltip-message {
        background: var(--darkMode_placeholder);
        color: var(--darkMode_white);
        opacity: 0.95;
    }
}
