.toastLayer {
    color: var(--dark_color);
    width: 380px;
    // width: 313px;
    border: 1px solid var(--borderColor3);
    border-radius: var(--radius2);
    box-shadow: 0px 32px 32px -24px rgba(0, 0, 0, 0.2);
    // position: fixed;
    position: absolute;
    z-index: 1111;
    background: var(--shade_ffffff);
    &.hideBox {
        display: none;
    }
    &.bottomRight {
        bottom: 68px;
        right: 50px;
    }
    .iconWrapper {
        width: 64px;
        height: 64px;
        margin: -1px 0px -1px -1px;
        border-radius: var(--radius2) 0px 0px var(--radius2);
    }
    &.Success {
        .iconWrapper {
            background-color: var(--brandColor);
        }
    }
    &.Error {
        .iconWrapper {
            background-color: var(--error);
        }
    }
    &.Warning {
        .iconWrapper {
            background-color: var(--warningYellow);
        }
    }
    &.Info {
        .iconWrapper {
            background-color: var(--updateText);
        }
    }
    .labelWrapper {
        padding-left: 10px;
        padding-right: 20px;
        flex: 1;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        word-wrap: break-word;
    }
    svg {
        color: #fff;
    }
    .iconClose {
        padding-right: 10px;
        svg {
            color: var(--shade_CAD1D4);
            cursor: pointer;
            &:hover {
                color: var(--dark_color);
            }
        }
    }
}

[data-theme="darkMode"] {
    .toastLayer {
        background: var(--darkMode_PanelBg);

        .iconClosesvg {
            color: var(--darkMode_primaryGrey);
        }
    }
}
