.layoutPopupContainer {
    display: flex;
    height: calc(100vh - 146px);
    .layoutConfigWrapper {
        display: flex;
        border-right: 1px solid var(--borderColor4);
        // padding-top: 20px;
        // max-width: 427px;
        max-width: 450px;
        width: 100%;
        .leftPanelWrapper {
            padding-left: 10px;
            padding-right: 10px;
            border-right: 1px solid var(--borderColor4);
            padding-top: 20px;
            position: fixed;
            height: calc(100% - 145px);
            .iconWrapper {
                width: 28px;
                height: 28px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 30px;
                cursor: pointer;
                svg {
                    color: var(--grey_shade);
                }
                &:hover {
                    border-radius: 4px;
                    background-color: var(--addBg);
                    svg {
                        color: var(--brandColor);
                    }
                }

                &.iconWrapperActive {
                    border-radius: 4px;
                    background-color: var(--addBg);
                    svg {
                        color: var(--brandColor);
                    }
                }
            }
        }
        .dataWrapper {
            padding-left: 20px;
            width: 100%;

            height: 100%;
            overflow-y: scroll;
            padding-right: 20px;
            &.dataWrapperPadding {
                padding-left: 69px;
            }
            .headerTitle {
                padding-bottom: 24px;
                color: var(--dark_color);
                font-size: 18px;
                font-weight: 500;
                border-bottom: 1px solid var(--borderColor4);
                margin-bottom: 20px;
                padding-left: 20px;
                margin-right: -20px;
                margin-left: -20px;
                padding-top: 20px;
            }

            .templateWrapper {
                // width: 640px;
                // margin-top: 20px;
                // margin-left: 20px;

                .editWrapper {
                    width: 61px;
                    // height: 28px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 500;
                    font-size: 12px;
                    color: var(--grey_shade);
                    cursor: pointer;
                    svg {
                        margin-right: 6px;
                    }

                    &:hover {
                        color: var(--brandColor);
                        svg {
                            color: var(--brandColor);
                        }
                    }
                }
            }

            .noSelectionWrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                img {
                    width: 130px;
                    height: 130px;
                }

                .selectonText {
                    font-weight: 500;
                    font-size: 16px;
                    margin-top: 20px;
                    margin-bottom: 10px;
                    color: var(--dark_color);
                }

                .content {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 18px;
                    color: var(--grey_shade);
                    text-align: center;
                }
            }

            .dataWrapperTemplate {
                // padding: 0px 20px 20px 20px;
                .boxWrapper {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    padding-left: 20px;
                    height: 86px;
                    border: 1px solid var(--shade_EAF0F3);
                    border-radius: 8px;
                    margin-bottom: 20px;
                    .title {
                        margin-bottom: 10px;
                        font-weight: 500;
                        font-size: 14px;
                        color: var(--grey_shade);
                    }
                    .value {
                        font-weight: 400;
                        font-size: 14px;
                        color: var(--dark_color);
                    }

                    &:last-child {
                        margin-bottom: 20px;
                    }

                    &.noConfig {
                        border: 0px;
                        padding-left: 0px;
                        height: 50px;
                    }
                }
            }
        }

        .boxWrapper {
            max-width: 400px;
            width: 100%;
            padding-bottom: 20px;
            border: 1px solid var(--borderColor4);
            margin-bottom: 20px;
            position: relative;
            border-radius: var(--radius2);
            padding: 10px;
            .titleWrapper {
                display: flex;
                align-items: center;
                font-size: 14px;
                margin-bottom: 10px;
                div:nth-child(1) {
                    color: var(--grey_shade);
                    font-weight: 500;
                    max-width: 35px;
                    width: 100%;
                }
                div:nth-child(2) {
                    color: var(--dark_color);
                    max-width: calc(100% - 35px);
                    width: 100%;
                    word-wrap: break-word;
                }

                &.titleWrapperSecond {
                    div:nth-child(1) {
                        max-width: 86px;
                        width: 100%;
                    }

                    div:nth-child(2) {
                        max-width: calc(100% - 86px);
                        width: 100%;
                        word-wrap: break-word;
                    }
                }

                &.titleWrapperNoWidth {
                    div:nth-child(1) {
                        max-width: unset;
                        width: auto;
                        flex-shrink: 0;
                    }
                }
            }

            .deleteIcon,
            .deletePopup {
                position: absolute;
                top: 10px;
                right: 10px;
                z-index: 2;
                &.deleteIcon__disable svg {
                    pointer-events: none;
                }
            }

            .deletePopup {
                top: 0;
                right: 0;
            }

            .deleteIcon {
                display: none;
            }

            .inputSecond {
                margin-left: 20px;
            }

            &:hover {
                .deleteIcon {
                    display: block;
                }
            }
        }

        .configSelectWrap {
            position: absolute;
            z-index: 9;
        }
    }

    .preview_container {
        position: relative;
        // display: none;
        // padding: 0 0 30px;
        // padding: 10px;
        padding-top: 10px;
        // padding-left: 30px;
        flex: 4;
        background-color: var(----shade_EAF0F3);
        .buttonGroupHeader {
            display: flex;
            justify-content: center;
            border-bottom: 1px solid var(--borderColor1);
            .downloadButton {
                margin-right: 20px;
                svg {
                    fill: var(--shade_74838F) !important;
                    cursor: pointer;
                }
            }
        }
        .buttonGroupHeaderHide {
            display: none;
        }
        .buttonGroup {
            display: flex;
            gap: 25px;
            // padding: 0px 10px 10px 20px;
            // border-bottom: 1px solid var(--borderColor1);
        }
        .tab {
            // padding: 10px 60px;
            cursor: pointer;
            opacity: 0.6;
            // background: white;
            border: 0;
            outline: 0;
            // padding-bottom: 10px;
            text-transform: capitalize;
            display: flex;
            align-items: center;
            margin-bottom: 2px;
            &:first-child {
                margin-left: 20px;
            }

            &.active {
                border-bottom: 2px solid var(--brandColor);
                opacity: 1;
                margin-bottom: 0px;
                color: var(--brandColor);
            }
        }
        .previewDeviceContainer {
            display: flex;
            justify-content: center;
            align-items: center;
            .fullHeight {
                display: flex;
                flex-direction: column;
                width: 100%;
                height: calc(91vh - 200px);
                justify-content: center;
                align-items: center;
                &.fullHeightNodePreview {
                    height: 400px;
                }
            }
            .imgContainer {
                margin-top: 37px;
                border-radius: 5px;
                margin-bottom: 20px;
                width: 90%;
                height: inherit;
                margin-left: auto;
                margin-right: auto;
                position: relative;

                .loaderWrapper {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    div {
                        width: 70px;
                        height: 70px;
                    }
                }

                &.isBigScreen {
                    // margin-top: 10px;
                }

                &.imgContainerNodePreview {
                    margin-top: 0px;
                    margin-bottom: 0px;
                }
            }
        }

        .defaultMsgPopup {
            position: absolute;
            width: 100%;
            top: 62px;
            border-radius: 0px;
            border: 1px solid var(--infoBorder);
            color: var(--grey_shade);
            background-color: var(--infoColor2);
            border-left: 1px solid var(--borderColor4);
            svg {
                color: var(--updateText);
            }
        }

        &.previewNodeContainer {
            padding-top: 0px;
        }
    }

    .previewUpDateBtn {
        margin-left: auto;
        margin-bottom: 10px;
        margin-right: 20px;
        .domainDropdown {
            width: 150px;
        }
        .refreshButton {
            width: 42px;
            height: 42px;
        }

        .autoReload {
            align-items: center;
            max-width: 120px;
            width: 100%;
            flex-shrink: 0;
        }
    }

    &.layoutPopupContainerPreviewNode {
        height: 400px;
        overflow: hidden;
    }

    // &.noPaddingLeft {
    //     margin-left: -24px;
    // }

   
    
   
}

.keywordTextWrap{
    &:hover{
        div:nth-child(1){
                color: var(--brandColor);
                cursor: pointer;
                text-decoration: underline;
        }
    }
   
}

.imgWrapper{
   img{
    height: 25px;
    width:25px;
    object-fit: fill;
   }

   svg{
    height: 25px;
    width:25px;
    path{
        fill:var(--grey_shade)
    }
   }
}

[data-theme="darkMode"] {
    .imgWrapper{
        img{
         height: 25px;
         width:25px;
         object-fit: fill;
         color:var(--darkMode_primaryGrey);
        }

        svg{
    
        path{
            fill:var(--darkMode_primaryGrey)
        }
   }
     }
}

.tableWrapper > div {
    max-height: 189px;
    overflow: auto;
}

:global {
    .reloadTooltip {
        margin-left: -23px;
    }
}

[data-theme="darkMode"] {
    .refreshButton:not(:hover) {
        background: var(--darkMode_genericGrey);
    }
}
