.wrapper {
    flex-shrink: 0;
    &__left {
        width: calc(60% - 10px);
    }

    &__right {
        width: calc(40% - 10px);
    }

    .header {
        color: var(--grey_shade);
        font-weight: var(--fontMedium);
        margin: 20px 0 10px;
        text-transform: capitalize;

        .btn {
            color: var(--brandColor);
            cursor: pointer;
            &.disabled {
                pointer-events: none;
                opacity: 0.6;
            }
        }
    }

    .listWrap {
        border-radius: var(--radius2);
        border: 1px solid var(--borderColor4);
        overflow: auto;
        > ul {
            background: none;
        }

        .searchBar {
            position: sticky;
            background: var(--headerBg);
            display: flex;
            top: 0;
            z-index: 1;
            input {
                padding-right: 40px;
            }
            .clearSearch {
                position: absolute;
                width: 15px;
                height: 15px;
                right: 15px;
                top: 50%;
                transform: translateY(-50%);
                cursor: pointer;
            }
        }
    }
}
