.TargettingParamsContainer {
    position: relative;
    color: var(--dark_color);
    .headerTitleRequired {
        display: flex;
        align-items: center;
        button {
            margin-left: 10px;
            margin-right: 0px;
            height: 28px;
            border-radius: 8px;
            padding: 0px 8px;
        }
    }
    .bodyPadding {
        padding-top: 0px !important;
    }
    .CheckboxContainer {
        align-items: center;
        justify-content: flex-start;
        .CheckboxWrapper {
            margin-right: 30px;
            label {
                color: var(--grey_shade);
            }
        }
    }
    .setBtnWrapper {
        position: relative;
        align-items: self-start;
        flex-wrap: wrap;
        gap: 10px;
        .plusIconWrapper {
            position: relative;
            cursor: pointer;
            padding: 7px 7px 3px 7px;
            border: 1px solid var(--borderColor4);
            border-radius: var(--radius2);
            // margin-right: 10px;
            &:hover {
                background: var(--brandColor);
                svg {
                    color: var(--shade_ffffff) !important;
                }
            }
        }
        .setBtn {
            cursor: pointer;
            font-weight: var(--fontMedium);
            font-size: var(--fontSize);
            color: var(--shade_CAD1D4);
            padding: 5px 10px;
            border: 1px dashed var(--borderColor4);
            border-radius: var(--radius3);
            font-style: italic;
            &:hover {
                background: var(--shade_F3F9F9);
                color: var(--dark_color);
                border: 1px dashed var(--brandColor);
            }
        }
    }
    .plusDropdownWrapper {
        height: 30px;
        position: absolute;
        left: 0;
        max-width: 332px;
        width: 100%;
        top: 32px;
        z-index: 1;
        .select {
            min-height: 30px !important;
            height: 30px;
        }
    }
    .browseCsvWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        background: var(--shade_FAFCFC);
        border: 1px dashed var(--borderColor1);
        border-radius: var(--radius2);
        margin-top: 40px;
        .fileName {
            font-size: var(--fontSize);
            font-weight: var(--fontRegular);
            color: var(--shade_CAD1D4);
        }
    }
    input[type="file"] {
        display: none;
    }
    .loadSetContainer {
        display: none;
    }
    .showText {
        opacity: 1;
        display: block;
    }
    .hideText {
        opacity: 0;
        display: none;
    }
    .addMoreTargetting {
        margin-bottom: 20px;
        color: var(--infoText);
        pointer-events: none;
        p {
            margin-left: 5px;
        }
    }
    .saveSetLayer {
        position: relative;
    }
    .innerPopUp {
        text-align: left;
    }
    .loadSetLayer,
    .resetSetLayer {
        position: relative;
    }
    .customSelect {
        width: 332px;
        position: absolute;
        bottom: 0;
        left: 10px;
        z-index: 1;
    }
    .savePopup {
        text-align: left;
    }
    .savePopup > div:last-child {
        justify-content: flex-end !important;
        flex-direction: row !important;
        border: 0;
        padding-top: 0;
    }
    .sucessFullWrapper {
        background: rgba(65, 195, 169, 0.2);
        border-radius: var(--radius2);
        color: var(--brandColor);
        padding: 5px 10px;
        margin-left: 10px;
        .labelWrapper {
            margin-left: 5px;
        }
        &.resetSuccessWrapper {
            background: rgba(231, 110, 110, 0.2);
            color: var(--error);
        }
    }
    .resetBtn {
        &:hover {
            background: var(--error);
            color: #fff;
            border-color: var(--error);
        }
    }
    .textError {
        color: var(--error);
        margin-top: 5px;
        // font-weight: var(--fontRegular);
        font-weight: var(--fontMedium);
        font-size: 12px;
        line-height: 16px;
    }
    &.borderBottom {
        position: relative;
        &::after {
            content: "";
            position: absolute;
            left: 20px;
            bottom: 0;
            height: 1px;
            width: calc(100% - 40px);
            background-color: var(--borderColor1);
        }
    }

    .addText {
        color: var(--brandColor);
        margin-bottom: 20px;
        align-self: flex-start;
        cursor: pointer;
    }

    .operator {
        background: var(--shade_f7f7f7);
        border: 1px solid var(--borderColor4);
        border-radius: var(--radius2);
        margin-bottom: 20px;
        padding: 10px;
        width: 120px;
        text-transform: uppercase;
    }

    .nestedTp {
        border: 1px solid var(--borderColor4);
        background: var(--shade-fcfcfc);
        border-radius: var(--radius2);
        margin-bottom: 20px;
        padding: 20px;
    }
}

.typeWrap {
    border: 1px solid var(--borderColor1);
    border-radius: 10px;
    padding-left: 5px;
}

.customToggleValueSwitch {
    label {
        padding: 0;
    }
}

[data-theme="darkMode"] {
    .TargettingParamsContainer {
        .nestedTp {
            background: var(--darkMode_PanelBg);
        }
        .operator {
            background: var(--darkMode_disabled);
        }
    }
}
